import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Injectable({
  providedIn: 'root',
})
export class LanguageService {
  constructor(private readonly translateService: TranslateService) {}

  public getLanguageLabelMini(languageCode) {
    return languageCode.includes('-') ? languageCode.substr(0, languageCode.indexOf('-')) : languageCode;
  }

  public getLanguageLabelMaxi(languageCode) {
    return !languageCode.includes('-') ? languageCode + '-' + languageCode.toUpperCase() : languageCode;
  }
}
