/**
 * Sam Tools WS
 * Sam Tools Web Services.
 *
 * OpenAPI spec version: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
/* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent }                           from '@angular/common/http';
import { CustomHttpUrlEncodingCodec }                        from '../encoder';

import { Observable }                                        from 'rxjs';

import { ApiResponse } from '../model/apiResponse';
import { Filters } from '../model/filters';
import { Licence } from '../model/licence';
import { ListLicencesResponse } from '../model/listLicencesResponse';

import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
import { Configuration }                                     from '../configuration';


@Injectable({
  providedIn: 'root'
})
export class LicenceService {

    protected basePath = 'http://api.samtools.local';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {

        if (configuration) {
            this.configuration = configuration;
            this.configuration.basePath = configuration.basePath || basePath || this.basePath;

        } else {
            this.configuration.basePath = basePath || this.basePath;
        }
    }

    /**
     * @param consumes string[] mime-types
     * @return true: consumes contains 'multipart/form-data', false: otherwise
     */
    private canConsumeForm(consumes: string[]): boolean {
        const form = 'multipart/form-data';
        for (const consume of consumes) {
            if (form === consume) {
                return true;
            }
        }
        return false;
    }


    /**
     * Créer une licence
     * 
     * @param licence 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public create(licence: Licence, observe?: 'body', reportProgress?: boolean): Observable<Licence>;
    public create(licence: Licence, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Licence>>;
    public create(licence: Licence, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Licence>>;
    public create(licence: Licence, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
      return this.createLicence(licence, observe, reportProgress);
    }

    /**
     * Créer une licence
     * 
     * @param licence 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public createLicence(licence: Licence, observe?: 'body', reportProgress?: boolean): Observable<Licence>;
    public createLicence(licence: Licence, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Licence>>;
    public createLicence(licence: Licence, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Licence>>;
    public createLicence(licence: Licence, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (licence === null || licence === undefined) {
            throw new Error('Required parameter licence was null or undefined when calling createLicence.');
        }

        let headers = this.defaultHeaders;


        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<Licence>(`${this.configuration.basePath}/licence`,
            licence,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Supprime une licence
     * Suppression d&#39;une licence
     * @param licenceId Identifiant de la licence à supprimer
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public _delete(licenceId: number, observe?: 'body', reportProgress?: boolean): Observable<ApiResponse>;
    public _delete(licenceId: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<ApiResponse>>;
    public _delete(licenceId: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<ApiResponse>>;
    public _delete(licenceId: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
      return this.deleteLicence(licenceId, observe, reportProgress);
    }

    /**
     * Supprime une licence
     * Suppression d&#39;une licence
     * @param licenceId Identifiant de la licence à supprimer
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public deleteLicence(licenceId: number, observe?: 'body', reportProgress?: boolean): Observable<ApiResponse>;
    public deleteLicence(licenceId: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<ApiResponse>>;
    public deleteLicence(licenceId: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<ApiResponse>>;
    public deleteLicence(licenceId: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (licenceId === null || licenceId === undefined) {
            throw new Error('Required parameter licenceId was null or undefined when calling deleteLicence.');
        }

        let headers = this.defaultHeaders;


        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.delete<ApiResponse>(`${this.configuration.basePath}/licence/${encodeURIComponent(String(licenceId))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Retourne une licence selon l&#39;identifiant du client
     * 
     * @param customerId id du client
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getById(customerId: number, observe?: 'body', reportProgress?: boolean): Observable<Licence>;
    public getById(customerId: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Licence>>;
    public getById(customerId: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Licence>>;
    public getById(customerId: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
      return this.getLicenceByCustomerId(customerId, observe, reportProgress);
    }

    /**
     * Retourne une licence selon l&#39;identifiant du client
     * 
     * @param customerId id du client
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getLicenceByCustomerId(customerId: number, observe?: 'body', reportProgress?: boolean): Observable<Licence>;
    public getLicenceByCustomerId(customerId: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Licence>>;
    public getLicenceByCustomerId(customerId: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Licence>>;
    public getLicenceByCustomerId(customerId: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (customerId === null || customerId === undefined) {
            throw new Error('Required parameter customerId was null or undefined when calling getLicenceByCustomerId.');
        }

        let headers = this.defaultHeaders;


        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<Licence>(`${this.configuration.basePath}/licence/customer/${encodeURIComponent(String(customerId))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Retourne le nombre de licence attribuer à tous les clients enfants de la licence donnée
     * 
     * @param licenceId id de la licence
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getLicenceGivenToChildrenCustomer(licenceId: number, observe?: 'body', reportProgress?: boolean): Observable<number>;
    public getLicenceGivenToChildrenCustomer(licenceId: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<number>>;
    public getLicenceGivenToChildrenCustomer(licenceId: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<number>>;
    public getLicenceGivenToChildrenCustomer(licenceId: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (licenceId === null || licenceId === undefined) {
            throw new Error('Required parameter licenceId was null or undefined when calling getLicenceGivenToChildrenCustomer.');
        }

        let headers = this.defaultHeaders;


        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<number>(`${this.configuration.basePath}/licence/children/${encodeURIComponent(String(licenceId))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Retourne une nombre restant de licence à attribuer à des client
     * 
     * @param licenceId id de la licence à récupérer
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getRemainingLicenceByLicenceId(licenceId: number, observe?: 'body', reportProgress?: boolean): Observable<number>;
    public getRemainingLicenceByLicenceId(licenceId: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<number>>;
    public getRemainingLicenceByLicenceId(licenceId: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<number>>;
    public getRemainingLicenceByLicenceId(licenceId: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (licenceId === null || licenceId === undefined) {
            throw new Error('Required parameter licenceId was null or undefined when calling getRemainingLicenceByLicenceId.');
        }

        let headers = this.defaultHeaders;


        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<number>(`${this.configuration.basePath}/licence/remaining/${encodeURIComponent(String(licenceId))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Retourne une liste de licence
     * 
     * @param filters 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public list(filters: Filters, observe?: 'body', reportProgress?: boolean): Observable<ListLicencesResponse>;
    public list(filters: Filters, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<ListLicencesResponse>>;
    public list(filters: Filters, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<ListLicencesResponse>>;
    public list(filters: Filters, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
      return this.listLicences(filters, observe, reportProgress);
    }

    /**
     * Retourne une liste de licence
     * 
     * @param filters 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public listLicences(filters: Filters, observe?: 'body', reportProgress?: boolean): Observable<ListLicencesResponse>;
    public listLicences(filters: Filters, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<ListLicencesResponse>>;
    public listLicences(filters: Filters, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<ListLicencesResponse>>;
    public listLicences(filters: Filters, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (filters === null || filters === undefined) {
            throw new Error('Required parameter filters was null or undefined when calling listLicences.');
        }

        let headers = this.defaultHeaders;


        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<ListLicencesResponse>(`${this.configuration.basePath}/licences`,
            filters,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Met à jour une licence
     * MaJ licence
     * @param licenceId Identifiant de la licence à mettre à jour
     * @param licence 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public update(licenceId: number, licence: Licence, observe?: 'body', reportProgress?: boolean): Observable<ApiResponse>;
    public update(licenceId: number, licence: Licence, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<ApiResponse>>;
    public update(licenceId: number, licence: Licence, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<ApiResponse>>;
    public update(licenceId: number, licence: Licence, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
      return this.updateLicence(licenceId, licence, observe, reportProgress);
    }

    /**
     * Met à jour une licence
     * MaJ licence
     * @param licenceId Identifiant de la licence à mettre à jour
     * @param licence 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public updateLicence(licenceId: number, licence: Licence, observe?: 'body', reportProgress?: boolean): Observable<ApiResponse>;
    public updateLicence(licenceId: number, licence: Licence, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<ApiResponse>>;
    public updateLicence(licenceId: number, licence: Licence, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<ApiResponse>>;
    public updateLicence(licenceId: number, licence: Licence, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (licenceId === null || licenceId === undefined) {
            throw new Error('Required parameter licenceId was null or undefined when calling updateLicence.');
        }
        if (licence === null || licence === undefined) {
            throw new Error('Required parameter licence was null or undefined when calling updateLicence.');
        }

        let headers = this.defaultHeaders;


        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.put<ApiResponse>(`${this.configuration.basePath}/licence/${encodeURIComponent(String(licenceId))}`,
            licence,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

}
