/**
 * Sam Tools WS
 * Sam Tools Web Services.
 *
 * OpenAPI spec version: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
/* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent }                           from '@angular/common/http';
import { CustomHttpUrlEncodingCodec }                        from '../encoder';

import { Observable }                                        from 'rxjs';

import { ApiResponseServerError } from '../model/apiResponseServerError';
import { ApiUser } from '../model/apiUser';
import { ApiUserConnect } from '../model/apiUserConnect';
import { ApiUserDisconnect } from '../model/apiUserDisconnect';
import { ApiUserResponse } from '../model/apiUserResponse';

import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
import { Configuration }                                     from '../configuration';


@Injectable({
  providedIn: 'root'
})
export class ApiUserService {

    protected basePath = 'http://api.samtools.local';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {

        if (configuration) {
            this.configuration = configuration;
            this.configuration.basePath = configuration.basePath || basePath || this.basePath;

        } else {
            this.configuration.basePath = basePath || this.basePath;
        }
    }

    /**
     * @param consumes string[] mime-types
     * @return true: consumes contains 'multipart/form-data', false: otherwise
     */
    private canConsumeForm(consumes: string[]): boolean {
        const form = 'multipart/form-data';
        for (const consume of consumes) {
            if (form === consume) {
                return true;
            }
        }
        return false;
    }


    /**
     * Mise à jour du badge ou d&#39;un utilisateur complet
     * 
     * @param apiUser 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiUser(apiUser: ApiUser, observe?: 'body', reportProgress?: boolean): Observable<ApiUserResponse>;
    public apiUser(apiUser: ApiUser, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<ApiUserResponse>>;
    public apiUser(apiUser: ApiUser, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<ApiUserResponse>>;
    public apiUser(apiUser: ApiUser, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (apiUser === null || apiUser === undefined) {
            throw new Error('Required parameter apiUser was null or undefined when calling apiUser.');
        }

        let headers = this.defaultHeaders;


        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.put<ApiUserResponse>(`${this.configuration.basePath}/api/users`,
            apiUser,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Connexion d&#39;un utilisateur sur un contenant
     * 
     * @param apiUserConnect 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiUserConnect(apiUserConnect: ApiUserConnect, observe?: 'body', reportProgress?: boolean): Observable<ApiUserResponse>;
    public apiUserConnect(apiUserConnect: ApiUserConnect, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<ApiUserResponse>>;
    public apiUserConnect(apiUserConnect: ApiUserConnect, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<ApiUserResponse>>;
    public apiUserConnect(apiUserConnect: ApiUserConnect, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (apiUserConnect === null || apiUserConnect === undefined) {
            throw new Error('Required parameter apiUserConnect was null or undefined when calling apiUserConnect.');
        }

        let headers = this.defaultHeaders;


        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<ApiUserResponse>(`${this.configuration.basePath}/api/users/connect`,
            apiUserConnect,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Déconnexion d&#39;un utilisateur sur un contenant
     * 
     * @param apiUserDisconnect 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiUserDisconnect(apiUserDisconnect: ApiUserDisconnect, observe?: 'body', reportProgress?: boolean): Observable<ApiUserResponse>;
    public apiUserDisconnect(apiUserDisconnect: ApiUserDisconnect, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<ApiUserResponse>>;
    public apiUserDisconnect(apiUserDisconnect: ApiUserDisconnect, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<ApiUserResponse>>;
    public apiUserDisconnect(apiUserDisconnect: ApiUserDisconnect, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (apiUserDisconnect === null || apiUserDisconnect === undefined) {
            throw new Error('Required parameter apiUserDisconnect was null or undefined when calling apiUserDisconnect.');
        }

        let headers = this.defaultHeaders;


        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<ApiUserResponse>(`${this.configuration.basePath}/api/users/disconnect`,
            apiUserDisconnect,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Mise à disposition d&#39;un utilisateur
     * 
     * @param userId User à récupérer
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getUserContainer(userId: number, observe?: 'body', reportProgress?: boolean): Observable<ApiUserResponse>;
    public getUserContainer(userId: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<ApiUserResponse>>;
    public getUserContainer(userId: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<ApiUserResponse>>;
    public getUserContainer(userId: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (userId === null || userId === undefined) {
            throw new Error('Required parameter userId was null or undefined when calling getUserContainer.');
        }

        let headers = this.defaultHeaders;


        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<ApiUserResponse>(`${this.configuration.basePath}/api/user/${encodeURIComponent(String(userId))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Mise à disposition des utilisateurs
     * 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public listApiUserContainer(observe?: 'body', reportProgress?: boolean): Observable<ApiUserResponse>;
    public listApiUserContainer(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<ApiUserResponse>>;
    public listApiUserContainer(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<ApiUserResponse>>;
    public listApiUserContainer(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;


        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<ApiUserResponse>(`${this.configuration.basePath}/api/users`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

}
