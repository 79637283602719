import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, Subscriber } from 'rxjs';
import { map, tap, timeout } from 'rxjs/operators';
import { LocalStorageService } from '@adista/window-kit-ui';

import { environment } from 'src/environments/environment';
import { TranslateService } from '@ngx-translate/core';
import { ActivationEnd, Router } from '@angular/router';
import { HttpCancelService } from '@shared/services/http-cancel.service';

export interface OAuthData {
  access_token: string;
  grant_type?: string;
  expires_in: number;
  refresh_token: string;
  expiration_date?: number;
}

@Injectable({
  providedIn: 'root',
})
export class OAuthService {
  protected clientId = null;
  protected clientSecret = null;
  protected url = null;

  protected readonly lsTokenKey = 'oauthtoken';
  protected readonly lsUserKey = 'userData';
  protected readonly formDataHeaders = new HttpHeaders({
    'Content-Type': 'application/x-www-form-urlencoded',
  });

  constructor(
    protected http: HttpClient,
    public lsService: LocalStorageService,
    private translate: TranslateService,
    private httpCancelService: HttpCancelService,
    private readonly router: Router,
  ) {
    this.url = environment.apiAccessPoint;
    this.clientId = environment.API_CREDENTIALS.clientId;
    this.clientSecret = environment.API_CREDENTIALS.clientSecret;
  }

  public haveLicence(login: string): Observable<boolean> {
    return this.http.get(`${this.url}/user/haveLicence/${login}`, { headers: this.formDataHeaders }).pipe(
      map((response: any) => {
        return response;
      }),
    );
  }

  public checkLoginPassword(credentials: any): Observable<boolean> {
    const formData: string = this.getFormData(credentials);
    return this.http.post(`${this.url}/user/check`, formData, { headers: this.formDataHeaders }).pipe(
      map((response: any) => {
        return response;
      }),
    );
  }

  public fetchAccessToken(credentials: any): Observable<OAuthData> {
    credentials.client_id = this.clientId;
    credentials.client_secret = this.clientSecret;
    credentials.grant_type = 'password';

    const formData: string = this.getFormData(credentials);

    return this.http.post(this.url + '/oauth/v2/token', formData, { headers: this.formDataHeaders }).pipe(
      tap((res: OAuthData) => {
        this.setToken(res);
      }),
    );
  }

  public getToken(): string | null {
    if (this.lsService.get(this.lsTokenKey) && this.isTokenExpired(0)) {
      this.removeToken();
      return null;
    }

    if (this.lsService.get(this.lsTokenKey) && this.isTokenExpired(60 * 20)) {
      // Si le token est presque expiré, récupère le nouveau token en background
      this.refreshToken().subscribe((res: OAuthData) => {
        this.setToken(res);
      });
    }

    const oauthData: OAuthData = this.getOAuthData();
    return oauthData ? oauthData.access_token : null;
  }

  public refreshToken(): Observable<any> {
    const oauthToken: OAuthData = this.getOAuthData();
    const credentials = {
      client_id: this.clientId,
      client_secret: this.clientSecret,
      grant_type: 'refresh_token',
      refresh_token: oauthToken.refresh_token,
    };
    const formData: string = this.getFormData(credentials);

    return this.http.post(this.url + '/oauth/v2/token', formData, { headers: this.formDataHeaders });
  }

  public checkIfRefreshToken(token): Observable<boolean> {
    return this.http.get(`${this.url}/user/check/refreshToken/${token}`, { headers: this.formDataHeaders }).pipe(
      map((response: any) => {
        return response;
      }),
    );
  }

  public setToken(token: OAuthData): void {
    token.expiration_date = Math.floor(Date.now() / 1000) + token.expires_in;
    this.lsService.set(this.lsTokenKey, token);
  }

  public isLogged(): boolean {
    return this.getOAuthData() !== null && !this.isTokenExpired();
  }

  public removeToken(): void {
    if (this.lsService.get(this.lsTokenKey)) {
      const oauthData: OAuthData = this.getOAuthData();
      if (oauthData && this.lsService.get(this.lsTokenKey).access_token) {
        this.httpCancelService.cancelPendingRequests();
        this.http
          .get(`${this.url}/token/delete/${this.lsService.get(this.lsTokenKey).access_token}`, { headers: this.formDataHeaders })
          .subscribe((message) => {});
        window.location.reload();
      }

      this.lsService.remove(this.lsTokenKey);
      this.lsService.remove(this.lsUserKey);
      this.lsService.remove('lang');
      this.translate.use(this.translate.getDefaultLang());
    } else {
      return;
    }
  }

  public isTokenExpired(delay: number = 0): boolean {
    if (this.getOAuthData() === undefined) {
      return true;
    }
    const oauthData: OAuthData = this.getOAuthData();
    return oauthData.expiration_date - delay < Math.floor(Date.now() / 1000);
  }

  public getOAuthData(): OAuthData | null {
    const token: OAuthData = this.lsService.get(this.lsTokenKey);
    return token || null;
  }

  protected getExpirationDate(): number {
    const token: OAuthData = this.getOAuthData();
    if (!token) {
      throw new Error('No token stored');
    }
    return token.expiration_date;
  }

  protected getFormData(object: object): string {
    const formData: string[] = [];
    Object.keys(object).forEach((key) => formData.push(encodeURIComponent(key) + '=' + encodeURIComponent(object[key])));
    return formData.join('&');
  }
}
