import { NgModule } from '@angular/core';
import { CustomKitFormDatepickerComponent } from '@components/libs/forms/custom-kit-form-datepicker/custom-kit-form-datepicker.component';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { FormDatepickerComponent } from '@adista/window-kit-ui';
import { CommonModule } from '@angular/common';
import { CustomKitFormSelectComponent } from '@components/libs/forms/custom-kit-form-select/custom-kit-form-select.component';
import { MatSelectModule } from '@angular/material/select';
import { MatIconModule } from '@angular/material/icon';
import { MatPseudoCheckboxModule } from '@angular/material/core';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatButtonModule } from '@angular/material/button';
import { TranslateModule } from '@ngx-translate/core';
import { ThousandSeparatorPipe } from '@shared/pipes/thousandSeparator.pipe';
import { ThousandSeparatorSpacePipe } from '@shared/pipes/thousandSeparatorSpace.pipe';
import { MultiDeleteComponent } from '@modules/multi-delete/multi-delete.component';

@NgModule({
  declarations: [
    CustomKitFormDatepickerComponent,
    CustomKitFormSelectComponent,
    ThousandSeparatorPipe,
    ThousandSeparatorSpacePipe,
    MultiDeleteComponent,
  ],
  entryComponents: [CustomKitFormDatepickerComponent, CustomKitFormSelectComponent],
  exports: [
    CustomKitFormDatepickerComponent,
    CustomKitFormSelectComponent,
    ThousandSeparatorPipe,
    ThousandSeparatorSpacePipe,
    MultiDeleteComponent,
  ],
  imports: [
    MatFormFieldModule,
    MatInputModule,
    MatDatepickerModule,
    CommonModule,
    MatSelectModule,
    MatIconModule,
    MatPseudoCheckboxModule,
    MatCheckboxModule,
    MatButtonModule,
    TranslateModule,
  ],
  providers: [FormDatepickerComponent],
})
export class SharedModule {}
