import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { WindowManagerModule } from '@adista/window-kit-ui';

import { MaterialComponentsModule } from 'src/app/material-components.module';
import { IconActiveRendererComponent } from '@components/libs/custom-ag-grid/icon-active-renderer.component';
import { BtnDeleteCellRendererComponent } from '@components/libs/custom-ag-grid/btn-delete-cell-renderer.component';
import { BtnAddCustomerCellRendererComponent } from '@components/libs/custom-ag-grid/btn-add-customer-cell-renderer.component';
import { TranslateModule } from '@ngx-translate/core';
import { IconTrackedProductRendererComponent } from '@components/libs/custom-ag-grid/icon-tracked-product-renderer.component';
import { IconInPendingRenderComponent } from '@components/libs/custom-ag-grid/icon-in-pending-render.component';
import { NumericEditorComponent } from '@components/libs/custom-ag-grid/numeric-editor.component';
import { LinkRendererComponent } from '@components/libs/custom-ag-grid/link-renderer.component';
import { ImageRendererComponent } from '@components/libs/custom-ag-grid/image-renderer.component';
import { IconAlertRendererComponent } from '@components/libs/custom-ag-grid/icon-alert-renderer.component';

@NgModule({
  imports: [CommonModule, MaterialComponentsModule, FormsModule, ReactiveFormsModule, WindowManagerModule, TranslateModule],
  declarations: [
    IconActiveRendererComponent,
    BtnDeleteCellRendererComponent,
    BtnAddCustomerCellRendererComponent,
    IconTrackedProductRendererComponent,
    IconInPendingRenderComponent,
    NumericEditorComponent,
    LinkRendererComponent,
    ImageRendererComponent,
    IconAlertRendererComponent,
  ],
  exports: [
    IconActiveRendererComponent,
    BtnDeleteCellRendererComponent,
    BtnAddCustomerCellRendererComponent,
    IconTrackedProductRendererComponent,
    IconInPendingRenderComponent,
    NumericEditorComponent,
    LinkRendererComponent,
    ImageRendererComponent,
    IconAlertRendererComponent,
  ],
})
export class CustomAgGridModule {}
