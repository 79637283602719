/**
 * Sam Tools WS
 * Sam Tools Web Services.
 *
 * OpenAPI spec version: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
/* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent }                           from '@angular/common/http';
import { CustomHttpUrlEncodingCodec }                        from '../encoder';

import { Observable }                                        from 'rxjs';

import { ApiResponse } from '../model/apiResponse';
import { Filters } from '../model/filters';
import { ListTrackedProductsResponse } from '../model/listTrackedProductsResponse';
import { TrackedProduct } from '../model/trackedProduct';

import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
import { Configuration }                                     from '../configuration';


@Injectable({
  providedIn: 'root'
})
export class TrackedProductService {

    protected basePath = 'http://api.samtools.local';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {

        if (configuration) {
            this.configuration = configuration;
            this.configuration.basePath = configuration.basePath || basePath || this.basePath;

        } else {
            this.configuration.basePath = basePath || this.basePath;
        }
    }

    /**
     * @param consumes string[] mime-types
     * @return true: consumes contains 'multipart/form-data', false: otherwise
     */
    private canConsumeForm(consumes: string[]): boolean {
        const form = 'multipart/form-data';
        for (const consume of consumes) {
            if (form === consume) {
                return true;
            }
        }
        return false;
    }


    /**
     * Créer un article suivi
     * 
     * @param trackedProduct 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public create(trackedProduct: TrackedProduct, observe?: 'body', reportProgress?: boolean): Observable<TrackedProduct>;
    public create(trackedProduct: TrackedProduct, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<TrackedProduct>>;
    public create(trackedProduct: TrackedProduct, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<TrackedProduct>>;
    public create(trackedProduct: TrackedProduct, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
      return this.createTrackedProduct(trackedProduct, observe, reportProgress);
    }

    /**
     * Créer un article suivi
     * 
     * @param trackedProduct 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public createTrackedProduct(trackedProduct: TrackedProduct, observe?: 'body', reportProgress?: boolean): Observable<TrackedProduct>;
    public createTrackedProduct(trackedProduct: TrackedProduct, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<TrackedProduct>>;
    public createTrackedProduct(trackedProduct: TrackedProduct, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<TrackedProduct>>;
    public createTrackedProduct(trackedProduct: TrackedProduct, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (trackedProduct === null || trackedProduct === undefined) {
            throw new Error('Required parameter trackedProduct was null or undefined when calling createTrackedProduct.');
        }

        let headers = this.defaultHeaders;


        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // authentication (oAuth) required

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<TrackedProduct>(`${this.configuration.basePath}/trackedProduct`,
            trackedProduct,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Supprime un article suivi
     * Suppression d&#39;article suivi
     * @param trackedProductId Identifiant de l&#39;article suivi à supprimer
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public _delete(trackedProductId: number, observe?: 'body', reportProgress?: boolean): Observable<ApiResponse>;
    public _delete(trackedProductId: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<ApiResponse>>;
    public _delete(trackedProductId: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<ApiResponse>>;
    public _delete(trackedProductId: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
      return this.deleteTrackedProduct(trackedProductId, observe, reportProgress);
    }

    /**
     * Supprime un article suivi
     * Suppression d&#39;article suivi
     * @param trackedProductId Identifiant de l&#39;article suivi à supprimer
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public deleteTrackedProduct(trackedProductId: number, observe?: 'body', reportProgress?: boolean): Observable<ApiResponse>;
    public deleteTrackedProduct(trackedProductId: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<ApiResponse>>;
    public deleteTrackedProduct(trackedProductId: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<ApiResponse>>;
    public deleteTrackedProduct(trackedProductId: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (trackedProductId === null || trackedProductId === undefined) {
            throw new Error('Required parameter trackedProductId was null or undefined when calling deleteTrackedProduct.');
        }

        let headers = this.defaultHeaders;


        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // authentication (oAuth) required

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.delete<ApiResponse>(`${this.configuration.basePath}/trackedProduct/${encodeURIComponent(String(trackedProductId))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Retourne un article suivi
     * 
     * @param trackedProductId id de l&#39;article suivi à récupérer
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getById(trackedProductId: number, observe?: 'body', reportProgress?: boolean): Observable<TrackedProduct>;
    public getById(trackedProductId: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<TrackedProduct>>;
    public getById(trackedProductId: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<TrackedProduct>>;
    public getById(trackedProductId: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
      return this.getTrackedProductById(trackedProductId, observe, reportProgress);
    }

    /**
     * Retourne un article suivi
     * 
     * @param trackedProductId id de l&#39;article suivi à récupérer
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getTrackedProductById(trackedProductId: number, observe?: 'body', reportProgress?: boolean): Observable<TrackedProduct>;
    public getTrackedProductById(trackedProductId: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<TrackedProduct>>;
    public getTrackedProductById(trackedProductId: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<TrackedProduct>>;
    public getTrackedProductById(trackedProductId: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (trackedProductId === null || trackedProductId === undefined) {
            throw new Error('Required parameter trackedProductId was null or undefined when calling getTrackedProductById.');
        }

        let headers = this.defaultHeaders;


        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // authentication (oAuth) required

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<TrackedProduct>(`${this.configuration.basePath}/trackedProduct/${encodeURIComponent(String(trackedProductId))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Retourne une liste d&#39;articles suivis
     * 
     * @param filters 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public list(filters: Filters, observe?: 'body', reportProgress?: boolean): Observable<ListTrackedProductsResponse>;
    public list(filters: Filters, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<ListTrackedProductsResponse>>;
    public list(filters: Filters, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<ListTrackedProductsResponse>>;
    public list(filters: Filters, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
      return this.listTrackedProducts(filters, observe, reportProgress);
    }

    /**
     * Retourne une liste d&#39;articles suivis
     * 
     * @param filters 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public listTrackedProducts(filters: Filters, observe?: 'body', reportProgress?: boolean): Observable<ListTrackedProductsResponse>;
    public listTrackedProducts(filters: Filters, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<ListTrackedProductsResponse>>;
    public listTrackedProducts(filters: Filters, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<ListTrackedProductsResponse>>;
    public listTrackedProducts(filters: Filters, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (filters === null || filters === undefined) {
            throw new Error('Required parameter filters was null or undefined when calling listTrackedProducts.');
        }

        let headers = this.defaultHeaders;


        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // authentication (oAuth) required

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<ListTrackedProductsResponse>(`${this.configuration.basePath}/trackedProducts`,
            filters,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Met à jour un article suivi
     * MaJ article suivi
     * @param trackedProductId Identifiant de l&#39;article suivi à mettre à jour
     * @param trackedProduct 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public update(trackedProductId: number, trackedProduct: TrackedProduct, observe?: 'body', reportProgress?: boolean): Observable<ApiResponse>;
    public update(trackedProductId: number, trackedProduct: TrackedProduct, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<ApiResponse>>;
    public update(trackedProductId: number, trackedProduct: TrackedProduct, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<ApiResponse>>;
    public update(trackedProductId: number, trackedProduct: TrackedProduct, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
      return this.updateTrackedProduct(trackedProductId, trackedProduct, observe, reportProgress);
    }

    /**
     * Met à jour un article suivi
     * MaJ article suivi
     * @param trackedProductId Identifiant de l&#39;article suivi à mettre à jour
     * @param trackedProduct 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public updateTrackedProduct(trackedProductId: number, trackedProduct: TrackedProduct, observe?: 'body', reportProgress?: boolean): Observable<ApiResponse>;
    public updateTrackedProduct(trackedProductId: number, trackedProduct: TrackedProduct, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<ApiResponse>>;
    public updateTrackedProduct(trackedProductId: number, trackedProduct: TrackedProduct, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<ApiResponse>>;
    public updateTrackedProduct(trackedProductId: number, trackedProduct: TrackedProduct, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (trackedProductId === null || trackedProductId === undefined) {
            throw new Error('Required parameter trackedProductId was null or undefined when calling updateTrackedProduct.');
        }
        if (trackedProduct === null || trackedProduct === undefined) {
            throw new Error('Required parameter trackedProduct was null or undefined when calling updateTrackedProduct.');
        }

        let headers = this.defaultHeaders;


        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // authentication (oAuth) required

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.put<ApiResponse>(`${this.configuration.basePath}/trackedProduct/${encodeURIComponent(String(trackedProductId))}`,
            trackedProduct,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

}
