import { Component, ElementRef, HostListener, OnInit, Renderer2, ViewChild } from '@angular/core';
import { MenuService } from '@shared/services/menu.service';
import { ApiUser, hasUserPermission, KitService, WindowItem } from '@adista/window-kit-ui';
import { CurrentUserService } from '@shared/services/currentuser.service';
import { CustomMenuItem } from '@shared/models/custom-menu-item';
import { User } from '@api/model/user';
import { CustomerVisibilityCustomizationService } from '@api/api/customerVisibilityCustomization.service';
import { CustomerVisibilityCustomization } from '@api/model/customerVisibilityCustomization';

@Component({
  selector: 'app-boardnav',
  templateUrl: './boardnav.component.html',
  styleUrls: ['./boardnav.component.scss'],
})
export class BoardnavComponent implements OnInit {
  activeMobileMenu = false;
  closeTips = false;
  currentCat = null;
  menus: CustomMenuItem[];
  duplicatedWindow: WindowItem;

  menuSelect: any;

  scrWidth: any;
  modeMobile = window.innerWidth < 900;
  nothingSelected = true;
  user: User;

  public urlLogo = '/assets/img/samtools-supervisor-logo.svg';
  @ViewChild('toggleButton') toggleButton: ElementRef;

  /** Mise en place d'un HostListener pour la gestion du mode mobile (largeur d'écran inférieur à 900 pixels) */
  @HostListener('window:resize', ['$event'])
  getScreenSize(event?) {
    if (window.innerWidth < 900) {
      this.modeMobile = true;
    } else {
      this.modeMobile = false;
    }
  }
  constructor(
    private readonly menuService: MenuService,
    private readonly currentUserService: CurrentUserService,
    private readonly customerVisibilityCustomizationService: CustomerVisibilityCustomizationService,
    private readonly kit: KitService,
    private renderer: Renderer2,
  ) {
    this.renderer.listen('document', 'click', (e: Event) => {
      if (!this.toggleButton.nativeElement.contains(e.target) && !this.modeMobile) {
        this.nothingSelected = false;
      }
    });
    this.kit.desktop.displayedWindows$.subscribe((windowItem: WindowItem[]) => {
      this.nothingSelected = false;

      if (this.menus && this.menus.length > 0 && windowItem && windowItem.length === 1) {
        this.currentCat = this.menus.findIndex((customMenuItem: CustomMenuItem) => {
          let find = customMenuItem.label === windowItem[0].title;
          if (!find) {
            find = customMenuItem.subItems?.some((customSubMenuItem: CustomMenuItem) => customSubMenuItem.label === windowItem[0].title);
          }
          return find;
        });
      }
    });
  }

  ngOnInit() {
    this.currentUserService.currentUser$.subscribe((user: User) => {
      this.user = user;
      this.menuService.getMenus().subscribe((menu: CustomMenuItem[]) => {
        this.menus = this.filterMenus(user, menu);
      });
      if (user && user.referringCustomer) {
        this.customerVisibilityCustomizationService
          .getCustomerVisibilityCustomizationByCustomerId(user.referringCustomer)
          .subscribe((customerVisibilityCustomization: CustomerVisibilityCustomization) => {
            if (customerVisibilityCustomization && customerVisibilityCustomization.logoUrl) {
              this.urlLogo = customerVisibilityCustomization.logoUrl;
            }
          });
      }
    });
  }

  private filterMenus(user: ApiUser, data: CustomMenuItem[]): CustomMenuItem[] {
    const filteredMenu: CustomMenuItem[] = [];

    data.forEach((menu: CustomMenuItem) => {
      if (!menu.permission || hasUserPermission(user, menu.permission)) {
        const newMenu: CustomMenuItem = { ...menu };

        if (menu.subItems && menu.subItems.length > 0) {
          newMenu.subItems = this.filterMenus(user, menu.subItems);
          if (newMenu.subItems && newMenu.subItems.length > 0) {
            filteredMenu.push(newMenu);
          }
        } else {
          filteredMenu.push(newMenu);
        }
      }
    });

    return filteredMenu;
  }

  menuItemClicked(item: CustomMenuItem, index: number, isSubMenu = false) {
    this.duplicatedWindow = undefined;
    this.nothingSelected = true;

    if (item.subItems && item.subItems.length === 1) {
      isSubMenu = true;
    }

    const menuClicked: CustomMenuItem = item.subItems && item.subItems.length === 1 ? item.subItems[0] : item;
    let labelItem = menuClicked.label;
    if (menuClicked.keyLabel === 'menu.myCompagny') {
      // Si le menu est "Ma société", on prend la société de l'utilisateur
      labelItem = this.user.referringCustomerLabel;
    }

    if (!this.modeMobile) {
      this.currentCat = index;
    } else {
      if (this.currentCat === index && item.subItems) {
        this.currentCat = null;
      } else if (this.currentCat === null || item.subItems) {
        this.currentCat = index;
      } else {
        this.currentCat = null;
      }
    }

    // Recherche des fenêtres dupliquées
    this.kit.desktop.windows$.subscribe((windowList: WindowItem[]) => {
      for (const window of windowList) {
        if (window.title === labelItem && (!item.subItems || isSubMenu)) {
          this.duplicatedWindow = window;
          break;
        }
      }
    });

    // Bloque l'ouverture de la fenêtre si elle est déjà ouverte + focus
    if (this.duplicatedWindow) {
      this.kit.desktop.setWindowVisibility(this.duplicatedWindow, true);
      this.activeMobileMenu = false;
      return;
    }

    if (item) {
      if (item.link) {
        this.kit.routing.openLink(item.link);
        // Fermeture du menu en mode mobile
        this.activeMobileMenu = false;
      } else {
        // Si un seul sous-menu, l'entrée de menu ouvre directement la page
        if (item.subItems.length === 1) {
          this.nothingSelected = false;
          this.menuItemClicked(item.subItems[0], index);
        }
      }
      if (isSubMenu) {
        this.nothingSelected = false;
      }
    }
  }
}
