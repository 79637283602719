/**
 * Sam Tools WS
 * Sam Tools Web Services.
 *
 * OpenAPI spec version: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
/* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent }                           from '@angular/common/http';
import { CustomHttpUrlEncodingCodec }                        from '../encoder';

import { Observable }                                        from 'rxjs';

import { ApiResponse } from '../model/apiResponse';
import { ErrorCode } from '../model/errorCode';
import { ErrorParam } from '../model/errorParam';
import { Filters } from '../model/filters';
import { ListErrorParamResponse } from '../model/listErrorParamResponse';

import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
import { Configuration }                                     from '../configuration';


@Injectable({
  providedIn: 'root'
})
export class ErrorService {

    protected basePath = 'http://api.samtools.local';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {

        if (configuration) {
            this.configuration = configuration;
            this.configuration.basePath = configuration.basePath || basePath || this.basePath;

        } else {
            this.configuration.basePath = basePath || this.basePath;
        }
    }

    /**
     * @param consumes string[] mime-types
     * @return true: consumes contains 'multipart/form-data', false: otherwise
     */
    private canConsumeForm(consumes: string[]): boolean {
        const form = 'multipart/form-data';
        for (const consume of consumes) {
            if (form === consume) {
                return true;
            }
        }
        return false;
    }


    /**
     * Créer une erreur
     * 
     * @param errorParam 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public create(errorParam: ErrorParam, observe?: 'body', reportProgress?: boolean): Observable<ErrorParam>;
    public create(errorParam: ErrorParam, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<ErrorParam>>;
    public create(errorParam: ErrorParam, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<ErrorParam>>;
    public create(errorParam: ErrorParam, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
      return this.createError(errorParam, observe, reportProgress);
    }

    /**
     * Créer une erreur
     * 
     * @param errorParam 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public createError(errorParam: ErrorParam, observe?: 'body', reportProgress?: boolean): Observable<ErrorParam>;
    public createError(errorParam: ErrorParam, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<ErrorParam>>;
    public createError(errorParam: ErrorParam, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<ErrorParam>>;
    public createError(errorParam: ErrorParam, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (errorParam === null || errorParam === undefined) {
            throw new Error('Required parameter errorParam was null or undefined when calling createError.');
        }

        let headers = this.defaultHeaders;


        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // authentication (oAuth) required

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<ErrorParam>(`${this.configuration.basePath}/error`,
            errorParam,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Supprime une erreur
     * Suppression d&#39;une erreur
     * @param errorId Identifiant de l&#39;erreur à supprimer
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public _delete(errorId: number, observe?: 'body', reportProgress?: boolean): Observable<ApiResponse>;
    public _delete(errorId: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<ApiResponse>>;
    public _delete(errorId: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<ApiResponse>>;
    public _delete(errorId: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
      return this.deleteError(errorId, observe, reportProgress);
    }

    /**
     * Supprime une erreur
     * Suppression d&#39;une erreur
     * @param errorId Identifiant de l&#39;erreur à supprimer
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public deleteError(errorId: number, observe?: 'body', reportProgress?: boolean): Observable<ApiResponse>;
    public deleteError(errorId: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<ApiResponse>>;
    public deleteError(errorId: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<ApiResponse>>;
    public deleteError(errorId: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (errorId === null || errorId === undefined) {
            throw new Error('Required parameter errorId was null or undefined when calling deleteError.');
        }

        let headers = this.defaultHeaders;


        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // authentication (oAuth) required

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.delete<ApiResponse>(`${this.configuration.basePath}/error/${encodeURIComponent(String(errorId))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Retourne une erreur par rapport à son code
     * 
     * @param errorCode 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getErrorByCode(errorCode: ErrorCode, observe?: 'body', reportProgress?: boolean): Observable<ErrorParam>;
    public getErrorByCode(errorCode: ErrorCode, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<ErrorParam>>;
    public getErrorByCode(errorCode: ErrorCode, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<ErrorParam>>;
    public getErrorByCode(errorCode: ErrorCode, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (errorCode === null || errorCode === undefined) {
            throw new Error('Required parameter errorCode was null or undefined when calling getErrorByCode.');
        }

        let headers = this.defaultHeaders;


        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // authentication (oAuth) required

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<ErrorParam>(`${this.configuration.basePath}/errorByCode`,
            errorCode,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Retourne une erreur
     * 
     * @param errorId id de l&#39;erreur à récupérer
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getById(errorId: number, observe?: 'body', reportProgress?: boolean): Observable<ErrorParam>;
    public getById(errorId: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<ErrorParam>>;
    public getById(errorId: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<ErrorParam>>;
    public getById(errorId: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
      return this.getErrorById(errorId, observe, reportProgress);
    }

    /**
     * Retourne une erreur
     * 
     * @param errorId id de l&#39;erreur à récupérer
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getErrorById(errorId: number, observe?: 'body', reportProgress?: boolean): Observable<ErrorParam>;
    public getErrorById(errorId: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<ErrorParam>>;
    public getErrorById(errorId: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<ErrorParam>>;
    public getErrorById(errorId: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (errorId === null || errorId === undefined) {
            throw new Error('Required parameter errorId was null or undefined when calling getErrorById.');
        }

        let headers = this.defaultHeaders;


        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // authentication (oAuth) required

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<ErrorParam>(`${this.configuration.basePath}/error/${encodeURIComponent(String(errorId))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Retourne une liste d&#39;erreur
     * 
     * @param filters 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public list(filters: Filters, observe?: 'body', reportProgress?: boolean): Observable<ListErrorParamResponse>;
    public list(filters: Filters, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<ListErrorParamResponse>>;
    public list(filters: Filters, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<ListErrorParamResponse>>;
    public list(filters: Filters, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
      return this.listError(filters, observe, reportProgress);
    }

    /**
     * Retourne une liste d&#39;erreur
     * 
     * @param filters 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public listError(filters: Filters, observe?: 'body', reportProgress?: boolean): Observable<ListErrorParamResponse>;
    public listError(filters: Filters, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<ListErrorParamResponse>>;
    public listError(filters: Filters, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<ListErrorParamResponse>>;
    public listError(filters: Filters, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (filters === null || filters === undefined) {
            throw new Error('Required parameter filters was null or undefined when calling listError.');
        }

        let headers = this.defaultHeaders;


        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // authentication (oAuth) required

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<ListErrorParamResponse>(`${this.configuration.basePath}/errors`,
            filters,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Met à jour une erreur
     * MaJ erreur
     * @param errorId Identifiant de l&#39;erreur à mettre à jour
     * @param errorParam 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public update(errorId: number, errorParam: ErrorParam, observe?: 'body', reportProgress?: boolean): Observable<ApiResponse>;
    public update(errorId: number, errorParam: ErrorParam, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<ApiResponse>>;
    public update(errorId: number, errorParam: ErrorParam, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<ApiResponse>>;
    public update(errorId: number, errorParam: ErrorParam, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
      return this.updateError(errorId, errorParam, observe, reportProgress);
    }

    /**
     * Met à jour une erreur
     * MaJ erreur
     * @param errorId Identifiant de l&#39;erreur à mettre à jour
     * @param errorParam 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public updateError(errorId: number, errorParam: ErrorParam, observe?: 'body', reportProgress?: boolean): Observable<ApiResponse>;
    public updateError(errorId: number, errorParam: ErrorParam, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<ApiResponse>>;
    public updateError(errorId: number, errorParam: ErrorParam, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<ApiResponse>>;
    public updateError(errorId: number, errorParam: ErrorParam, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (errorId === null || errorId === undefined) {
            throw new Error('Required parameter errorId was null or undefined when calling updateError.');
        }
        if (errorParam === null || errorParam === undefined) {
            throw new Error('Required parameter errorParam was null or undefined when calling updateError.');
        }

        let headers = this.defaultHeaders;


        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // authentication (oAuth) required

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.put<ApiResponse>(`${this.configuration.basePath}/error/${encodeURIComponent(String(errorId))}`,
            errorParam,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

}
