/**
 * Sam Tools WS
 * Sam Tools Web Services.
 *
 * OpenAPI spec version: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
/* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent }                           from '@angular/common/http';
import { CustomHttpUrlEncodingCodec }                        from '../encoder';

import { Observable }                                        from 'rxjs';

import { ApiContainerAlert } from '../model/apiContainerAlert';
import { ApiContainerError } from '../model/apiContainerError';
import { ApiContainerOAuth } from '../model/apiContainerOAuth';
import { ApiContainerOAuthResponse } from '../model/apiContainerOAuthResponse';
import { ApiContainerResponse } from '../model/apiContainerResponse';
import { ApiContainerRestore } from '../model/apiContainerRestore';
import { ApiContainerState } from '../model/apiContainerState';
import { ApiContainerUpdateSTM } from '../model/apiContainerUpdateSTM';
import { ApiContainerWall } from '../model/apiContainerWall';
import { ApiResponseServerError } from '../model/apiResponseServerError';

import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
import { Configuration }                                     from '../configuration';


@Injectable({
  providedIn: 'root'
})
export class ApiContainerService {

    protected basePath = 'http://api.samtools.local';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {

        if (configuration) {
            this.configuration = configuration;
            this.configuration.basePath = configuration.basePath || basePath || this.basePath;

        } else {
            this.configuration.basePath = basePath || this.basePath;
        }
    }

    /**
     * @param consumes string[] mime-types
     * @return true: consumes contains 'multipart/form-data', false: otherwise
     */
    private canConsumeForm(consumes: string[]): boolean {
        const form = 'multipart/form-data';
        for (const consume of consumes) {
            if (form === consume) {
                return true;
            }
        }
        return false;
    }


    /**
     * Authentification d&#39;un contenant auprès d&#39;un Supervisor
     * 
     * @param apiContainerOAuth 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public containerAuthentification(apiContainerOAuth: ApiContainerOAuth, observe?: 'body', reportProgress?: boolean): Observable<ApiContainerOAuthResponse>;
    public containerAuthentification(apiContainerOAuth: ApiContainerOAuth, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<ApiContainerOAuthResponse>>;
    public containerAuthentification(apiContainerOAuth: ApiContainerOAuth, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<ApiContainerOAuthResponse>>;
    public containerAuthentification(apiContainerOAuth: ApiContainerOAuth, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (apiContainerOAuth === null || apiContainerOAuth === undefined) {
            throw new Error('Required parameter apiContainerOAuth was null or undefined when calling containerAuthentification.');
        }

        let headers = this.defaultHeaders;


        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<ApiContainerOAuthResponse>(`${this.configuration.basePath}/api/oauth/v2/token`,
            apiContainerOAuth,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Mise à jour du stock au contenant
     * 
     * @param apiContainerRestore 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public containerRestoreComplete(apiContainerRestore: ApiContainerRestore, observe?: 'body', reportProgress?: boolean): Observable<ApiContainerResponse>;
    public containerRestoreComplete(apiContainerRestore: ApiContainerRestore, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<ApiContainerResponse>>;
    public containerRestoreComplete(apiContainerRestore: ApiContainerRestore, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<ApiContainerResponse>>;
    public containerRestoreComplete(apiContainerRestore: ApiContainerRestore, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (apiContainerRestore === null || apiContainerRestore === undefined) {
            throw new Error('Required parameter apiContainerRestore was null or undefined when calling containerRestoreComplete.');
        }

        let headers = this.defaultHeaders;


        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.put<ApiContainerResponse>(`${this.configuration.basePath}/api/containers/restore-complete`,
            apiContainerRestore,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Remonte l&#39;état du contenant
     * 
     * @param apiContainerState 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public containerState(apiContainerState: ApiContainerState, observe?: 'body', reportProgress?: boolean): Observable<ApiContainerResponse>;
    public containerState(apiContainerState: ApiContainerState, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<ApiContainerResponse>>;
    public containerState(apiContainerState: ApiContainerState, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<ApiContainerResponse>>;
    public containerState(apiContainerState: ApiContainerState, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (apiContainerState === null || apiContainerState === undefined) {
            throw new Error('Required parameter apiContainerState was null or undefined when calling containerState.');
        }

        let headers = this.defaultHeaders;


        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.put<ApiContainerResponse>(`${this.configuration.basePath}/api/containers/status`,
            apiContainerState,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Le supervisor met à disposition une mise à jour du STM
     * 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public containerUpdateManager(observe?: 'body', reportProgress?: boolean): Observable<ApiContainerUpdateSTM>;
    public containerUpdateManager(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<ApiContainerUpdateSTM>>;
    public containerUpdateManager(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<ApiContainerUpdateSTM>>;
    public containerUpdateManager(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;


        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<ApiContainerUpdateSTM>(`${this.configuration.basePath}/api/firmware/notifications`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Notifications automatique d&#39;une alerte machine
     * 
     * @param apiContainerAlert 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public createContainerAlertAuto(apiContainerAlert: ApiContainerAlert, observe?: 'body', reportProgress?: boolean): Observable<ApiContainerResponse>;
    public createContainerAlertAuto(apiContainerAlert: ApiContainerAlert, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<ApiContainerResponse>>;
    public createContainerAlertAuto(apiContainerAlert: ApiContainerAlert, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<ApiContainerResponse>>;
    public createContainerAlertAuto(apiContainerAlert: ApiContainerAlert, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (apiContainerAlert === null || apiContainerAlert === undefined) {
            throw new Error('Required parameter apiContainerAlert was null or undefined when calling createContainerAlertAuto.');
        }

        let headers = this.defaultHeaders;


        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<ApiContainerResponse>(`${this.configuration.basePath}/api/containers/alerts`,
            apiContainerAlert,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Notification manuelle d&#39;une alerte machine
     * 
     * @param apiContainerError 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public createContainerAlertManual(apiContainerError: ApiContainerError, observe?: 'body', reportProgress?: boolean): Observable<ApiContainerResponse>;
    public createContainerAlertManual(apiContainerError: ApiContainerError, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<ApiContainerResponse>>;
    public createContainerAlertManual(apiContainerError: ApiContainerError, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<ApiContainerResponse>>;
    public createContainerAlertManual(apiContainerError: ApiContainerError, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (apiContainerError === null || apiContainerError === undefined) {
            throw new Error('Required parameter apiContainerError was null or undefined when calling createContainerAlertManual.');
        }

        let headers = this.defaultHeaders;


        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<ApiContainerResponse>(`${this.configuration.basePath}/api/containers/errors`,
            apiContainerError,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Transmettre aux contenants l’ensemble de sa composition ainsi que l’emplacement des différents article le composant
     * 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public listApiContainerInventory(observe?: 'body', reportProgress?: boolean): Observable<ApiContainerResponse>;
    public listApiContainerInventory(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<ApiContainerResponse>>;
    public listApiContainerInventory(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<ApiContainerResponse>>;
    public listApiContainerInventory(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;


        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<ApiContainerResponse>(`${this.configuration.basePath}/api/containers/inventory`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Transmettre aux contenants l’ensemble de sa composition temporary avant envoi au STM
     * 
     * @param urai urai du contenant
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public listApiContainerUpdate(urai: string, observe?: 'body', reportProgress?: boolean): Observable<ApiContainerResponse>;
    public listApiContainerUpdate(urai: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<ApiContainerResponse>>;
    public listApiContainerUpdate(urai: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<ApiContainerResponse>>;
    public listApiContainerUpdate(urai: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (urai === null || urai === undefined) {
            throw new Error('Required parameter urai was null or undefined when calling listApiContainerUpdate.');
        }

        let headers = this.defaultHeaders;


        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<ApiContainerResponse>(`${this.configuration.basePath}/api/containers/update/${encodeURIComponent(String(urai))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Transmettre aux contenants l’ensemble de sa composition ainsi que la composition des contenants enfants déclarés
     * 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public listApiContainerWall(observe?: 'body', reportProgress?: boolean): Observable<ApiContainerWall>;
    public listApiContainerWall(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<ApiContainerWall>>;
    public listApiContainerWall(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<ApiContainerWall>>;
    public listApiContainerWall(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;


        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<ApiContainerWall>(`${this.configuration.basePath}/api/containers`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

}
