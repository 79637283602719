import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { PermissionDefiner } from '@shared/models/permission';
import { CustomMenuItem } from '@shared/models/custom-menu-item';
import { TranslateService } from '@ngx-translate/core';

@Injectable({
  providedIn: 'root',
})
export class MenuService {
  constructor(private readonly translateService: TranslateService) {
    this.translateAllMenu();
  }

  private readonly _allMenus: CustomMenuItem[] = [
    {
      keyLabel: 'global.dashboard',
      icon: 'store',
      link: '/dashboard/page',
      permission: PermissionDefiner.DASHBOARD.menu,
    },
    {
      keyLabel: 'menu.mySupervisor',
      icon: 'account_circle',
      subItems: [
        {
          keyLabel: 'menu.myCompagny',
          permission: PermissionDefiner.COMPANY.custom,
          link: '/customer/my-company',
        },
        {
          keyLabel: 'global.roles',
          link: '/role/list',
          permission: PermissionDefiner.MYROLE.custom,
        },
        {
          keyLabel: 'global.sites',
          permission: PermissionDefiner.MYSITEMENU.custom,
          link: '/site/list',
        },
        {
          keyLabel: 'global.areas',
          permission: PermissionDefiner.MYAREAMENU.custom,
          link: '/area/list',
        },
        {
          keyLabel: 'global.services',
          permission: PermissionDefiner.MYSERVICEMENU.custom,
          link: '/service/list',
        },
      ],
    },
    {
      keyLabel: 'global.customers',
      icon: 'face',
      subItems: [
        {
          keyLabel: 'global.customers',
          permission: PermissionDefiner.CUSTOMER.menu,
          link: '/customer/list',
        },
        {
          keyLabel: 'global.sites',
          permission: PermissionDefiner.SITE.menu,
          link: '/site/list',
        },
        {
          keyLabel: 'global.areas',
          permission: PermissionDefiner.AREA.menu,
          link: '/area/list',
        },
        {
          keyLabel: 'global.services',
          permission: PermissionDefiner.SERVICE.menu,
          link: '/service/list',
        },
      ],
    },
    {
      keyLabel: 'global.users',
      icon: 'recent_actors',
      subItems: [
        {
          keyLabel: 'global.stsUsers',
          permission: PermissionDefiner.USER.menu,
          link: '/user/list',
        },
        {
          keyLabel: 'global.machineUsers',
          permission: PermissionDefiner.USER_MACHINE.menu,
          link: '/user/machineList',
        },
      ],
    },
    {
      keyLabel: 'global.roles',
      icon: '3p',
      link: '/role/list',
      permission: PermissionDefiner.ROLE.menu,
    },
    {
      keyLabel: 'global.profiles',
      icon: 'manage_accounts',
      link: '/profile/list',
      permission: PermissionDefiner.PROFILE.menu,
    },
    {
      keyLabel: 'global.products',
      icon: 'category',
      subItems: [
        {
          keyLabel: 'global.products',
          link: '/product/list',
          permission: PermissionDefiner.PRODUCT.menu,
        },
        {
          keyLabel: 'global.brands',
          link: '/brand/list',
          permission: PermissionDefiner.BRAND.menu,
        },
        {
          keyLabel: 'global.distributors',
          link: '/distributor/list',
          permission: PermissionDefiner.DISTRIBUTOR.menu,
        },
        {
          keyLabel: 'global.productCategories',
          link: '/product-categories/list',
          permission: PermissionDefiner.PRODUCTCATEGORIES.menu,
        },
        {
          keyLabel: 'global.trackedProducts',
          link: '/tracked-product/list',
          permission: PermissionDefiner.TRACKEDPRODUCT.menu,
        },
        {
          keyLabel: 'global.controlProducts',
          link: '/control-product/list',
          permission: PermissionDefiner.CONTROLPRODUCT.menu,
        },
      ],
    },
    {
      keyLabel: 'global.containers',
      icon: 'inventory_2',
      subItems: [
        {
          keyLabel: 'global.containers',
          link: '/container/list',
          permission: PermissionDefiner.CONTAINER.menu,
        },
        {
          keyLabel: 'menu.containersInPending',
          link: '/container-in-pending/list',
          permission: PermissionDefiner.CONTAINERINPENDING.menu,
        },
        {
          keyLabel: 'menu.logsContainerContract',
          link: '/log-container-contract/list-log',
          permission: PermissionDefiner.LOGCONTAINERCONTRACT.menu,
        },
      ],
    },
    {
      keyLabel: 'global.modelsContainer',
      icon: 'view_comfy',
      subItems: [
        {
          keyLabel: 'global.boxFormat',
          permission: PermissionDefiner.BOXFORMAT.menu,
          link: '/box-format/list',
        },
        {
          keyLabel: 'menu.baseModel',
          permission: PermissionDefiner.CONTAINERMODEL.menu,
          link: '/container-model/list',
        },
      ],
    },
    {
      keyLabel: 'global.movements',
      icon: 'settings_ethernet',
      subItems: [
        {
          keyLabel: 'global.historic',
          permission: PermissionDefiner.MOVEMENT.menu,
          link: '/movement/page/full',
        },
        {
          keyLabel: 'global.statistics',
          permission: PermissionDefiner.MOVEMENT.menu,
          link: '/movement/page/aggregate',
        },
      ],
    },
    {
      keyLabel: 'global.errors',
      icon: 'error',
      link: '/error/list',
      permission: PermissionDefiner.ERROR.menu,
    },
    {
      keyLabel: 'global.assistanceRequests',
      icon: 'help',
      link: '/assistance-request/list',
      permission: PermissionDefiner.ASSISTANCEREQUEST.menu,
    },
    {
      keyLabel: 'global.updatesManager',
      icon: 'update',
      link: '/update-manager/list',
      permission: PermissionDefiner.UPDATEMANAGER.menu,
    },
    {
      keyLabel: 'global.restockingRequests',
      icon: 'autorenew',
      link: '/restocking-request/list',
      permission: PermissionDefiner.RESTOCKINGREQUEST.menu,
    },
    {
      keyLabel: 'global.alertModels',
      icon: 'feedback',
      link: '/alert-model/list',
      permission: PermissionDefiner.ALERTMODEL.menu,
    },
    {
      keyLabel: 'global.alerts',
      icon: 'report',
      link: '/alert/list',
      permission: PermissionDefiner.ALERT.menu,
    },
  ];

  public getMenus(): Observable<CustomMenuItem[]> {
    return of(this._allMenus);
  }

  private translateAllMenu() {
    this._allMenus.map((menu: CustomMenuItem) => {
      this.translateService.get(menu.keyLabel).subscribe((res: string) => {
        let result;
        if (res.includes('{{suffix}}')) {
          result = res.replace('{{suffix}}', '');
        } else {
          result = res;
        }
        menu.label = result;
        if (menu.subItems) {
          menu.subItems.map((subMenu: CustomMenuItem) => {
            this.translateService.get(subMenu.keyLabel).subscribe((resSubMenu: string) => {
              let resultSubMenu;
              if (resSubMenu.includes('{{suffix}}')) {
                resultSubMenu = resSubMenu.replace('{{suffix}}', '');
              } else {
                resultSubMenu = resSubMenu;
              }
              subMenu.label = resultSubMenu;
            });
          });
        }
      });
    });
  }
}
