import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class DevGuard implements CanActivate {
  constructor(private readonly _router: Router) {}

  canActivate(): boolean {
    if (environment.production) {
      this._router.navigate(['/']);
    }
    return !environment.production;
  }
}
