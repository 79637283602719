/**
 * Sam Tools WS
 * Sam Tools Web Services.
 *
 * OpenAPI spec version: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
/* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent }                           from '@angular/common/http';
import { CustomHttpUrlEncodingCodec }                        from '../encoder';

import { Observable }                                        from 'rxjs';

import { ApiResponse } from '../model/apiResponse';
import { Filters } from '../model/filters';
import { ListSitesResponse } from '../model/listSitesResponse';
import { Site } from '../model/site';

import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
import { Configuration }                                     from '../configuration';


@Injectable({
  providedIn: 'root'
})
export class SiteService {

    protected basePath = 'http://api.samtools.local';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {

        if (configuration) {
            this.configuration = configuration;
            this.configuration.basePath = configuration.basePath || basePath || this.basePath;

        } else {
            this.configuration.basePath = basePath || this.basePath;
        }
    }

    /**
     * @param consumes string[] mime-types
     * @return true: consumes contains 'multipart/form-data', false: otherwise
     */
    private canConsumeForm(consumes: string[]): boolean {
        const form = 'multipart/form-data';
        for (const consume of consumes) {
            if (form === consume) {
                return true;
            }
        }
        return false;
    }


    /**
     * Créer un site
     * 
     * @param site 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public create(site: Site, observe?: 'body', reportProgress?: boolean): Observable<Site>;
    public create(site: Site, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Site>>;
    public create(site: Site, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Site>>;
    public create(site: Site, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
      return this.createSite(site, observe, reportProgress);
    }

    /**
     * Créer un site
     * 
     * @param site 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public createSite(site: Site, observe?: 'body', reportProgress?: boolean): Observable<Site>;
    public createSite(site: Site, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Site>>;
    public createSite(site: Site, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Site>>;
    public createSite(site: Site, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (site === null || site === undefined) {
            throw new Error('Required parameter site was null or undefined when calling createSite.');
        }

        let headers = this.defaultHeaders;


        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // authentication (oAuth) required

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<Site>(`${this.configuration.basePath}/site`,
            site,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Supprime un site
     * Suppression d&#39;un site
     * @param siteId Identifiant du site à supprimer
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public _delete(siteId: number, observe?: 'body', reportProgress?: boolean): Observable<ApiResponse>;
    public _delete(siteId: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<ApiResponse>>;
    public _delete(siteId: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<ApiResponse>>;
    public _delete(siteId: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
      return this.deleteSite(siteId, observe, reportProgress);
    }

    /**
     * Supprime un site
     * Suppression d&#39;un site
     * @param siteId Identifiant du site à supprimer
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public deleteSite(siteId: number, observe?: 'body', reportProgress?: boolean): Observable<ApiResponse>;
    public deleteSite(siteId: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<ApiResponse>>;
    public deleteSite(siteId: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<ApiResponse>>;
    public deleteSite(siteId: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (siteId === null || siteId === undefined) {
            throw new Error('Required parameter siteId was null or undefined when calling deleteSite.');
        }

        let headers = this.defaultHeaders;


        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // authentication (oAuth) required

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.delete<ApiResponse>(`${this.configuration.basePath}/site/${encodeURIComponent(String(siteId))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Retourne un site
     * 
     * @param siteId id du site à récupérer
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getById(siteId: number, observe?: 'body', reportProgress?: boolean): Observable<Site>;
    public getById(siteId: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Site>>;
    public getById(siteId: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Site>>;
    public getById(siteId: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
      return this.getSiteById(siteId, observe, reportProgress);
    }

    /**
     * Retourne un site
     * 
     * @param siteId id du site à récupérer
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getSiteById(siteId: number, observe?: 'body', reportProgress?: boolean): Observable<Site>;
    public getSiteById(siteId: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Site>>;
    public getSiteById(siteId: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Site>>;
    public getSiteById(siteId: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (siteId === null || siteId === undefined) {
            throw new Error('Required parameter siteId was null or undefined when calling getSiteById.');
        }

        let headers = this.defaultHeaders;


        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // authentication (oAuth) required

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<Site>(`${this.configuration.basePath}/site/${encodeURIComponent(String(siteId))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Retourne une liste de site
     * 
     * @param filters 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public list(filters: Filters, observe?: 'body', reportProgress?: boolean): Observable<ListSitesResponse>;
    public list(filters: Filters, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<ListSitesResponse>>;
    public list(filters: Filters, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<ListSitesResponse>>;
    public list(filters: Filters, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
      return this.listSites(filters, observe, reportProgress);
    }

    /**
     * Retourne une liste de site
     * 
     * @param filters 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public listSites(filters: Filters, observe?: 'body', reportProgress?: boolean): Observable<ListSitesResponse>;
    public listSites(filters: Filters, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<ListSitesResponse>>;
    public listSites(filters: Filters, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<ListSitesResponse>>;
    public listSites(filters: Filters, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (filters === null || filters === undefined) {
            throw new Error('Required parameter filters was null or undefined when calling listSites.');
        }

        let headers = this.defaultHeaders;


        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // authentication (oAuth) required

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<ListSitesResponse>(`${this.configuration.basePath}/sites`,
            filters,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Met à jour un site
     * MaJ site
     * @param siteId Identifiant du site à mettre à jour
     * @param site 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public update(siteId: number, site: Site, observe?: 'body', reportProgress?: boolean): Observable<ApiResponse>;
    public update(siteId: number, site: Site, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<ApiResponse>>;
    public update(siteId: number, site: Site, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<ApiResponse>>;
    public update(siteId: number, site: Site, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
      return this.updateSite(siteId, site, observe, reportProgress);
    }

    /**
     * Met à jour un site
     * MaJ site
     * @param siteId Identifiant du site à mettre à jour
     * @param site 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public updateSite(siteId: number, site: Site, observe?: 'body', reportProgress?: boolean): Observable<ApiResponse>;
    public updateSite(siteId: number, site: Site, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<ApiResponse>>;
    public updateSite(siteId: number, site: Site, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<ApiResponse>>;
    public updateSite(siteId: number, site: Site, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (siteId === null || siteId === undefined) {
            throw new Error('Required parameter siteId was null or undefined when calling updateSite.');
        }
        if (site === null || site === undefined) {
            throw new Error('Required parameter site was null or undefined when calling updateSite.');
        }

        let headers = this.defaultHeaders;


        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // authentication (oAuth) required

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.put<ApiResponse>(`${this.configuration.basePath}/site/${encodeURIComponent(String(siteId))}`,
            site,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

}
