/**
 * Sam Tools WS
 * Sam Tools Web Services.
 *
 * OpenAPI spec version: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
/* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent }                           from '@angular/common/http';
import { CustomHttpUrlEncodingCodec }                        from '../encoder';

import { Observable }                                        from 'rxjs';

import { ApiResponse } from '../model/apiResponse';
import { ContainerAllocation } from '../model/containerAllocation';
import { ListContainerAllocationResponse } from '../model/listContainerAllocationResponse';
import { ListZoneAllocationResponse } from '../model/listZoneAllocationResponse';
import { StateContainerAllocation } from '../model/stateContainerAllocation';
import { ZoneAllocation } from '../model/zoneAllocation';

import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
import { Configuration }                                     from '../configuration';


@Injectable({
  providedIn: 'root'
})
export class ContainerAllocationService {

    protected basePath = 'http://api.samtools.local';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {

        if (configuration) {
            this.configuration = configuration;
            this.configuration.basePath = configuration.basePath || basePath || this.basePath;

        } else {
            this.configuration.basePath = basePath || this.basePath;
        }
    }

    /**
     * @param consumes string[] mime-types
     * @return true: consumes contains 'multipart/form-data', false: otherwise
     */
    private canConsumeForm(consumes: string[]): boolean {
        const form = 'multipart/form-data';
        for (const consume of consumes) {
            if (form === consume) {
                return true;
            }
        }
        return false;
    }


    /**
     * Ajoute une entrée container allocation à l&#39;api queue
     * 
     * @param containerAllocationId id de l&#39;allocation
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public createApiQueueContainerAllocation(containerAllocationId: number, observe?: 'body', reportProgress?: boolean): Observable<ApiResponse>;
    public createApiQueueContainerAllocation(containerAllocationId: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<ApiResponse>>;
    public createApiQueueContainerAllocation(containerAllocationId: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<ApiResponse>>;
    public createApiQueueContainerAllocation(containerAllocationId: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (containerAllocationId === null || containerAllocationId === undefined) {
            throw new Error('Required parameter containerAllocationId was null or undefined when calling createApiQueueContainerAllocation.');
        }

        let headers = this.defaultHeaders;


        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // authentication (oAuth) required

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.post<ApiResponse>(`${this.configuration.basePath}/container_allocation/add_in_queue_api/${encodeURIComponent(String(containerAllocationId))}`,
            null,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Créer l&#39;allocation de contenant
     * 
     * @param containerAllocation 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public create(containerAllocation: ContainerAllocation, observe?: 'body', reportProgress?: boolean): Observable<ContainerAllocation>;
    public create(containerAllocation: ContainerAllocation, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<ContainerAllocation>>;
    public create(containerAllocation: ContainerAllocation, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<ContainerAllocation>>;
    public create(containerAllocation: ContainerAllocation, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
      return this.createContainerAllocation(containerAllocation, observe, reportProgress);
    }

    /**
     * Créer l&#39;allocation de contenant
     * 
     * @param containerAllocation 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public createContainerAllocation(containerAllocation: ContainerAllocation, observe?: 'body', reportProgress?: boolean): Observable<ContainerAllocation>;
    public createContainerAllocation(containerAllocation: ContainerAllocation, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<ContainerAllocation>>;
    public createContainerAllocation(containerAllocation: ContainerAllocation, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<ContainerAllocation>>;
    public createContainerAllocation(containerAllocation: ContainerAllocation, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (containerAllocation === null || containerAllocation === undefined) {
            throw new Error('Required parameter containerAllocation was null or undefined when calling createContainerAllocation.');
        }

        let headers = this.defaultHeaders;


        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // authentication (oAuth) required

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<ContainerAllocation>(`${this.configuration.basePath}/container_allocation`,
            containerAllocation,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Supprime une allocation de contenant
     * Suppression allocation de contenant
     * @param containerId Identifiant du contenant de l&#39;allocation à supprimer
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public _delete(containerId: number, observe?: 'body', reportProgress?: boolean): Observable<ApiResponse>;
    public _delete(containerId: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<ApiResponse>>;
    public _delete(containerId: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<ApiResponse>>;
    public _delete(containerId: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
      return this.deleteContainerAllocation(containerId, observe, reportProgress);
    }

    /**
     * Supprime une allocation de contenant
     * Suppression allocation de contenant
     * @param containerId Identifiant du contenant de l&#39;allocation à supprimer
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public deleteContainerAllocation(containerId: number, observe?: 'body', reportProgress?: boolean): Observable<ApiResponse>;
    public deleteContainerAllocation(containerId: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<ApiResponse>>;
    public deleteContainerAllocation(containerId: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<ApiResponse>>;
    public deleteContainerAllocation(containerId: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (containerId === null || containerId === undefined) {
            throw new Error('Required parameter containerId was null or undefined when calling deleteContainerAllocation.');
        }

        let headers = this.defaultHeaders;


        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // authentication (oAuth) required

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.delete<ApiResponse>(`${this.configuration.basePath}/container_allocation/${encodeURIComponent(String(containerId))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Supprime une zone d&#39;une allocation de contenant
     * Suppression zone allocation de contenant
     * @param containerId Identifiant du contenant de l&#39;allocation
     * @param zoneAllocation 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public deleteZoneAllocation(containerId: number, zoneAllocation: ZoneAllocation, observe?: 'body', reportProgress?: boolean): Observable<ApiResponse>;
    public deleteZoneAllocation(containerId: number, zoneAllocation: ZoneAllocation, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<ApiResponse>>;
    public deleteZoneAllocation(containerId: number, zoneAllocation: ZoneAllocation, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<ApiResponse>>;
    public deleteZoneAllocation(containerId: number, zoneAllocation: ZoneAllocation, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (containerId === null || containerId === undefined) {
            throw new Error('Required parameter containerId was null or undefined when calling deleteZoneAllocation.');
        }
        if (zoneAllocation === null || zoneAllocation === undefined) {
            throw new Error('Required parameter zoneAllocation was null or undefined when calling deleteZoneAllocation.');
        }

        let headers = this.defaultHeaders;


        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // authentication (oAuth) required

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<ApiResponse>(`${this.configuration.basePath}/container_zones_delete/${encodeURIComponent(String(containerId))}`,
            zoneAllocation,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Retourne une queue api d&#39;allocation du contenant
     * 
     * @param containerAllocationId id de l&#39;allocation
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getApiQueueContainerAllocation(containerAllocationId: number, observe?: 'body', reportProgress?: boolean): Observable<Array<boolean>>;
    public getApiQueueContainerAllocation(containerAllocationId: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<boolean>>>;
    public getApiQueueContainerAllocation(containerAllocationId: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<boolean>>>;
    public getApiQueueContainerAllocation(containerAllocationId: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (containerAllocationId === null || containerAllocationId === undefined) {
            throw new Error('Required parameter containerAllocationId was null or undefined when calling getApiQueueContainerAllocation.');
        }

        let headers = this.defaultHeaders;


        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // authentication (oAuth) required

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<Array<boolean>>(`${this.configuration.basePath}/container_allocation/get_queue_api_container_allocation/${encodeURIComponent(String(containerAllocationId))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Retourne l&#39;allocation du contenant
     * retourne l&#39;allocation du contenant, favorise le retour du temporaire en priorité
     * @param containerId id du contenant pour récupérer l&#39;allocation
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getById(containerId: number, observe?: 'body', reportProgress?: boolean): Observable<ContainerAllocation>;
    public getById(containerId: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<ContainerAllocation>>;
    public getById(containerId: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<ContainerAllocation>>;
    public getById(containerId: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
      return this.getContainerAllocationByContainerId(containerId, observe, reportProgress);
    }

    /**
     * Retourne l&#39;allocation du contenant
     * retourne l&#39;allocation du contenant, favorise le retour du temporaire en priorité
     * @param containerId id du contenant pour récupérer l&#39;allocation
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getContainerAllocationByContainerId(containerId: number, observe?: 'body', reportProgress?: boolean): Observable<ContainerAllocation>;
    public getContainerAllocationByContainerId(containerId: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<ContainerAllocation>>;
    public getContainerAllocationByContainerId(containerId: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<ContainerAllocation>>;
    public getContainerAllocationByContainerId(containerId: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (containerId === null || containerId === undefined) {
            throw new Error('Required parameter containerId was null or undefined when calling getContainerAllocationByContainerId.');
        }

        let headers = this.defaultHeaders;


        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // authentication (oAuth) required

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<ContainerAllocation>(`${this.configuration.basePath}/container_allocation/${encodeURIComponent(String(containerId))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Retourne les allocations d&#39;un article
     * 
     * @param productId id de l&#39;article pour récupérer l&#39;allocation
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getContainerAllocationByProductId(productId: number, observe?: 'body', reportProgress?: boolean): Observable<ListContainerAllocationResponse>;
    public getContainerAllocationByProductId(productId: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<ListContainerAllocationResponse>>;
    public getContainerAllocationByProductId(productId: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<ListContainerAllocationResponse>>;
    public getContainerAllocationByProductId(productId: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (productId === null || productId === undefined) {
            throw new Error('Required parameter productId was null or undefined when calling getContainerAllocationByProductId.');
        }

        let headers = this.defaultHeaders;


        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // authentication (oAuth) required

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<ListContainerAllocationResponse>(`${this.configuration.basePath}/container_allocation/product/${encodeURIComponent(String(productId))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Récupération des articles suivie dans les allocations
     * Retourne les allocations d&#39;un article suivie
     * @param trackedProductId Identifiant de l&#39;article suivie pour récupérer l&#39;allocation
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getContainerAllocationTrackedByProductId(trackedProductId: number, observe?: 'body', reportProgress?: boolean): Observable<ListContainerAllocationResponse>;
    public getContainerAllocationTrackedByProductId(trackedProductId: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<ListContainerAllocationResponse>>;
    public getContainerAllocationTrackedByProductId(trackedProductId: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<ListContainerAllocationResponse>>;
    public getContainerAllocationTrackedByProductId(trackedProductId: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (trackedProductId === null || trackedProductId === undefined) {
            throw new Error('Required parameter trackedProductId was null or undefined when calling getContainerAllocationTrackedByProductId.');
        }

        let headers = this.defaultHeaders;


        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // authentication (oAuth) required

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<ListContainerAllocationResponse>(`${this.configuration.basePath}/container_allocation/trackedProduct/${encodeURIComponent(String(trackedProductId))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Retourne une liste des zones pour un contenant
     * 
     * @param containerId id du contenant pour récupérer l&#39;allocation des zones
     * @param stateContainerAllocation 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public listZoneAllocationByContainerId(containerId: number, stateContainerAllocation: StateContainerAllocation, observe?: 'body', reportProgress?: boolean): Observable<ListZoneAllocationResponse>;
    public listZoneAllocationByContainerId(containerId: number, stateContainerAllocation: StateContainerAllocation, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<ListZoneAllocationResponse>>;
    public listZoneAllocationByContainerId(containerId: number, stateContainerAllocation: StateContainerAllocation, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<ListZoneAllocationResponse>>;
    public listZoneAllocationByContainerId(containerId: number, stateContainerAllocation: StateContainerAllocation, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (containerId === null || containerId === undefined) {
            throw new Error('Required parameter containerId was null or undefined when calling listZoneAllocationByContainerId.');
        }
        if (stateContainerAllocation === null || stateContainerAllocation === undefined) {
            throw new Error('Required parameter stateContainerAllocation was null or undefined when calling listZoneAllocationByContainerId.');
        }

        let headers = this.defaultHeaders;


        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // authentication (oAuth) required

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<ListZoneAllocationResponse>(`${this.configuration.basePath}/container_zones/${encodeURIComponent(String(containerId))}`,
            stateContainerAllocation,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Met à jour une allocation de contenant
     * MaJ allocation de contenant
     * @param containerId Identifiant du contenant pour à mettre à jour l&#39;allocation
     * @param containerAllocation 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public update(containerId: number, containerAllocation: ContainerAllocation, observe?: 'body', reportProgress?: boolean): Observable<ApiResponse>;
    public update(containerId: number, containerAllocation: ContainerAllocation, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<ApiResponse>>;
    public update(containerId: number, containerAllocation: ContainerAllocation, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<ApiResponse>>;
    public update(containerId: number, containerAllocation: ContainerAllocation, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
      return this.updateContainerAllocation(containerId, containerAllocation, observe, reportProgress);
    }

    /**
     * Met à jour une allocation de contenant
     * MaJ allocation de contenant
     * @param containerId Identifiant du contenant pour à mettre à jour l&#39;allocation
     * @param containerAllocation 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public updateContainerAllocation(containerId: number, containerAllocation: ContainerAllocation, observe?: 'body', reportProgress?: boolean): Observable<ApiResponse>;
    public updateContainerAllocation(containerId: number, containerAllocation: ContainerAllocation, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<ApiResponse>>;
    public updateContainerAllocation(containerId: number, containerAllocation: ContainerAllocation, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<ApiResponse>>;
    public updateContainerAllocation(containerId: number, containerAllocation: ContainerAllocation, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (containerId === null || containerId === undefined) {
            throw new Error('Required parameter containerId was null or undefined when calling updateContainerAllocation.');
        }
        if (containerAllocation === null || containerAllocation === undefined) {
            throw new Error('Required parameter containerAllocation was null or undefined when calling updateContainerAllocation.');
        }

        let headers = this.defaultHeaders;


        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // authentication (oAuth) required

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.put<ApiResponse>(`${this.configuration.basePath}/container_allocation/${encodeURIComponent(String(containerId))}`,
            containerAllocation,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

}
