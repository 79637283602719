/**
 * Sam Tools WS
 * Sam Tools Web Services.
 *
 * OpenAPI spec version: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
/* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent }                           from '@angular/common/http';
import { CustomHttpUrlEncodingCodec }                        from '../encoder';

import { Observable }                                        from 'rxjs';

import { ApiResponse } from '../model/apiResponse';
import { ContainerModel } from '../model/containerModel';
import { Filters } from '../model/filters';
import { ListContainerModelsResponse } from '../model/listContainerModelsResponse';

import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
import { Configuration }                                     from '../configuration';


@Injectable({
  providedIn: 'root'
})
export class ContainerModelService {

    protected basePath = 'http://api.samtools.local';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {

        if (configuration) {
            this.configuration = configuration;
            this.configuration.basePath = configuration.basePath || basePath || this.basePath;

        } else {
            this.configuration.basePath = basePath || this.basePath;
        }
    }

    /**
     * @param consumes string[] mime-types
     * @return true: consumes contains 'multipart/form-data', false: otherwise
     */
    private canConsumeForm(consumes: string[]): boolean {
        const form = 'multipart/form-data';
        for (const consume of consumes) {
            if (form === consume) {
                return true;
            }
        }
        return false;
    }


    /**
     * Créer un modèle de contenant
     * 
     * @param containerModel 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public create(containerModel: ContainerModel, observe?: 'body', reportProgress?: boolean): Observable<ContainerModel>;
    public create(containerModel: ContainerModel, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<ContainerModel>>;
    public create(containerModel: ContainerModel, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<ContainerModel>>;
    public create(containerModel: ContainerModel, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
      return this.createContainerModel(containerModel, observe, reportProgress);
    }

    /**
     * Créer un modèle de contenant
     * 
     * @param containerModel 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public createContainerModel(containerModel: ContainerModel, observe?: 'body', reportProgress?: boolean): Observable<ContainerModel>;
    public createContainerModel(containerModel: ContainerModel, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<ContainerModel>>;
    public createContainerModel(containerModel: ContainerModel, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<ContainerModel>>;
    public createContainerModel(containerModel: ContainerModel, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (containerModel === null || containerModel === undefined) {
            throw new Error('Required parameter containerModel was null or undefined when calling createContainerModel.');
        }

        let headers = this.defaultHeaders;


        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // authentication (oAuth) required

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<ContainerModel>(`${this.configuration.basePath}/containerModel`,
            containerModel,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Supprime un modèle de contenant
     * Suppression modèle de contenant
     * @param containerModelId Identifiant du modèle de contenant à supprimer
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public _delete(containerModelId: number, observe?: 'body', reportProgress?: boolean): Observable<ApiResponse>;
    public _delete(containerModelId: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<ApiResponse>>;
    public _delete(containerModelId: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<ApiResponse>>;
    public _delete(containerModelId: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
      return this.deleteContainerModel(containerModelId, observe, reportProgress);
    }

    /**
     * Supprime un modèle de contenant
     * Suppression modèle de contenant
     * @param containerModelId Identifiant du modèle de contenant à supprimer
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public deleteContainerModel(containerModelId: number, observe?: 'body', reportProgress?: boolean): Observable<ApiResponse>;
    public deleteContainerModel(containerModelId: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<ApiResponse>>;
    public deleteContainerModel(containerModelId: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<ApiResponse>>;
    public deleteContainerModel(containerModelId: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (containerModelId === null || containerModelId === undefined) {
            throw new Error('Required parameter containerModelId was null or undefined when calling deleteContainerModel.');
        }

        let headers = this.defaultHeaders;


        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // authentication (oAuth) required

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.delete<ApiResponse>(`${this.configuration.basePath}/containerModel/${encodeURIComponent(String(containerModelId))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Retourne un modèle de contenant
     * 
     * @param containerModelId id du modèle de contenant à récupérer
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getById(containerModelId: number, observe?: 'body', reportProgress?: boolean): Observable<ContainerModel>;
    public getById(containerModelId: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<ContainerModel>>;
    public getById(containerModelId: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<ContainerModel>>;
    public getById(containerModelId: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
      return this.getContainerModelById(containerModelId, observe, reportProgress);
    }

    /**
     * Retourne un modèle de contenant
     * 
     * @param containerModelId id du modèle de contenant à récupérer
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getContainerModelById(containerModelId: number, observe?: 'body', reportProgress?: boolean): Observable<ContainerModel>;
    public getContainerModelById(containerModelId: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<ContainerModel>>;
    public getContainerModelById(containerModelId: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<ContainerModel>>;
    public getContainerModelById(containerModelId: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (containerModelId === null || containerModelId === undefined) {
            throw new Error('Required parameter containerModelId was null or undefined when calling getContainerModelById.');
        }

        let headers = this.defaultHeaders;


        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // authentication (oAuth) required

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<ContainerModel>(`${this.configuration.basePath}/containerModel/${encodeURIComponent(String(containerModelId))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Retourne une liste de modèles de contenant
     * 
     * @param filters 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public list(filters: Filters, observe?: 'body', reportProgress?: boolean): Observable<ListContainerModelsResponse>;
    public list(filters: Filters, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<ListContainerModelsResponse>>;
    public list(filters: Filters, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<ListContainerModelsResponse>>;
    public list(filters: Filters, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
      return this.listContainerModels(filters, observe, reportProgress);
    }

    /**
     * Retourne une liste de modèles de contenant
     * 
     * @param filters 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public listContainerModels(filters: Filters, observe?: 'body', reportProgress?: boolean): Observable<ListContainerModelsResponse>;
    public listContainerModels(filters: Filters, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<ListContainerModelsResponse>>;
    public listContainerModels(filters: Filters, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<ListContainerModelsResponse>>;
    public listContainerModels(filters: Filters, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (filters === null || filters === undefined) {
            throw new Error('Required parameter filters was null or undefined when calling listContainerModels.');
        }

        let headers = this.defaultHeaders;


        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // authentication (oAuth) required

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<ListContainerModelsResponse>(`${this.configuration.basePath}/containerModels`,
            filters,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Met à jour un modèle de contenant
     * MaJ modèle de contenant
     * @param containerModelId Identifiant du modèle de contenant à mettre à jour
     * @param containerModel 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public update(containerModelId: number, containerModel: ContainerModel, observe?: 'body', reportProgress?: boolean): Observable<ApiResponse>;
    public update(containerModelId: number, containerModel: ContainerModel, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<ApiResponse>>;
    public update(containerModelId: number, containerModel: ContainerModel, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<ApiResponse>>;
    public update(containerModelId: number, containerModel: ContainerModel, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
      return this.updateContainerModel(containerModelId, containerModel, observe, reportProgress);
    }

    /**
     * Met à jour un modèle de contenant
     * MaJ modèle de contenant
     * @param containerModelId Identifiant du modèle de contenant à mettre à jour
     * @param containerModel 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public updateContainerModel(containerModelId: number, containerModel: ContainerModel, observe?: 'body', reportProgress?: boolean): Observable<ApiResponse>;
    public updateContainerModel(containerModelId: number, containerModel: ContainerModel, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<ApiResponse>>;
    public updateContainerModel(containerModelId: number, containerModel: ContainerModel, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<ApiResponse>>;
    public updateContainerModel(containerModelId: number, containerModel: ContainerModel, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (containerModelId === null || containerModelId === undefined) {
            throw new Error('Required parameter containerModelId was null or undefined when calling updateContainerModel.');
        }
        if (containerModel === null || containerModel === undefined) {
            throw new Error('Required parameter containerModel was null or undefined when calling updateContainerModel.');
        }

        let headers = this.defaultHeaders;


        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // authentication (oAuth) required

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.put<ApiResponse>(`${this.configuration.basePath}/containerModel/${encodeURIComponent(String(containerModelId))}`,
            containerModel,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

}
