export enum TypeMovement {
  In = 0,
  Out = 1,
}

export enum UserConst {
  ManagementModeExpert = 'expert',
  ManagementModeGuide = 'unit',
}

export enum Language {
  LocaleCodeFR = 'fr-FR',
  LocaleCodeEN = 'en-GB',
  LocaleCodeDE = 'de-DE',
  LocaleCodeNL = 'nl-NL',
  LocaleCodeIT = 'it-IT',
  LocaleCodeES = 'es-ES',
  LocaleCodePT = 'pt-PT',
}

export enum CustomerType {
  Operator = 'Exploitant',
  Distributor = 'Distributeur',
  Integrator = 'Intégrateur',
  Sam = 'SAM',
}

export enum CustomerTypeDB {
  OperatorAuto = 'final-customer-autonome',
  Operator = 'final-customer',
  Distributor = 'distributor',
  Integrator = 'integrator',
}

export enum LinkedType {
  Single = 'Isolé',
  Parent = 'Parent',
  Child = 'Enfant',
}

export enum LinkedTypeDB {
  Single = 'single',
  Parent = 'parent',
  Child = 'child',
}

export enum ContainerStatusTypeDB {
  Ok = 'ok',
  Software = 'software',
  Machine = 'machine',
}

export enum ContainerStatusType {
  Ok = 'OK',
  Software = 'Problème logiciel',
  Machine = 'Problème machine',
}

export enum ContainerAlertTypeDB {
  Warning = 'warning',
  Error = 'error',
  Info = 'info',
}

export enum RoleDb {
  AdminSam = 1,
  Hotline = 2,
  Commercial = 3,
  Support = 4,
  Production = 5,
  Logistique = 6,
  Integrator = 7,
  Distributor = 8,
  FinalCustomerAutonome = 9,
  FinalCustomer = 10,
  Operator = 11,
  Restocker = 12,
}

export enum ParentTypes {
  CustomerVisibilityCustomization = 'customer_visibility_customization',
  ProductCategories = 'product_categories',
  ContainerModel = 'container_model',
  Product = 'product',
  BoxFormat = 'box_format',
  UpdateManager = 'update_manager',
}

export enum PermissionName {
  Area = 'area',
  Container = 'container',
  Customer = 'customer',
  File = 'file',
  Movement = 'movement',
  Product = 'product',
  Role = 'role',
  Service = 'service',
  Site = 'site',
  User = 'user',
  UserAssignmentZone = 'userAssignmentZone',
  Brand = 'brand',
  Distributor = 'distributor',
  ProductCategories = 'productCategories',
  TrackedProduct = 'trackedProduct',
  ControlProduct = 'controlProduct',
  ContainerModel = 'containerModel',
  BoxFormat = 'boxFormat',
  Profile = 'profile',
  Error = 'error',
  ContainerInPending = 'containerInPending',
  AssistanceRequest = 'assistanceRequest',
  ContainerAllocation = 'containerAllocation',
  LogContainerContract = 'logContainerContract',
  UpdateManager = 'updateManager',
  AlertModel = 'alertModel',
  Alert = 'alert',
  RestockingRequest = 'restockingRequest',
  Dashboard = 'dashboard',
}
export enum CustomPermissionName {
  MyAccountMenu = 'user:my-account-menu',
  MyAreaMenu = 'area:my-area-menu',
  MyCompanyMenu = 'customer:my-company-menu',
  MyRoleMenu = 'role:my-role-menu',
  MyServiceMenu = 'service:my-service-menu',
  MySiteMenu = 'site:my-site-menu',
}

export enum ProfileType {
  Schedule = 'schedule',
  Days = 'days',
  Timeslots = 'timeslots',
  Category = 'category',
  Product = 'product',
  Container = 'container',
  Location = 'location',
}

export enum Days {
  Monday = 'monday',
  Tuesday = 'tuesday',
  Wednesday = 'wednesday',
  Thursday = 'thursday',
  Friday = 'friday',
  Saturday = 'saturday',
  Sunday = 'sunday',
}

export enum BoxFormatId {
  H1 = 1,
  H2 = 2,
  H3 = 3,
  H4 = 4,
  C1 = 5,
  C2 = 6,
  C3 = 7,
  C3bis = 8,
  C4 = 9,
}

export enum ColorPaletteGraph {
  PrimaryDark1 = '#668397',
  PrimaryDark2 = '#4c6e86',
  PrimaryDark3 = '#325a75',
  PrimaryDark4 = '#194564',
  PrimaryDark5 = '#003153',
  SecondaryLight1 = '#990000',
  SecondaryLight2 = '#b62e2e',
  SecondaryLight3 = '#dc6262',
  SecondaryLight4 = '#ff8c8c',
  SecondaryLight5 = '#ffa6a1',
  SecondaryDark1 = '#5c1010',
  SecondaryDark2 = '#6f0000',
  SecondaryDark3 = '#560d0d',
  SecondaryDark4 = '#c30101',
  SecondaryDark5 = '#940000',
}

export enum ColorPalette {
  PrimaryLight1 = '#4682b4',
  PrimaryLight2 = '#6fa8dc',
  PrimaryLight3 = '#9fc5e8',
  PrimaryLight4 = '#cce1f4',
  PrimaryLight5 = '#ddecfa',
  PrimaryDark1 = '#668397',
  PrimaryDark2 = '#4c6e86',
  PrimaryDark3 = '#325a75',
  PrimaryDark4 = '#194564',
  PrimaryDark5 = '#003153',
  SecondaryLight1 = '#990000',
  SecondaryLight2 = '#b62e2e',
  SecondaryLight3 = '#dc6262',
  SecondaryLight4 = '#ff8c8c',
  SecondaryLight5 = '#ffa6a1',
  SecondaryDark1 = '#5c1010',
  SecondaryDark2 = '#6f0000',
  SecondaryDark3 = '#560d0d',
  SecondaryDark4 = '#c30101',
  SecondaryDark5 = '#940000',
  Pink = '#ffcdd2',
  RedLight = '#f44336',
  Red = '#d32f2f',
  GreenLight = '#b2dfdb',
  Cyan = '#00bcd4',
  Green = '#009688',
  GreenMid = '#00796b',
  BlueLight = '#536dfe',
  BrownLight = '#795548',
  Brown = '#5d4037',
  OrangeLight = '#ff9800',
  Orange = '#f57c00',
  OrangeDark = '#ff5722',
  Lime = '#cddc39',
  GreenLight2 = '#8bc34a',
  Green2 = '#388e3c',
  PurpleLight = '#e1bee7',
  Purple = '#9c27b0',
  PurpleDark = '#7b1fa2',
}

export enum ColorPalettePrimary {
  PrimaryLight1 = '#4682b4',
  PrimaryLight2 = '#6fa8dc',
  PrimaryLight3 = '#9fc5e8',
  PrimaryLight4 = '#cce1f4',
  PrimaryLight5 = '#ddecfa',
  PrimaryDark1 = '#668397',
  PrimaryDark2 = '#4c6e86',
  PrimaryDark3 = '#325a75',
  PrimaryDark4 = '#194564',
  PrimaryDark5 = '#003153',
}

export enum ColorPaletteSecondary {
  SecondaryLight1 = '#990000',
  SecondaryLight2 = '#b62e2e',
  SecondaryLight3 = '#dc6262',
  SecondaryLight4 = '#ff8c8c',
  SecondaryLight5 = '#ffa6a1',
  SecondaryDark1 = '#5c1010',
  SecondaryDark2 = '#6f0000',
  SecondaryDark3 = '#560d0d',
  SecondaryDark4 = '#c30101',
  SecondaryDark5 = '#940000',
}

export enum State {
  WAITING_FOR_SUPPORT = 'WAITING_FOR_SUPPORT',
  IN_PROGRESS = 'IN_PROGRESS',
  ENDED = 'ENDED',
}

export enum FileSize {
  SIZE_IMAGE_PRODUCT_LIST_WIDTH = 100,
  SIZE_IMAGE_PRODUCT_LIST_HEIGHT = 100,
  SIZE_IMAGE_PRODUCT_DETAIL_WIDTH = 150,
  SIZE_IMAGE_PRODUCT_DETAIL_HEIGHT = 150,
  SIZE_IMAGE_CATEGORY_PRODUCT_WIDTH = 225,
  SIZE_IMAGE_CATEGORY_PRODUCT_HEIGHT = 255,
  SIZE_IMAGE_CUSTOMER_WIDTH = 256,
  SIZE_IMAGE_CUSTOMER_HEIGHT = 256,
  SIZE_IMAGE_CONTAINER_WIDTH = 500,
  SIZE_IMAGE_CONTAINER_HEIGHT = 770,
}

export enum DashboardIcon {
  error = 'Error',
  warning = 'Info',
  critique = 'Critique',
}

export enum TriggerAlert {
  fillingRate = 'fillingRate',
  product = 'product',
  machine = 'machine',
}

export enum PeriodicityAlert {
  dayly = 'dayly',
  weekly = 'weekly',
  monthly = 'monthly',
}

export enum ProblemTypeMachine {
  software = 'software',
  technical = 'technical',
  lowBattery = 'lowBattery',
}

export enum ErrorLevel {
  warning = 'warning',
  error = 'error',
  info = 'info',
}

export enum RestockingRequestStatusDB {
  considered = 'considered',
  pending = 'pending',
}
export enum RestockingRequestStatusLabel {
  considered = 'Pris en compte',
  pending = 'En attente',
}

export enum ActionContractContainer {
  activation = 'activation',
  deactivation = 'deactivation',
  extend = 'extend',
}
