/**
 * Sam Tools WS
 * Sam Tools Web Services.
 *
 * OpenAPI spec version: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
/* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent }                           from '@angular/common/http';
import { CustomHttpUrlEncodingCodec }                        from '../encoder';

import { Observable }                                        from 'rxjs';

import { ApiProductAlert } from '../model/apiProductAlert';
import { ApiProductCustomer } from '../model/apiProductCustomer';
import { ApiProductLocation } from '../model/apiProductLocation';
import { ApiProductLocationResponse } from '../model/apiProductLocationResponse';
import { ApiProductResponse } from '../model/apiProductResponse';
import { ApiResponseServerError } from '../model/apiResponseServerError';

import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
import { Configuration }                                     from '../configuration';


@Injectable({
  providedIn: 'root'
})
export class ApiProductService {

    protected basePath = 'http://api.samtools.local';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {

        if (configuration) {
            this.configuration = configuration;
            this.configuration.basePath = configuration.basePath || basePath || this.basePath;

        } else {
            this.configuration.basePath = basePath || this.basePath;
        }
    }

    /**
     * @param consumes string[] mime-types
     * @return true: consumes contains 'multipart/form-data', false: otherwise
     */
    private canConsumeForm(consumes: string[]): boolean {
        const form = 'multipart/form-data';
        for (const consume of consumes) {
            if (form === consume) {
                return true;
            }
        }
        return false;
    }


    /**
     * Localisation d&#39;un article dans l&#39;ensemble des contenants d&#39;un secteur d&#39;une entreprise
     * 
     * @param apiProductLocation 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiProductLocate(apiProductLocation: ApiProductLocation, observe?: 'body', reportProgress?: boolean): Observable<ApiProductLocationResponse>;
    public apiProductLocate(apiProductLocation: ApiProductLocation, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<ApiProductLocationResponse>>;
    public apiProductLocate(apiProductLocation: ApiProductLocation, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<ApiProductLocationResponse>>;
    public apiProductLocate(apiProductLocation: ApiProductLocation, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (apiProductLocation === null || apiProductLocation === undefined) {
            throw new Error('Required parameter apiProductLocation was null or undefined when calling apiProductLocate.');
        }

        let headers = this.defaultHeaders;


        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<ApiProductLocationResponse>(`${this.configuration.basePath}/api/articles/locate`,
            apiProductLocation,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Création d&#39;une alerte pour un article
     * 
     * @param apiProductAlert 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public createApiProductAlert(apiProductAlert: ApiProductAlert, observe?: 'body', reportProgress?: boolean): Observable<ApiProductResponse>;
    public createApiProductAlert(apiProductAlert: ApiProductAlert, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<ApiProductResponse>>;
    public createApiProductAlert(apiProductAlert: ApiProductAlert, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<ApiProductResponse>>;
    public createApiProductAlert(apiProductAlert: ApiProductAlert, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (apiProductAlert === null || apiProductAlert === undefined) {
            throw new Error('Required parameter apiProductAlert was null or undefined when calling createApiProductAlert.');
        }

        let headers = this.defaultHeaders;


        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<ApiProductResponse>(`${this.configuration.basePath}/api/articles/alerts`,
            apiProductAlert,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Met à jour du catalogue des articles
     * MaJ catalogue article
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getCatalogueArticle(observe?: 'body', reportProgress?: boolean): Observable<ApiProductCustomer>;
    public getCatalogueArticle(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<ApiProductCustomer>>;
    public getCatalogueArticle(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<ApiProductCustomer>>;
    public getCatalogueArticle(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;


        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<ApiProductCustomer>(`${this.configuration.basePath}/api/articles`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

}
