import { User } from '@api/model/user';
import { UserService } from '@api/api/api';
import { first, filter } from 'rxjs/operators';
import { FormSelectOption, KitService, LocalStorageService, WindowItem } from '@adista/window-kit-ui';
import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { Language } from '@shared/models/const';
import { TranslateKitService } from '@shared/services/translate-kit.service';
import { TranslateService } from '@ngx-translate/core';
import { LanguageService } from '@shared/services/language.service';
import { DateAdapter } from '@angular/material/core';
import { Permission } from '@api/model/permission';

@Component({
  selector: 'app-topbar',
  templateUrl: './topbar.component.html',
  styleUrls: ['./topbar.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class TopbarComponent implements OnInit {
  user: User;
  languageUserCode: string;
  languageOptions: FormSelectOption[] = [];
  languageSelectIndex = 0;
  languageSelectCode: string;
  languageSelect: FormSelectOption;
  duplicatedWindow: WindowItem;

  constructor(
    private readonly userService: UserService,
    private kit: KitService,
    private translateKitService: TranslateKitService,
    public readonly translateService: TranslateService,
    private readonly localStorage: LocalStorageService,
    private readonly languageService: LanguageService,
    private readonly dateAdapter: DateAdapter<Date>,
    private readonly translate: TranslateService,
  ) {}

  ngOnInit() {
    this.languageSelectCode = this.localStorage.get('lang');

    this.userService.getCurrentUser().subscribe((user: User) => {
      this.user = user;
      this.languageUserCode = this.languageSelectCode ?? user.locale;
      this.loadSelectLanguage();
    });
  }

  protected loadSelectLanguage() {
    this.languageOptions = [];
    Object.keys(Language).forEach((key: string) => {
      const languageCode = Language[key];
      this.languageOptions.push({ id: languageCode, label: this.translateKitService.translateInstant('language.' + languageCode) });
    });
    this.selectLanguage(this.languageUserCode);
    if (
      this.user &&
      this.user.role.permissions.some((userPermission: Permission) => userPermission.slug.toLowerCase() === 'dashboard:menu'.toLowerCase())
    ) {
      this.kit.routing.openModuleList('/dashboard/page');
    }
  }

  public selectLanguage(languageSelectCode) {
    // On sélectionne la langue
    this.languageSelect = this.languageOptions.find((l) => l.id === languageSelectCode);
    this.languageSelectIndex = this.languageOptions.findIndex((l) => l.id === languageSelectCode);

    this.userService.updateLanguageUserInLocaleStorage({ language: languageSelectCode }).subscribe(() => {
      const languageMini = this.languageService.getLanguageLabelMini(languageSelectCode);
      this.localStorage.set('lang', languageSelectCode);
      this.translateService.use(languageMini);
      this.dateAdapter.setLocale(languageMini);

      if (languageMini !== this.translateService.currentLang) {
        window.location.reload();
      }
    });
  }

  public logout(): void {
    this.kit.desktop
      .closeWorkspace()
      .pipe(
        first(),
        filter((result: boolean) => result),
      )
      .subscribe(() => {
        this.kit.config.services.userService.logout();
        this.localStorage.remove('lang');
        this.translate.use(this.translate.getDefaultLang());
      });
  }

  public goToMyAccount(user): void {
    // Recherche des fenêtres dupliquées
    this.kit.desktop.windows$.subscribe((windowList: WindowItem[]) => {
      for (const window of windowList) {
        if (window.title === user.name + ' ' + user.firstname) {
          this.duplicatedWindow = window;
          break;
        }
      }
    });

    // Bloque l'ouverture de la fenêtre si elle est déjà ouverte + focus
    if (this.duplicatedWindow) {
      this.kit.desktop.setWindowVisibility(this.duplicatedWindow, true);
      return;
    } else {
      this.kit.routing.openModuleForm('user/my-account', {});
    }
  }
}
