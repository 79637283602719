import { Injectable } from '@angular/core';
import { HttpEvent, HttpHandler, HttpRequest, HttpClient, HttpErrorResponse } from '@angular/common/http';
import { HttpInterceptor } from '@angular/common/http';
import { Observable, Subscriber, throwError } from 'rxjs';
import { catchError, mergeMap } from 'rxjs/operators';
import { HttpStatus } from '@shared/models/http-status';
import { OAuthData, OAuthService } from '@shared/services/oauth.service';
import { CurrentUserService } from '@shared/services/currentuser.service';

@Injectable()
export class RefreshTokenService implements HttpInterceptor {
  constructor(
    private readonly oauthService: OAuthService,
    public http: HttpClient,
    private readonly currentUserService: CurrentUserService,
  ) {}

  public intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(req).pipe(
      catchError((error: any) => {
        if (error instanceof HttpErrorResponse && error.status === HttpStatus.Unauthorized) {
          return this.handleUnauthorized(req, next);
        }
        return throwError(error);
      }),
    );
  }

  /**
   * Handle when a request is unauthorized.
   * @param req
   * @param next
   */
  private handleUnauthorized(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const oldToken = Array.from((req.headers as any).headers.values())[0][0].replace('Bearer ', '');
    return new Observable<any>((observer: Subscriber<string>) => {
      this.oauthService.checkIfRefreshToken(oldToken).subscribe(
        (response) => {
          this.oauthService.refreshToken().subscribe(
            (data: any) => {
              this.oauthService.setToken(data as OAuthData);

              const oauthData: OAuthData = this.oauthService.getOAuthData();

              return next.handle(this.addToken(req, oauthData.access_token));
            },
            (errorRefreshToken) => {
              this.currentUserService.logout('tokenExpiredOrUserUpdate');
            },
          );
        },
        (errorCheckIfRefreshToken) => {
          this.currentUserService.logout('tokenExpiredOrUserUpdate');
        },
      );
    });
  }

  /**
   * Add access_token & clone the request.
   * @param request
   * @param token
   */
  private addToken(request: HttpRequest<any>, token: string): HttpRequest<any> {
    return request.clone({
      setHeaders: {
        Authorization: `Bearer ${token}`,
      },
    });
  }
}
