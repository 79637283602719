import { LOCALE_ID, NgModule } from '@angular/core';
import { DatePipe, registerLocaleData } from '@angular/common';
import { HttpClient, HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { InjectableRxStompConfig, RxStompService, rxStompServiceFactory } from '@stomp/ng2-stompjs';
import { WINDOW_MANAGER_CONFIG_PROVIDER, KIT_UI_CONFIG_PROVIDER, KitUIModule, KITUI_RESOURCES_PROVIDER } from '@adista/window-kit-ui';

import { MaterialComponentsModule } from './material-components.module';

import { BASE_PATH, ApiModule, LockService } from '@api/index';

import { configurationProvider } from '@shared/helpers/configuration.provider';
import { CurrentUserService } from '@shared/services/currentuser.service';
import { ErrorMessageService } from '@shared/services/error-message.service';
import { MenuService } from '@shared/services/menu.service';
import { environment } from 'src/environments/environment';
import { samToolsWMConfig, samToolsUIConfig } from './kit.config';
import { rxStompConfig } from './rx-stomp.config';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { TopbarComponent } from '@components/topbar/topbar.component';
import { BoardnavComponent } from '@components/boardnav/boardnav.component';
import { TranslateKitService } from '@shared/services/translate-kit.service';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { RefreshTokenService } from '@shared/services/refresh-token.service';
import { ContainerConfigFormModalComponent } from '@modules/container/container-form/container-config-form-modal/container-config-form-modal.component';
import { IconActiveRendererComponent } from '@components/libs/custom-ag-grid/icon-active-renderer.component';
import { BtnDeleteCellRendererComponent } from '@components/libs/custom-ag-grid/btn-delete-cell-renderer.component';
import { ContainerInPendingPrioriFormModalComponent } from '@modules/container-in-pending/container-in-pending-form-modal/container-in-pending-priori-form-modal/container-in-pending-priori-form-modal.component';
import { BtnAddCustomerCellRendererComponent } from '@components/libs/custom-ag-grid/btn-add-customer-cell-renderer.component';
import { ContainerInPendingPosterioriFormModalComponent } from '@modules/container-in-pending/container-in-pending-form-modal/container-in-pending-posteriori-form-modal/container-in-pending-posteriori-form-modal.component';
import { IconStatusRendererComponent } from '@components/libs/custom-ag-grid/icon-status-renderer.component';
import { BtnDeleteWithoutEditingCellRendererComponent } from '@components/libs/custom-ag-grid/btn-delete-without-editing-cell-renderer.component';
import { LoaderModule } from '@adista/components';
import { IconInOutRendererComponent } from '@components/libs/custom-ag-grid/icon-in-out-renderer.component';
import { IconTrackedProductRendererComponent } from '@components/libs/custom-ag-grid/icon-tracked-product-renderer.component';
import { ColorRendererComponent } from '@components/libs/custom-ag-grid/color-renderer.component';
import { IconInPendingRenderComponent } from '@components/libs/custom-ag-grid/icon-in-pending-render.component';
import { ImportModalComponent } from '@modules/import-error-modal/import-modal.component';
import { MultiDeleteModalComponent } from '@modules/multi-delete-modal/multi-delete-modal.component';
import { AgGridModule } from 'ag-grid-angular';
import { ReactiveFormsModule } from '@angular/forms';
import { CustomAgGridModule } from '@components/libs/custom-ag-grid/custom-ag-grid.module';
import { NumericEditorComponent } from '@components/libs/custom-ag-grid/numeric-editor.component';
import { LinkRendererComponent } from '@components/libs/custom-ag-grid/link-renderer.component';
import { BtnMenuCellRendererComponent } from '@components/libs/custom-ag-grid/btn-menu-cell-renderer.component';
import { DetailRestockingRequestEmailModalComponent } from '@modules/restocking-request/detail-restocking-request-form/detail-restocking-request-email-modal/detail-restocking-request-email-modal.component';
import { NotFoundPageComponent } from '@pages/not-found/not-found-page.component';
import { MAT_DATE_LOCALE } from '@angular/material/core';
import localeFr from '@angular/common/locales/fr';
import { SharedModule } from '@shared/modules/shared.module';
import { ImageRendererComponent } from '@components/libs/custom-ag-grid/image-renderer.component';
import { IconAlertRendererComponent } from '@components/libs/custom-ag-grid/icon-alert-renderer.component';
import { HttpCancelInterceptor } from '@shared/interceptor/http-cancel.interceptor';
import { HttpCancelService } from '@shared/services/http-cancel.service';

export function getApiBasePath(): string {
  return environment.apiAccessPoint;
}

registerLocaleData(localeFr);

@NgModule({
  declarations: [
    AppComponent,
    NotFoundPageComponent,
    TopbarComponent,
    ContainerConfigFormModalComponent,
    ContainerInPendingPrioriFormModalComponent,
    BoardnavComponent,
    ContainerInPendingPosterioriFormModalComponent,
    DetailRestockingRequestEmailModalComponent,
    IconStatusRendererComponent,
    IconInOutRendererComponent,
    BtnDeleteWithoutEditingCellRendererComponent,
    BtnMenuCellRendererComponent,
    ColorRendererComponent,
    ImportModalComponent,
    MultiDeleteModalComponent,
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    MaterialComponentsModule,
    HttpClientModule,
    KitUIModule,
    ApiModule,
    AppRoutingModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient],
      },
    }),
    LoaderModule,
    AgGridModule,
    CustomAgGridModule,
    ReactiveFormsModule,
    SharedModule,
  ],
  entryComponents: [
    AppComponent,
    TopbarComponent,
    IconActiveRendererComponent,
    ImageRendererComponent,
    IconInOutRendererComponent,
    IconTrackedProductRendererComponent,
    BtnDeleteCellRendererComponent,
    BtnDeleteWithoutEditingCellRendererComponent,
    ContainerConfigFormModalComponent,
    ContainerInPendingPrioriFormModalComponent,
    ContainerInPendingPosterioriFormModalComponent,
    DetailRestockingRequestEmailModalComponent,
    BtnAddCustomerCellRendererComponent,
    IconStatusRendererComponent,
    BtnMenuCellRendererComponent,
    ColorRendererComponent,
    IconInPendingRenderComponent,
    ImportModalComponent,
    NumericEditorComponent,
    LinkRendererComponent,
    IconAlertRendererComponent,
  ],
  providers: [
    TranslateKitService,
    HttpCancelService,
    configurationProvider,
    DatePipe,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: RefreshTokenService,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpCancelInterceptor,
      multi: true,
    },
    { provide: MAT_DATE_LOCALE, useValue: 'fr-FR' },
    // OpenAPI
    {
      provide: BASE_PATH,
      useFactory: getApiBasePath,
    },
    // Stomp
    {
      provide: InjectableRxStompConfig,
      useValue: rxStompConfig,
    },
    {
      provide: RxStompService,
      useFactory: rxStompServiceFactory,
      deps: [InjectableRxStompConfig],
    },

    // Kit UI
    {
      provide: WINDOW_MANAGER_CONFIG_PROVIDER,
      useFactory: samToolsWMConfig,
      deps: [CurrentUserService, ErrorMessageService, RxStompService, LockService],
    },
    {
      provide: KIT_UI_CONFIG_PROVIDER,
      useFactory: samToolsUIConfig,
      deps: [MenuService],
    },
    // Resources
    {
      provide: KITUI_RESOURCES_PROVIDER,
      useClass: TranslateKitService,
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}

export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
}
