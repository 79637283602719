/**
 * Sam Tools WS
 * Sam Tools Web Services.
 *
 * OpenAPI spec version: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
/* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent }                           from '@angular/common/http';
import { CustomHttpUrlEncodingCodec }                        from '../encoder';

import { Observable }                                        from 'rxjs';

import { ApiResponse } from '../model/apiResponse';
import { ControlProduct } from '../model/controlProduct';
import { Filters } from '../model/filters';
import { ListControlProductsResponse } from '../model/listControlProductsResponse';
import { TrackedProductControlsProduct } from '../model/trackedProductControlsProduct';

import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
import { Configuration }                                     from '../configuration';


@Injectable({
  providedIn: 'root'
})
export class ControlProductService {

    protected basePath = 'http://api.samtools.local';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {

        if (configuration) {
            this.configuration = configuration;
            this.configuration.basePath = configuration.basePath || basePath || this.basePath;

        } else {
            this.configuration.basePath = basePath || this.basePath;
        }
    }

    /**
     * @param consumes string[] mime-types
     * @return true: consumes contains 'multipart/form-data', false: otherwise
     */
    private canConsumeForm(consumes: string[]): boolean {
        const form = 'multipart/form-data';
        for (const consume of consumes) {
            if (form === consume) {
                return true;
            }
        }
        return false;
    }


    /**
     * Acquitter une notification de contrôle
     * Permet d&#39;acquitter une notification d&#39;un contrôle d&#39;article
     * @param controlProductId Identifiant d&#39;une alerte à supprimer
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public controlAcquittedNotification(controlProductId: number, observe?: 'body', reportProgress?: boolean): Observable<ApiResponse>;
    public controlAcquittedNotification(controlProductId: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<ApiResponse>>;
    public controlAcquittedNotification(controlProductId: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<ApiResponse>>;
    public controlAcquittedNotification(controlProductId: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (controlProductId === null || controlProductId === undefined) {
            throw new Error('Required parameter controlProductId was null or undefined when calling controlAcquittedNotification.');
        }

        let headers = this.defaultHeaders;


        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // authentication (oAuth) required

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.put<ApiResponse>(`${this.configuration.basePath}/controlProduct_acquitted_notification/${encodeURIComponent(String(controlProductId))}`,
            null,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Créer une info de controle
     * 
     * @param controlProduct 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public create(controlProduct: ControlProduct, observe?: 'body', reportProgress?: boolean): Observable<ControlProduct>;
    public create(controlProduct: ControlProduct, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<ControlProduct>>;
    public create(controlProduct: ControlProduct, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<ControlProduct>>;
    public create(controlProduct: ControlProduct, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
      return this.createControlProduct(controlProduct, observe, reportProgress);
    }

    /**
     * Créer une info de controle
     * 
     * @param controlProduct 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public createControlProduct(controlProduct: ControlProduct, observe?: 'body', reportProgress?: boolean): Observable<ControlProduct>;
    public createControlProduct(controlProduct: ControlProduct, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<ControlProduct>>;
    public createControlProduct(controlProduct: ControlProduct, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<ControlProduct>>;
    public createControlProduct(controlProduct: ControlProduct, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (controlProduct === null || controlProduct === undefined) {
            throw new Error('Required parameter controlProduct was null or undefined when calling createControlProduct.');
        }

        let headers = this.defaultHeaders;


        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // authentication (oAuth) required

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<ControlProduct>(`${this.configuration.basePath}/controlProduct`,
            controlProduct,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Créer les info de controle
     * 
     * @param trackedProductControlsProduct 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public createControlsProduct(trackedProductControlsProduct: TrackedProductControlsProduct, observe?: 'body', reportProgress?: boolean): Observable<ApiResponse>;
    public createControlsProduct(trackedProductControlsProduct: TrackedProductControlsProduct, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<ApiResponse>>;
    public createControlsProduct(trackedProductControlsProduct: TrackedProductControlsProduct, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<ApiResponse>>;
    public createControlsProduct(trackedProductControlsProduct: TrackedProductControlsProduct, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (trackedProductControlsProduct === null || trackedProductControlsProduct === undefined) {
            throw new Error('Required parameter trackedProductControlsProduct was null or undefined when calling createControlsProduct.');
        }

        let headers = this.defaultHeaders;


        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // authentication (oAuth) required

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<ApiResponse>(`${this.configuration.basePath}/controlsProduct`,
            trackedProductControlsProduct,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Supprime une info de controle
     * Suppression info de controle
     * @param controlProductId Identifiant de l&#39;info de controle à supprimer
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public _delete(controlProductId: number, observe?: 'body', reportProgress?: boolean): Observable<ApiResponse>;
    public _delete(controlProductId: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<ApiResponse>>;
    public _delete(controlProductId: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<ApiResponse>>;
    public _delete(controlProductId: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
      return this.deleteControlProduct(controlProductId, observe, reportProgress);
    }

    /**
     * Supprime une info de controle
     * Suppression info de controle
     * @param controlProductId Identifiant de l&#39;info de controle à supprimer
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public deleteControlProduct(controlProductId: number, observe?: 'body', reportProgress?: boolean): Observable<ApiResponse>;
    public deleteControlProduct(controlProductId: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<ApiResponse>>;
    public deleteControlProduct(controlProductId: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<ApiResponse>>;
    public deleteControlProduct(controlProductId: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (controlProductId === null || controlProductId === undefined) {
            throw new Error('Required parameter controlProductId was null or undefined when calling deleteControlProduct.');
        }

        let headers = this.defaultHeaders;


        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // authentication (oAuth) required

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.delete<ApiResponse>(`${this.configuration.basePath}/controlProduct/${encodeURIComponent(String(controlProductId))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Retourne une info de controle
     * 
     * @param controlProductId id de l&#39;info de controle à récupérer
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getById(controlProductId: number, observe?: 'body', reportProgress?: boolean): Observable<ControlProduct>;
    public getById(controlProductId: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<ControlProduct>>;
    public getById(controlProductId: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<ControlProduct>>;
    public getById(controlProductId: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
      return this.getControlProductById(controlProductId, observe, reportProgress);
    }

    /**
     * Retourne une info de controle
     * 
     * @param controlProductId id de l&#39;info de controle à récupérer
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getControlProductById(controlProductId: number, observe?: 'body', reportProgress?: boolean): Observable<ControlProduct>;
    public getControlProductById(controlProductId: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<ControlProduct>>;
    public getControlProductById(controlProductId: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<ControlProduct>>;
    public getControlProductById(controlProductId: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (controlProductId === null || controlProductId === undefined) {
            throw new Error('Required parameter controlProductId was null or undefined when calling getControlProductById.');
        }

        let headers = this.defaultHeaders;


        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // authentication (oAuth) required

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<ControlProduct>(`${this.configuration.basePath}/controlProduct/${encodeURIComponent(String(controlProductId))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Retourne une liste d&#39;infos de controle
     * 
     * @param filters 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public list(filters: Filters, observe?: 'body', reportProgress?: boolean): Observable<ListControlProductsResponse>;
    public list(filters: Filters, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<ListControlProductsResponse>>;
    public list(filters: Filters, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<ListControlProductsResponse>>;
    public list(filters: Filters, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
      return this.listControlProducts(filters, observe, reportProgress);
    }

    /**
     * Retourne une liste d&#39;infos de controle
     * 
     * @param filters 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public listControlProducts(filters: Filters, observe?: 'body', reportProgress?: boolean): Observable<ListControlProductsResponse>;
    public listControlProducts(filters: Filters, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<ListControlProductsResponse>>;
    public listControlProducts(filters: Filters, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<ListControlProductsResponse>>;
    public listControlProducts(filters: Filters, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (filters === null || filters === undefined) {
            throw new Error('Required parameter filters was null or undefined when calling listControlProducts.');
        }

        let headers = this.defaultHeaders;


        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // authentication (oAuth) required

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<ListControlProductsResponse>(`${this.configuration.basePath}/controlProducts`,
            filters,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Récupération du nombre de contrôle d&#39;article
     * Retourne le nombre total contrôle d&#39;article en fonction de l&#39;utilisateur
     * @param filters 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public nbControlsProduct(filters: Filters, observe?: 'body', reportProgress?: boolean): Observable<number>;
    public nbControlsProduct(filters: Filters, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<number>>;
    public nbControlsProduct(filters: Filters, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<number>>;
    public nbControlsProduct(filters: Filters, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (filters === null || filters === undefined) {
            throw new Error('Required parameter filters was null or undefined when calling nbControlsProduct.');
        }

        let headers = this.defaultHeaders;


        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // authentication (oAuth) required

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<number>(`${this.configuration.basePath}/controlProducts/count`,
            filters,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Met à jour une info de controle
     * MaJ info de controle
     * @param controlProductId Identifiant de l&#39;info de controle à mettre à jour
     * @param controlProduct 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public update(controlProductId: number, controlProduct: ControlProduct, observe?: 'body', reportProgress?: boolean): Observable<ApiResponse>;
    public update(controlProductId: number, controlProduct: ControlProduct, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<ApiResponse>>;
    public update(controlProductId: number, controlProduct: ControlProduct, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<ApiResponse>>;
    public update(controlProductId: number, controlProduct: ControlProduct, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
      return this.updateControlProduct(controlProductId, controlProduct, observe, reportProgress);
    }

    /**
     * Met à jour une info de controle
     * MaJ info de controle
     * @param controlProductId Identifiant de l&#39;info de controle à mettre à jour
     * @param controlProduct 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public updateControlProduct(controlProductId: number, controlProduct: ControlProduct, observe?: 'body', reportProgress?: boolean): Observable<ApiResponse>;
    public updateControlProduct(controlProductId: number, controlProduct: ControlProduct, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<ApiResponse>>;
    public updateControlProduct(controlProductId: number, controlProduct: ControlProduct, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<ApiResponse>>;
    public updateControlProduct(controlProductId: number, controlProduct: ControlProduct, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (controlProductId === null || controlProductId === undefined) {
            throw new Error('Required parameter controlProductId was null or undefined when calling updateControlProduct.');
        }
        if (controlProduct === null || controlProduct === undefined) {
            throw new Error('Required parameter controlProduct was null or undefined when calling updateControlProduct.');
        }

        let headers = this.defaultHeaders;


        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // authentication (oAuth) required

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.put<ApiResponse>(`${this.configuration.basePath}/controlProduct/${encodeURIComponent(String(controlProductId))}`,
            controlProduct,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

}
