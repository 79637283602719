import { ChangeDetectorRef, Component, HostListener, Inject, Optional, ViewChild } from '@angular/core';
import { LangChangeEvent, TranslateService } from '@ngx-translate/core';
import { CurrentUserService } from '@shared/services/currentuser.service';
import { DatePipe, Location } from '@angular/common';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
import { LicenseManager } from 'ag-grid-enterprise';
import { Language } from '@shared/models/const';
import { FormDatepickerComponent, LocalStorageService } from '@adista/window-kit-ui';
import { LanguageService } from '@shared/services/language.service';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE, MatDateFormats } from '@angular/material/core';
import { MomentDateAdapter } from '@angular/material-moment-adapter';
import * as moment from 'moment';
import { SharedService } from '@shared/services/shared.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
})
export class AppComponent {
  title = 'samTools-project';
  showMenu: boolean;

  @ViewChild(FormDatepickerComponent) datePicker: FormDatepickerComponent;

  constructor(
    private translate: TranslateService,
    private location: Location,
    private currentUserService: CurrentUserService,
    private iconRegistry: MatIconRegistry,
    private sanitizer: DomSanitizer,
    private localStorage: LocalStorageService,
    private languageService: LanguageService,
    private dateAdapter: DateAdapter<Date>,
    private datePipe: DatePipe,
  ) {
    this.getLanguage();

    iconRegistry.addSvgIcon('icon-prdtracked', sanitizer.bypassSecurityTrustResourceUrl('assets/img/icons/icon-prdtracked.svg'));

    this.location.onUrlChange((url) => {
      if (url.startsWith('/login')) {
        this.showMenu = false;
      } else {
        this.showMenu = true;
      }
    });

    LicenseManager.setLicenseKey(
      'CompanyName=ADISTA,LicensedGroup=Adista,LicenseType=MultipleApplications,LicensedConcurrentDeveloperCount=5,LicensedProductionInstancesCount=2,AssetReference=AG-022976,ExpiryDate=3_December_2022_[v2]_MTY3MDAyNTYwMDAwMA==f9c120f1daed949e93ffb86d0f8d353d',
    );
  }
  public getLanguage() {
    this.getLanguages();

    let browserLanguage = navigator.language;
    browserLanguage = this.languageService.getLanguageLabelMaxi(browserLanguage);
    const browserLanguageMini = this.languageService.getLanguageLabelMini(browserLanguage);

    const localStorageLanguage = this.localStorage.get('lang');
    const localStorageLanguageMini = localStorageLanguage ? this.languageService.getLanguageLabelMini(localStorageLanguage) : null;

    const language = localStorageLanguage ?? browserLanguage;
    const languageMini = localStorageLanguageMini ?? browserLanguageMini;

    this.translate.setDefaultLang(languageMini);
    this.translate.use(languageMini);

    this.dateAdapter.setLocale(languageMini);
    moment.locale(languageMini);
  }

  protected getLanguages() {
    const languages = [];
    Object.keys(Language).forEach((key: string) => {
      const languageCode = Language[key];
      const languageMini = this.languageService.getLanguageLabelMini(languageCode);
      languages.push(languageMini);
    });

    this.translate.addLangs(languages);
  }
}
