/**
 * Sam Tools WS
 * Sam Tools Web Services.
 *
 * OpenAPI spec version: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
/* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent }                           from '@angular/common/http';
import { CustomHttpUrlEncodingCodec }                        from '../encoder';

import { Observable }                                        from 'rxjs';

import { ApiResponse } from '../model/apiResponse';
import { DashboardFilters } from '../model/dashboardFilters';
import { Filters } from '../model/filters';
import { ListContainerMovement } from '../model/listContainerMovement';
import { ListMovementsByProductResponse } from '../model/listMovementsByProductResponse';
import { ListMovementsConsumedValueResponse } from '../model/listMovementsConsumedValueResponse';
import { ListMovementsPerDay } from '../model/listMovementsPerDay';
import { ListMovementsProductByContainers } from '../model/listMovementsProductByContainers';
import { ListMovementsResponse } from '../model/listMovementsResponse';
import { ListProductConsumed } from '../model/listProductConsumed';
import { Movement } from '../model/movement';
import { MovementFilter } from '../model/movementFilter';
import { MovementsExportResponse } from '../model/movementsExportResponse';

import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
import { Configuration }                                     from '../configuration';


@Injectable({
  providedIn: 'root'
})
export class MovementService {

    protected basePath = 'http://api.samtools.local';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {

        if (configuration) {
            this.configuration = configuration;
            this.configuration.basePath = configuration.basePath || basePath || this.basePath;

        } else {
            this.configuration.basePath = basePath || this.basePath;
        }
    }

    /**
     * @param consumes string[] mime-types
     * @return true: consumes contains 'multipart/form-data', false: otherwise
     */
    private canConsumeForm(consumes: string[]): boolean {
        const form = 'multipart/form-data';
        for (const consume of consumes) {
            if (form === consume) {
                return true;
            }
        }
        return false;
    }


    /**
     * Créer un mouvement
     * 
     * @param movement 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public create(movement: Movement, observe?: 'body', reportProgress?: boolean): Observable<Movement>;
    public create(movement: Movement, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Movement>>;
    public create(movement: Movement, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Movement>>;
    public create(movement: Movement, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
      return this.createMovement(movement, observe, reportProgress);
    }

    /**
     * Créer un mouvement
     * 
     * @param movement 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public createMovement(movement: Movement, observe?: 'body', reportProgress?: boolean): Observable<Movement>;
    public createMovement(movement: Movement, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Movement>>;
    public createMovement(movement: Movement, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Movement>>;
    public createMovement(movement: Movement, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (movement === null || movement === undefined) {
            throw new Error('Required parameter movement was null or undefined when calling createMovement.');
        }

        let headers = this.defaultHeaders;


        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // authentication (oAuth) required

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<Movement>(`${this.configuration.basePath}/movement`,
            movement,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Supprime un mouvement
     * Suppression d&#39;un mouvement
     * @param movementId Identifiant du mouvement à supprimer
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public _delete(movementId: number, observe?: 'body', reportProgress?: boolean): Observable<ApiResponse>;
    public _delete(movementId: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<ApiResponse>>;
    public _delete(movementId: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<ApiResponse>>;
    public _delete(movementId: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
      return this.deleteMovement(movementId, observe, reportProgress);
    }

    /**
     * Supprime un mouvement
     * Suppression d&#39;un mouvement
     * @param movementId Identifiant du mouvement à supprimer
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public deleteMovement(movementId: number, observe?: 'body', reportProgress?: boolean): Observable<ApiResponse>;
    public deleteMovement(movementId: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<ApiResponse>>;
    public deleteMovement(movementId: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<ApiResponse>>;
    public deleteMovement(movementId: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (movementId === null || movementId === undefined) {
            throw new Error('Required parameter movementId was null or undefined when calling deleteMovement.');
        }

        let headers = this.defaultHeaders;


        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // authentication (oAuth) required

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.delete<ApiResponse>(`${this.configuration.basePath}/movement/${encodeURIComponent(String(movementId))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * export de la liste des entrées/sorties
     * 
     * @param filters 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public exportCSV(filters: Filters, observe?: 'body', reportProgress?: boolean): Observable<MovementsExportResponse>;
    public exportCSV(filters: Filters, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<MovementsExportResponse>>;
    public exportCSV(filters: Filters, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<MovementsExportResponse>>;
    public exportCSV(filters: Filters, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (filters === null || filters === undefined) {
            throw new Error('Required parameter filters was null or undefined when calling exportCSV.');
        }

        let headers = this.defaultHeaders;


        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // authentication (oAuth) required

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<MovementsExportResponse>(`${this.configuration.basePath}/movements/export`,
            filters,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Récupération du contenant le plus utilisé
     * Retourne le contenant le plus utilisé
     * @param movementFilter 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getContainerWithMostNbProductConsumed(movementFilter: MovementFilter, observe?: 'body', reportProgress?: boolean): Observable<ListContainerMovement>;
    public getContainerWithMostNbProductConsumed(movementFilter: MovementFilter, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<ListContainerMovement>>;
    public getContainerWithMostNbProductConsumed(movementFilter: MovementFilter, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<ListContainerMovement>>;
    public getContainerWithMostNbProductConsumed(movementFilter: MovementFilter, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (movementFilter === null || movementFilter === undefined) {
            throw new Error('Required parameter movementFilter was null or undefined when calling getContainerWithMostNbProductConsumed.');
        }

        let headers = this.defaultHeaders;


        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // authentication (oAuth) required

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<ListContainerMovement>(`${this.configuration.basePath}/movements/container_most_use`,
            movementFilter,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Retourne le nombre de sortie d&#39;article
     * 
     * @param dashboardFilters 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getInfoMovementDashboard(dashboardFilters: DashboardFilters, observe?: 'body', reportProgress?: boolean): Observable<ListMovementsResponse>;
    public getInfoMovementDashboard(dashboardFilters: DashboardFilters, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<ListMovementsResponse>>;
    public getInfoMovementDashboard(dashboardFilters: DashboardFilters, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<ListMovementsResponse>>;
    public getInfoMovementDashboard(dashboardFilters: DashboardFilters, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (dashboardFilters === null || dashboardFilters === undefined) {
            throw new Error('Required parameter dashboardFilters was null or undefined when calling getInfoMovementDashboard.');
        }

        let headers = this.defaultHeaders;


        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // authentication (oAuth) required

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<ListMovementsResponse>(`${this.configuration.basePath}/movements/dashboard`,
            dashboardFilters,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Retourne un mouvement
     * 
     * @param movementId id du mouvement à récupérer
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getById(movementId: number, observe?: 'body', reportProgress?: boolean): Observable<Movement>;
    public getById(movementId: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Movement>>;
    public getById(movementId: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Movement>>;
    public getById(movementId: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
      return this.getMovementById(movementId, observe, reportProgress);
    }

    /**
     * Retourne un mouvement
     * 
     * @param movementId id du mouvement à récupérer
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getMovementById(movementId: number, observe?: 'body', reportProgress?: boolean): Observable<Movement>;
    public getMovementById(movementId: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Movement>>;
    public getMovementById(movementId: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Movement>>;
    public getMovementById(movementId: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (movementId === null || movementId === undefined) {
            throw new Error('Required parameter movementId was null or undefined when calling getMovementById.');
        }

        let headers = this.defaultHeaders;


        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // authentication (oAuth) required

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<Movement>(`${this.configuration.basePath}/movement/${encodeURIComponent(String(movementId))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Retourne le nombre d&#39;articles consommés
     * 
     * @param movementFilter 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getNbProductConsumed(movementFilter: MovementFilter, observe?: 'body', reportProgress?: boolean): Observable<number>;
    public getNbProductConsumed(movementFilter: MovementFilter, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<number>>;
    public getNbProductConsumed(movementFilter: MovementFilter, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<number>>;
    public getNbProductConsumed(movementFilter: MovementFilter, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (movementFilter === null || movementFilter === undefined) {
            throw new Error('Required parameter movementFilter was null or undefined when calling getNbProductConsumed.');
        }

        let headers = this.defaultHeaders;


        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // authentication (oAuth) required

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<number>(`${this.configuration.basePath}/movements/nb_product_consumed`,
            movementFilter,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Retourne les produits consommés
     * 
     * @param movementFilter 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getProductConsumed(movementFilter: MovementFilter, observe?: 'body', reportProgress?: boolean): Observable<ListProductConsumed>;
    public getProductConsumed(movementFilter: MovementFilter, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<ListProductConsumed>>;
    public getProductConsumed(movementFilter: MovementFilter, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<ListProductConsumed>>;
    public getProductConsumed(movementFilter: MovementFilter, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (movementFilter === null || movementFilter === undefined) {
            throw new Error('Required parameter movementFilter was null or undefined when calling getProductConsumed.');
        }

        let headers = this.defaultHeaders;


        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // authentication (oAuth) required

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<ListProductConsumed>(`${this.configuration.basePath}/movements/product_consumed`,
            movementFilter,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Retourne les produits consommés par jour
     * 
     * @param movementFilter 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getProductConsumedPerDay(movementFilter: MovementFilter, observe?: 'body', reportProgress?: boolean): Observable<number>;
    public getProductConsumedPerDay(movementFilter: MovementFilter, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<number>>;
    public getProductConsumedPerDay(movementFilter: MovementFilter, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<number>>;
    public getProductConsumedPerDay(movementFilter: MovementFilter, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (movementFilter === null || movementFilter === undefined) {
            throw new Error('Required parameter movementFilter was null or undefined when calling getProductConsumedPerDay.');
        }

        let headers = this.defaultHeaders;


        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // authentication (oAuth) required

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<number>(`${this.configuration.basePath}/movements/product_consumed_per_day`,
            movementFilter,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Retourne une liste de valeur consommé
     * 
     * @param dashboardFilters 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public listConsumedValue(dashboardFilters: DashboardFilters, observe?: 'body', reportProgress?: boolean): Observable<ListMovementsConsumedValueResponse>;
    public listConsumedValue(dashboardFilters: DashboardFilters, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<ListMovementsConsumedValueResponse>>;
    public listConsumedValue(dashboardFilters: DashboardFilters, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<ListMovementsConsumedValueResponse>>;
    public listConsumedValue(dashboardFilters: DashboardFilters, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (dashboardFilters === null || dashboardFilters === undefined) {
            throw new Error('Required parameter dashboardFilters was null or undefined when calling listConsumedValue.');
        }

        let headers = this.defaultHeaders;


        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // authentication (oAuth) required

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<ListMovementsConsumedValueResponse>(`${this.configuration.basePath}/movements/consumed_value`,
            dashboardFilters,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Retourne le nombre des entrées/sorties par jour
     * 
     * @param movementFilter 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public listMovementPerDay(movementFilter: MovementFilter, observe?: 'body', reportProgress?: boolean): Observable<ListMovementsPerDay>;
    public listMovementPerDay(movementFilter: MovementFilter, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<ListMovementsPerDay>>;
    public listMovementPerDay(movementFilter: MovementFilter, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<ListMovementsPerDay>>;
    public listMovementPerDay(movementFilter: MovementFilter, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (movementFilter === null || movementFilter === undefined) {
            throw new Error('Required parameter movementFilter was null or undefined when calling listMovementPerDay.');
        }

        let headers = this.defaultHeaders;


        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // authentication (oAuth) required

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<ListMovementsPerDay>(`${this.configuration.basePath}/movements/movement_per_day`,
            movementFilter,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Retourne le nombre d&#39;articles consommés par contenant
     * 
     * @param movementFilter 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public listMovementProductByContainer(movementFilter: MovementFilter, observe?: 'body', reportProgress?: boolean): Observable<ListMovementsProductByContainers>;
    public listMovementProductByContainer(movementFilter: MovementFilter, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<ListMovementsProductByContainers>>;
    public listMovementProductByContainer(movementFilter: MovementFilter, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<ListMovementsProductByContainers>>;
    public listMovementProductByContainer(movementFilter: MovementFilter, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (movementFilter === null || movementFilter === undefined) {
            throw new Error('Required parameter movementFilter was null or undefined when calling listMovementProductByContainer.');
        }

        let headers = this.defaultHeaders;


        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // authentication (oAuth) required

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<ListMovementsProductByContainers>(`${this.configuration.basePath}/movements/movement_product_by_container`,
            movementFilter,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Retourne une liste de mouvement
     * 
     * @param filters 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public list(filters: Filters, observe?: 'body', reportProgress?: boolean): Observable<ListMovementsResponse>;
    public list(filters: Filters, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<ListMovementsResponse>>;
    public list(filters: Filters, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<ListMovementsResponse>>;
    public list(filters: Filters, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
      return this.listMovements(filters, observe, reportProgress);
    }

    /**
     * Retourne une liste de mouvement
     * 
     * @param filters 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public listMovements(filters: Filters, observe?: 'body', reportProgress?: boolean): Observable<ListMovementsResponse>;
    public listMovements(filters: Filters, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<ListMovementsResponse>>;
    public listMovements(filters: Filters, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<ListMovementsResponse>>;
    public listMovements(filters: Filters, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (filters === null || filters === undefined) {
            throw new Error('Required parameter filters was null or undefined when calling listMovements.');
        }

        let headers = this.defaultHeaders;


        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // authentication (oAuth) required

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<ListMovementsResponse>(`${this.configuration.basePath}/movements`,
            filters,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Retourne une liste de mouvement groupés par référence de produit
     * 
     * @param dashboardFilters 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public listMovementsByProduct(dashboardFilters: DashboardFilters, observe?: 'body', reportProgress?: boolean): Observable<ListMovementsByProductResponse>;
    public listMovementsByProduct(dashboardFilters: DashboardFilters, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<ListMovementsByProductResponse>>;
    public listMovementsByProduct(dashboardFilters: DashboardFilters, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<ListMovementsByProductResponse>>;
    public listMovementsByProduct(dashboardFilters: DashboardFilters, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (dashboardFilters === null || dashboardFilters === undefined) {
            throw new Error('Required parameter dashboardFilters was null or undefined when calling listMovementsByProduct.');
        }

        let headers = this.defaultHeaders;


        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // authentication (oAuth) required

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<ListMovementsByProductResponse>(`${this.configuration.basePath}/movementsByProduct`,
            dashboardFilters,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Met à jour un mouvement
     * MaJ mouvement
     * @param movementId Identifiant du mouvement à mettre à jour
     * @param movement 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public update(movementId: number, movement: Movement, observe?: 'body', reportProgress?: boolean): Observable<ApiResponse>;
    public update(movementId: number, movement: Movement, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<ApiResponse>>;
    public update(movementId: number, movement: Movement, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<ApiResponse>>;
    public update(movementId: number, movement: Movement, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
      return this.updateMovement(movementId, movement, observe, reportProgress);
    }

    /**
     * Met à jour un mouvement
     * MaJ mouvement
     * @param movementId Identifiant du mouvement à mettre à jour
     * @param movement 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public updateMovement(movementId: number, movement: Movement, observe?: 'body', reportProgress?: boolean): Observable<ApiResponse>;
    public updateMovement(movementId: number, movement: Movement, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<ApiResponse>>;
    public updateMovement(movementId: number, movement: Movement, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<ApiResponse>>;
    public updateMovement(movementId: number, movement: Movement, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (movementId === null || movementId === undefined) {
            throw new Error('Required parameter movementId was null or undefined when calling updateMovement.');
        }
        if (movement === null || movement === undefined) {
            throw new Error('Required parameter movement was null or undefined when calling updateMovement.');
        }

        let headers = this.defaultHeaders;


        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // authentication (oAuth) required

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.put<ApiResponse>(`${this.configuration.basePath}/movement/${encodeURIComponent(String(movementId))}`,
            movement,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

}
