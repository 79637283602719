import { Configuration, ConfigurationParameters } from '@api/configuration';
import { OAuthService } from '../services/oauth.service';

const configurationFactory = (oAuthService: OAuthService) => {
  const args: ConfigurationParameters = {};
  args.accessToken = () => {
    return oAuthService.getToken();
  };
  return new Configuration(args);
};

export let configurationProvider = {
  provide: Configuration,
  useFactory: configurationFactory,
  deps: [OAuthService],
};
