/**
 * Sam Tools WS
 * Sam Tools Web Services.
 *
 * OpenAPI spec version: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
/* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent }                           from '@angular/common/http';
import { CustomHttpUrlEncodingCodec }                        from '../encoder';

import { Observable }                                        from 'rxjs';

import { ApiResponse } from '../model/apiResponse';
import { Filters } from '../model/filters';
import { ListUserAssignmentZonesResponse } from '../model/listUserAssignmentZonesResponse';
import { UserAssignmentZone } from '../model/userAssignmentZone';

import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
import { Configuration }                                     from '../configuration';


@Injectable({
  providedIn: 'root'
})
export class UserAssignmentZoneService {

    protected basePath = 'http://api.samtools.local';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {

        if (configuration) {
            this.configuration = configuration;
            this.configuration.basePath = configuration.basePath || basePath || this.basePath;

        } else {
            this.configuration.basePath = basePath || this.basePath;
        }
    }

    /**
     * @param consumes string[] mime-types
     * @return true: consumes contains 'multipart/form-data', false: otherwise
     */
    private canConsumeForm(consumes: string[]): boolean {
        const form = 'multipart/form-data';
        for (const consume of consumes) {
            if (form === consume) {
                return true;
            }
        }
        return false;
    }


    /**
     * Créer un certain nombre d&#39;assignement
     * 
     * @param userAssignmentZone 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public create(userAssignmentZone: UserAssignmentZone, observe?: 'body', reportProgress?: boolean): Observable<UserAssignmentZone>;
    public create(userAssignmentZone: UserAssignmentZone, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<UserAssignmentZone>>;
    public create(userAssignmentZone: UserAssignmentZone, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<UserAssignmentZone>>;
    public create(userAssignmentZone: UserAssignmentZone, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
      return this.createUserAssignmentZone(userAssignmentZone, observe, reportProgress);
    }

    /**
     * Créer un certain nombre d&#39;assignement
     * 
     * @param userAssignmentZone 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public createUserAssignmentZone(userAssignmentZone: UserAssignmentZone, observe?: 'body', reportProgress?: boolean): Observable<UserAssignmentZone>;
    public createUserAssignmentZone(userAssignmentZone: UserAssignmentZone, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<UserAssignmentZone>>;
    public createUserAssignmentZone(userAssignmentZone: UserAssignmentZone, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<UserAssignmentZone>>;
    public createUserAssignmentZone(userAssignmentZone: UserAssignmentZone, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (userAssignmentZone === null || userAssignmentZone === undefined) {
            throw new Error('Required parameter userAssignmentZone was null or undefined when calling createUserAssignmentZone.');
        }

        let headers = this.defaultHeaders;


        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // authentication (oAuth) required

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<UserAssignmentZone>(`${this.configuration.basePath}/userAssignmentZone`,
            userAssignmentZone,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Supprime un certain nombre d&#39;assignement
     * Suppression userAssignmentZone
     * @param userId Id de l&#39;utilisateur
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public _delete(userId: number, observe?: 'body', reportProgress?: boolean): Observable<ApiResponse>;
    public _delete(userId: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<ApiResponse>>;
    public _delete(userId: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<ApiResponse>>;
    public _delete(userId: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
      return this.deleteUserAssignmentZone(userId, observe, reportProgress);
    }

    /**
     * Supprime un certain nombre d&#39;assignement
     * Suppression userAssignmentZone
     * @param userId Id de l&#39;utilisateur
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public deleteUserAssignmentZone(userId: number, observe?: 'body', reportProgress?: boolean): Observable<ApiResponse>;
    public deleteUserAssignmentZone(userId: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<ApiResponse>>;
    public deleteUserAssignmentZone(userId: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<ApiResponse>>;
    public deleteUserAssignmentZone(userId: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (userId === null || userId === undefined) {
            throw new Error('Required parameter userId was null or undefined when calling deleteUserAssignmentZone.');
        }

        let headers = this.defaultHeaders;


        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // authentication (oAuth) required

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.delete<ApiResponse>(`${this.configuration.basePath}/userAssignmentZone/user/${encodeURIComponent(String(userId))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Retourne les zones affectées à un utilisateur
     * 
     * @param userId identifiant de l&#39;utilisateur à récupérer
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getById(userId: number, observe?: 'body', reportProgress?: boolean): Observable<UserAssignmentZone>;
    public getById(userId: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<UserAssignmentZone>>;
    public getById(userId: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<UserAssignmentZone>>;
    public getById(userId: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
      return this.getUserAssignmentZoneByUserId(userId, observe, reportProgress);
    }

    /**
     * Retourne les zones affectées à un utilisateur
     * 
     * @param userId identifiant de l&#39;utilisateur à récupérer
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getUserAssignmentZoneByUserId(userId: number, observe?: 'body', reportProgress?: boolean): Observable<UserAssignmentZone>;
    public getUserAssignmentZoneByUserId(userId: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<UserAssignmentZone>>;
    public getUserAssignmentZoneByUserId(userId: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<UserAssignmentZone>>;
    public getUserAssignmentZoneByUserId(userId: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (userId === null || userId === undefined) {
            throw new Error('Required parameter userId was null or undefined when calling getUserAssignmentZoneByUserId.');
        }

        let headers = this.defaultHeaders;


        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // authentication (oAuth) required

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<UserAssignmentZone>(`${this.configuration.basePath}/userAssignmentZone/user/${encodeURIComponent(String(userId))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Retourne une liste d&#39;userAssignmentZones
     * 
     * @param userId identifiant de l&#39;utilisateur à récupérer
     * @param filters 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public list(userId: number, filters: Filters, observe?: 'body', reportProgress?: boolean): Observable<ListUserAssignmentZonesResponse>;
    public list(userId: number, filters: Filters, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<ListUserAssignmentZonesResponse>>;
    public list(userId: number, filters: Filters, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<ListUserAssignmentZonesResponse>>;
    public list(userId: number, filters: Filters, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
      return this.listUserAssignmentZones(userId, filters, observe, reportProgress);
    }

    /**
     * Retourne une liste d&#39;userAssignmentZones
     * 
     * @param userId identifiant de l&#39;utilisateur à récupérer
     * @param filters 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public listUserAssignmentZones(userId: number, filters: Filters, observe?: 'body', reportProgress?: boolean): Observable<ListUserAssignmentZonesResponse>;
    public listUserAssignmentZones(userId: number, filters: Filters, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<ListUserAssignmentZonesResponse>>;
    public listUserAssignmentZones(userId: number, filters: Filters, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<ListUserAssignmentZonesResponse>>;
    public listUserAssignmentZones(userId: number, filters: Filters, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (userId === null || userId === undefined) {
            throw new Error('Required parameter userId was null or undefined when calling listUserAssignmentZones.');
        }
        if (filters === null || filters === undefined) {
            throw new Error('Required parameter filters was null or undefined when calling listUserAssignmentZones.');
        }

        let headers = this.defaultHeaders;


        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // authentication (oAuth) required

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<ListUserAssignmentZonesResponse>(`${this.configuration.basePath}/userAssignmentZones/user/${encodeURIComponent(String(userId))}`,
            filters,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Met à jour un assignement d&#39;utilisateur
     * MaJ userAssignmentZone
     * @param userId Id de l&#39;utilisateur à mettre à jour
     * @param userAssignmentZone 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public update(userId: number, userAssignmentZone: UserAssignmentZone, observe?: 'body', reportProgress?: boolean): Observable<ApiResponse>;
    public update(userId: number, userAssignmentZone: UserAssignmentZone, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<ApiResponse>>;
    public update(userId: number, userAssignmentZone: UserAssignmentZone, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<ApiResponse>>;
    public update(userId: number, userAssignmentZone: UserAssignmentZone, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
      return this.updateUserAssignmentZone(userId, userAssignmentZone, observe, reportProgress);
    }

    /**
     * Met à jour un assignement d&#39;utilisateur
     * MaJ userAssignmentZone
     * @param userId Id de l&#39;utilisateur à mettre à jour
     * @param userAssignmentZone 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public updateUserAssignmentZone(userId: number, userAssignmentZone: UserAssignmentZone, observe?: 'body', reportProgress?: boolean): Observable<ApiResponse>;
    public updateUserAssignmentZone(userId: number, userAssignmentZone: UserAssignmentZone, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<ApiResponse>>;
    public updateUserAssignmentZone(userId: number, userAssignmentZone: UserAssignmentZone, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<ApiResponse>>;
    public updateUserAssignmentZone(userId: number, userAssignmentZone: UserAssignmentZone, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (userId === null || userId === undefined) {
            throw new Error('Required parameter userId was null or undefined when calling updateUserAssignmentZone.');
        }
        if (userAssignmentZone === null || userAssignmentZone === undefined) {
            throw new Error('Required parameter userAssignmentZone was null or undefined when calling updateUserAssignmentZone.');
        }

        let headers = this.defaultHeaders;


        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // authentication (oAuth) required

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.put<ApiResponse>(`${this.configuration.basePath}/userAssignmentZone/user/${encodeURIComponent(String(userId))}`,
            userAssignmentZone,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

}
