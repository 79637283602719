import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { DynamicRouterModule } from '@adista/window-kit-ui';

import { NotFoundPageComponent } from './pages/not-found/not-found-page.component';
import { DevGuard } from '@shared/guards/dev.guard';

const routes: Routes = [
  {
    path: 'login',
    loadChildren: () => import('./pages/login/login.module').then((m) => m.LoginModule),
  },
  {
    path: 'styleguide',
    loadChildren: () => import('./pages/styleguide/styleguide.module').then((m) => m.StyleguideModule),
    canActivate: [DevGuard],
  },
  {
    path: '**',
    component: NotFoundPageComponent,
  },
];

const dynamicRoutes: Routes = [
  {
    path: 'container',
    loadChildren: () => import('./modules/container/container.module').then((m) => m.ContainerModule),
  },
  {
    path: 'customer',
    loadChildren: () => import('./modules/customer/customer.module').then((m) => m.CustomerModule),
  },
  {
    path: 'movement',
    loadChildren: () => import('./modules/movement/movement.module').then((m) => m.MovementModule),
  },
  {
    path: 'product',
    loadChildren: () => import('./modules/product/product.module').then((m) => m.ProductModule),
  },
  {
    path: 'user',
    loadChildren: () => import('./modules/user/user.module').then((m) => m.UserModule),
  },
  {
    path: 'dashboard',
    loadChildren: () => import('./modules/dashboard/dashboard.module').then((m) => m.DashboardModule),
  },
  {
    path: 'samstyleguide',
    loadChildren: () => import('./modules/samstyleguide/samstyleguide.module').then((m) => m.DashboardModule),
  },
  {
    path: 'site',
    loadChildren: () => import('./modules/site/site.module').then((m) => m.SiteModule),
  },
  {
    path: 'area',
    loadChildren: () => import('./modules/area/area.module').then((m) => m.AreaModule),
  },
  {
    path: 'service',
    loadChildren: () => import('./modules/service/service.module').then((m) => m.ServiceModule),
  },
  {
    path: 'role',
    loadChildren: () => import('./modules/role/role.module').then((m) => m.RoleModule),
  },
  {
    path: 'brand',
    loadChildren: () => import('./modules/brand/brand.module').then((m) => m.BrandModule),
  },
  {
    path: 'distributor',
    loadChildren: () => import('./modules/distributor/distributor.module').then((m) => m.DistributorModule),
  },
  {
    path: 'product-categories',
    loadChildren: () => import('./modules/product-categories/product-categories.module').then((m) => m.ProductCategoriesModule),
  },
  {
    path: 'tracked-product',
    loadChildren: () => import('./modules/tracked-product/tracked-product.module').then((m) => m.TrackedProductModule),
  },
  {
    path: 'control-product',
    loadChildren: () => import('./modules/control-product/control-product.module').then((m) => m.ControlProductModule),
  },
  {
    path: 'container-model',
    loadChildren: () => import('./modules/container-model/container-model.module').then((m) => m.ContainerModelModule),
  },
  {
    path: 'box-format',
    loadChildren: () => import('./modules/box-format/box-format.module').then((m) => m.BoxFormatModule),
  },
  {
    path: 'profile',
    loadChildren: () => import('./modules/profile/profile.module').then((m) => m.ProfileModule),
  },
  {
    path: 'container-in-pending',
    loadChildren: () => import('./modules/container-in-pending/container-in-pending.module').then((m) => m.ContainerInPendingModule),
  },
  {
    path: 'error',
    loadChildren: () => import('./modules/error/error.module').then((m) => m.ErrorModule),
  },
  {
    path: 'assistance-request',
    loadChildren: () => import('./modules/assistance-request/assistance-request.module').then((m) => m.AssistanceRequestModule),
  },
  {
    path: 'log-container-contract',
    loadChildren: () => import('./modules/log-container-contract/log-container-contract.module').then((m) => m.LogContainerContractModule),
  },
  {
    path: 'update-manager',
    loadChildren: () => import('./modules/update-manager/update-manager.module').then((m) => m.UpdateManagerModule),
  },
  {
    path: 'alert-model',
    loadChildren: () => import('./modules/alert-model/alert-model.module').then((m) => m.AlertModelModule),
  },
  {
    path: 'alert',
    loadChildren: () => import('./modules/alert/alert.module').then((m) => m.AlertModule),
  },
  {
    path: 'restocking-request',
    loadChildren: () => import('./modules/restocking-request/restocking-request.module').then((m) => m.RestockingRequestModule),
  },
  {
    path: 'detail-restocking-request',
    loadChildren: () => import('./modules/restocking-request/restocking-request.module').then((m) => m.RestockingRequestModule),
  },
];
@NgModule({
  imports: [RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' }), DynamicRouterModule.forRoot(dynamicRoutes)],
  exports: [RouterModule, DynamicRouterModule],
})
export class AppRoutingModule {}
