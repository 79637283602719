/**
 * Sam Tools WS
 * Sam Tools Web Services.
 *
 * OpenAPI spec version: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
/* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent }                           from '@angular/common/http';
import { CustomHttpUrlEncodingCodec }                        from '../encoder';

import { Observable }                                        from 'rxjs';

import { ForceLock } from '../model/forceLock';
import { ForceLockResponse } from '../model/forceLockResponse';
import { Lock } from '../model/lock';
import { LockResponse } from '../model/lockResponse';
import { Unlock } from '../model/unlock';
import { UnlockResponse } from '../model/unlockResponse';

import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
import { Configuration }                                     from '../configuration';


@Injectable({
  providedIn: 'root'
})
export class LockService {

    protected basePath = 'http://api.samtools.local';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {

        if (configuration) {
            this.configuration = configuration;
            this.configuration.basePath = configuration.basePath || basePath || this.basePath;

        } else {
            this.configuration.basePath = basePath || this.basePath;
        }
    }

    /**
     * @param consumes string[] mime-types
     * @return true: consumes contains 'multipart/form-data', false: otherwise
     */
    private canConsumeForm(consumes: string[]): boolean {
        const form = 'multipart/form-data';
        for (const consume of consumes) {
            if (form === consume) {
                return true;
            }
        }
        return false;
    }


    /**
     * force le lock d&#39;une table déjà lock
     * 
     * @param forceLock 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public forcelock(forceLock: ForceLock, observe?: 'body', reportProgress?: boolean): Observable<ForceLockResponse>;
    public forcelock(forceLock: ForceLock, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<ForceLockResponse>>;
    public forcelock(forceLock: ForceLock, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<ForceLockResponse>>;
    public forcelock(forceLock: ForceLock, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (forceLock === null || forceLock === undefined) {
            throw new Error('Required parameter forceLock was null or undefined when calling forcelock.');
        }

        let headers = this.defaultHeaders;


        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<ForceLockResponse>(`${this.configuration.basePath}/forcelock`,
            forceLock,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Permet de lock une table si celle-ci n&#39;est pas lock ou de savoir qui en est le propriétaire dans le cas contraire
     * 
     * @param lock 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public lock(lock: Lock, observe?: 'body', reportProgress?: boolean): Observable<LockResponse>;
    public lock(lock: Lock, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<LockResponse>>;
    public lock(lock: Lock, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<LockResponse>>;
    public lock(lock: Lock, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (lock === null || lock === undefined) {
            throw new Error('Required parameter lock was null or undefined when calling lock.');
        }

        let headers = this.defaultHeaders;


        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<LockResponse>(`${this.configuration.basePath}/lock`,
            lock,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Unlock une table
     * 
     * @param unlock 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public unlock(unlock: Unlock, observe?: 'body', reportProgress?: boolean): Observable<UnlockResponse>;
    public unlock(unlock: Unlock, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<UnlockResponse>>;
    public unlock(unlock: Unlock, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<UnlockResponse>>;
    public unlock(unlock: Unlock, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (unlock === null || unlock === undefined) {
            throw new Error('Required parameter unlock was null or undefined when calling unlock.');
        }

        let headers = this.defaultHeaders;


        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<UnlockResponse>(`${this.configuration.basePath}/unlock`,
            unlock,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

}
