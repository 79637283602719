/**
 * Sam Tools WS
 * Sam Tools Web Services.
 *
 * OpenAPI spec version: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
/* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent }                           from '@angular/common/http';
import { CustomHttpUrlEncodingCodec }                        from '../encoder';

import { Observable }                                        from 'rxjs';

import { ApiResponse } from '../model/apiResponse';
import { AssistanceRequest } from '../model/assistanceRequest';
import { Filters } from '../model/filters';
import { ListAssistanceRequestsResponse } from '../model/listAssistanceRequestsResponse';

import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
import { Configuration }                                     from '../configuration';


@Injectable({
  providedIn: 'root'
})
export class AssistanceRequestService {

    protected basePath = 'http://api.samtools.local';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {

        if (configuration) {
            this.configuration = configuration;
            this.configuration.basePath = configuration.basePath || basePath || this.basePath;

        } else {
            this.configuration.basePath = basePath || this.basePath;
        }
    }

    /**
     * @param consumes string[] mime-types
     * @return true: consumes contains 'multipart/form-data', false: otherwise
     */
    private canConsumeForm(consumes: string[]): boolean {
        const form = 'multipart/form-data';
        for (const consume of consumes) {
            if (form === consume) {
                return true;
            }
        }
        return false;
    }


    /**
     * Créer une demande d&#39;assistance
     * 
     * @param assistanceRequest 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public create(assistanceRequest: AssistanceRequest, observe?: 'body', reportProgress?: boolean): Observable<AssistanceRequest>;
    public create(assistanceRequest: AssistanceRequest, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<AssistanceRequest>>;
    public create(assistanceRequest: AssistanceRequest, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<AssistanceRequest>>;
    public create(assistanceRequest: AssistanceRequest, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
      return this.createAssistanceRequest(assistanceRequest, observe, reportProgress);
    }

    /**
     * Créer une demande d&#39;assistance
     * 
     * @param assistanceRequest 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public createAssistanceRequest(assistanceRequest: AssistanceRequest, observe?: 'body', reportProgress?: boolean): Observable<AssistanceRequest>;
    public createAssistanceRequest(assistanceRequest: AssistanceRequest, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<AssistanceRequest>>;
    public createAssistanceRequest(assistanceRequest: AssistanceRequest, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<AssistanceRequest>>;
    public createAssistanceRequest(assistanceRequest: AssistanceRequest, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (assistanceRequest === null || assistanceRequest === undefined) {
            throw new Error('Required parameter assistanceRequest was null or undefined when calling createAssistanceRequest.');
        }

        let headers = this.defaultHeaders;


        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // authentication (oAuth) required

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<AssistanceRequest>(`${this.configuration.basePath}/assistanceRequest`,
            assistanceRequest,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Supprime une demande d&#39;assistance
     * Suppression d&#39;une demande d&#39;assistance
     * @param assistanceRequestId Identifiant de la demande d&#39;assistance à supprimer
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public _delete(assistanceRequestId: number, observe?: 'body', reportProgress?: boolean): Observable<ApiResponse>;
    public _delete(assistanceRequestId: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<ApiResponse>>;
    public _delete(assistanceRequestId: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<ApiResponse>>;
    public _delete(assistanceRequestId: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
      return this.deleteAssistanceRequest(assistanceRequestId, observe, reportProgress);
    }

    /**
     * Supprime une demande d&#39;assistance
     * Suppression d&#39;une demande d&#39;assistance
     * @param assistanceRequestId Identifiant de la demande d&#39;assistance à supprimer
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public deleteAssistanceRequest(assistanceRequestId: number, observe?: 'body', reportProgress?: boolean): Observable<ApiResponse>;
    public deleteAssistanceRequest(assistanceRequestId: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<ApiResponse>>;
    public deleteAssistanceRequest(assistanceRequestId: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<ApiResponse>>;
    public deleteAssistanceRequest(assistanceRequestId: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (assistanceRequestId === null || assistanceRequestId === undefined) {
            throw new Error('Required parameter assistanceRequestId was null or undefined when calling deleteAssistanceRequest.');
        }

        let headers = this.defaultHeaders;


        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // authentication (oAuth) required

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.delete<ApiResponse>(`${this.configuration.basePath}/assistanceRequest/${encodeURIComponent(String(assistanceRequestId))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Retourne une demande d&#39;assistance
     * 
     * @param assistanceRequestId id de la demande d&#39;assistance à récupérer
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getById(assistanceRequestId: number, observe?: 'body', reportProgress?: boolean): Observable<AssistanceRequest>;
    public getById(assistanceRequestId: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<AssistanceRequest>>;
    public getById(assistanceRequestId: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<AssistanceRequest>>;
    public getById(assistanceRequestId: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
      return this.getAssistanceRequestById(assistanceRequestId, observe, reportProgress);
    }

    /**
     * Retourne une demande d&#39;assistance
     * 
     * @param assistanceRequestId id de la demande d&#39;assistance à récupérer
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getAssistanceRequestById(assistanceRequestId: number, observe?: 'body', reportProgress?: boolean): Observable<AssistanceRequest>;
    public getAssistanceRequestById(assistanceRequestId: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<AssistanceRequest>>;
    public getAssistanceRequestById(assistanceRequestId: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<AssistanceRequest>>;
    public getAssistanceRequestById(assistanceRequestId: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (assistanceRequestId === null || assistanceRequestId === undefined) {
            throw new Error('Required parameter assistanceRequestId was null or undefined when calling getAssistanceRequestById.');
        }

        let headers = this.defaultHeaders;


        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // authentication (oAuth) required

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<AssistanceRequest>(`${this.configuration.basePath}/assistanceRequest/${encodeURIComponent(String(assistanceRequestId))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Retourne une liste de demande d&#39;assistance
     * 
     * @param filters 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public list(filters: Filters, observe?: 'body', reportProgress?: boolean): Observable<ListAssistanceRequestsResponse>;
    public list(filters: Filters, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<ListAssistanceRequestsResponse>>;
    public list(filters: Filters, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<ListAssistanceRequestsResponse>>;
    public list(filters: Filters, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
      return this.listAssistanceRequests(filters, observe, reportProgress);
    }

    /**
     * Retourne une liste de demande d&#39;assistance
     * 
     * @param filters 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public listAssistanceRequests(filters: Filters, observe?: 'body', reportProgress?: boolean): Observable<ListAssistanceRequestsResponse>;
    public listAssistanceRequests(filters: Filters, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<ListAssistanceRequestsResponse>>;
    public listAssistanceRequests(filters: Filters, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<ListAssistanceRequestsResponse>>;
    public listAssistanceRequests(filters: Filters, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (filters === null || filters === undefined) {
            throw new Error('Required parameter filters was null or undefined when calling listAssistanceRequests.');
        }

        let headers = this.defaultHeaders;


        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // authentication (oAuth) required

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<ListAssistanceRequestsResponse>(`${this.configuration.basePath}/assistanceRequests`,
            filters,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Met à jour une demande d&#39;assistance
     * MaJ demande d&#39;assistance
     * @param assistanceRequestId Identifiant de la demande d&#39;assistance à mettre à jour
     * @param assistanceRequest 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public update(assistanceRequestId: number, assistanceRequest: AssistanceRequest, observe?: 'body', reportProgress?: boolean): Observable<ApiResponse>;
    public update(assistanceRequestId: number, assistanceRequest: AssistanceRequest, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<ApiResponse>>;
    public update(assistanceRequestId: number, assistanceRequest: AssistanceRequest, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<ApiResponse>>;
    public update(assistanceRequestId: number, assistanceRequest: AssistanceRequest, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
      return this.updateAssistanceRequest(assistanceRequestId, assistanceRequest, observe, reportProgress);
    }

    /**
     * Met à jour une demande d&#39;assistance
     * MaJ demande d&#39;assistance
     * @param assistanceRequestId Identifiant de la demande d&#39;assistance à mettre à jour
     * @param assistanceRequest 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public updateAssistanceRequest(assistanceRequestId: number, assistanceRequest: AssistanceRequest, observe?: 'body', reportProgress?: boolean): Observable<ApiResponse>;
    public updateAssistanceRequest(assistanceRequestId: number, assistanceRequest: AssistanceRequest, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<ApiResponse>>;
    public updateAssistanceRequest(assistanceRequestId: number, assistanceRequest: AssistanceRequest, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<ApiResponse>>;
    public updateAssistanceRequest(assistanceRequestId: number, assistanceRequest: AssistanceRequest, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (assistanceRequestId === null || assistanceRequestId === undefined) {
            throw new Error('Required parameter assistanceRequestId was null or undefined when calling updateAssistanceRequest.');
        }
        if (assistanceRequest === null || assistanceRequest === undefined) {
            throw new Error('Required parameter assistanceRequest was null or undefined when calling updateAssistanceRequest.');
        }

        let headers = this.defaultHeaders;


        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // authentication (oAuth) required

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.put<ApiResponse>(`${this.configuration.basePath}/assistanceRequest/${encodeURIComponent(String(assistanceRequestId))}`,
            assistanceRequest,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

}
