/**
 * Sam Tools WS
 * Sam Tools Web Services.
 *
 * OpenAPI spec version: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
/* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent }                           from '@angular/common/http';
import { CustomHttpUrlEncodingCodec }                        from '../encoder';

import { Observable }                                        from 'rxjs';

import { ApiResponse } from '../model/apiResponse';
import { Container } from '../model/container';
import { ContainersExportResponse } from '../model/containersExportResponse';
import { ContractContainer } from '../model/contractContainer';
import { DashboardFilters } from '../model/dashboardFilters';
import { Filters } from '../model/filters';
import { ImportResponse } from '../model/importResponse';
import { ListContainersResponse } from '../model/listContainersResponse';
import { RelatedContainer } from '../model/relatedContainer';

import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
import { Configuration }                                     from '../configuration';


@Injectable({
  providedIn: 'root'
})
export class ContainerService {

    protected basePath = 'http://api.samtools.local';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {

        if (configuration) {
            this.configuration = configuration;
            this.configuration.basePath = configuration.basePath || basePath || this.basePath;

        } else {
            this.configuration.basePath = basePath || this.basePath;
        }
    }

    /**
     * @param consumes string[] mime-types
     * @return true: consumes contains 'multipart/form-data', false: otherwise
     */
    private canConsumeForm(consumes: string[]): boolean {
        const form = 'multipart/form-data';
        for (const consume of consumes) {
            if (form === consume) {
                return true;
            }
        }
        return false;
    }


    /**
     * Programmation dans la file d&#39;attente des mises à jour du Sam tool manager
     * 
     * @param containerId id du contenant pour lequel faire la programmation
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public askUpdateManager(containerId: number, observe?: 'body', reportProgress?: boolean): Observable<ApiResponse>;
    public askUpdateManager(containerId: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<ApiResponse>>;
    public askUpdateManager(containerId: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<ApiResponse>>;
    public askUpdateManager(containerId: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (containerId === null || containerId === undefined) {
            throw new Error('Required parameter containerId was null or undefined when calling askUpdateManager.');
        }

        let headers = this.defaultHeaders;


        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.post<ApiResponse>(`${this.configuration.basePath}/container/ask-update-manager/${encodeURIComponent(String(containerId))}`,
            null,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Changement dans le contrat du contenant
     * 
     * @param contractContainer 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public changeContractContainer(contractContainer: ContractContainer, observe?: 'body', reportProgress?: boolean): Observable<ApiResponse>;
    public changeContractContainer(contractContainer: ContractContainer, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<ApiResponse>>;
    public changeContractContainer(contractContainer: ContractContainer, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<ApiResponse>>;
    public changeContractContainer(contractContainer: ContractContainer, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (contractContainer === null || contractContainer === undefined) {
            throw new Error('Required parameter contractContainer was null or undefined when calling changeContractContainer.');
        }

        let headers = this.defaultHeaders;


        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // authentication (oAuth) required

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<ApiResponse>(`${this.configuration.basePath}/customer/change-contract`,
            contractContainer,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Informe sur les conséquences qu&#39;entraînera un changement de société sur un contenant
     * 
     * @param containerId id du contenant pour lequel faire la vérification
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public checkChangementCustomer(containerId: number, observe?: 'body', reportProgress?: boolean): Observable<ApiResponse>;
    public checkChangementCustomer(containerId: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<ApiResponse>>;
    public checkChangementCustomer(containerId: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<ApiResponse>>;
    public checkChangementCustomer(containerId: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (containerId === null || containerId === undefined) {
            throw new Error('Required parameter containerId was null or undefined when calling checkChangementCustomer.');
        }

        let headers = this.defaultHeaders;


        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<ApiResponse>(`${this.configuration.basePath}/container/check_changement_customer/${encodeURIComponent(String(containerId))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Créer un contenant
     * 
     * @param container 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public create(container: Container, observe?: 'body', reportProgress?: boolean): Observable<Container>;
    public create(container: Container, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Container>>;
    public create(container: Container, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Container>>;
    public create(container: Container, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
      return this.createContainer(container, observe, reportProgress);
    }

    /**
     * Créer un contenant
     * 
     * @param container 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public createContainer(container: Container, observe?: 'body', reportProgress?: boolean): Observable<Container>;
    public createContainer(container: Container, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Container>>;
    public createContainer(container: Container, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Container>>;
    public createContainer(container: Container, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (container === null || container === undefined) {
            throw new Error('Required parameter container was null or undefined when calling createContainer.');
        }

        let headers = this.defaultHeaders;


        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // authentication (oAuth) required

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<Container>(`${this.configuration.basePath}/container`,
            container,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Créer un contenant à priori
     * 
     * @param container 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public createContainerInPending(container: Container, observe?: 'body', reportProgress?: boolean): Observable<Container>;
    public createContainerInPending(container: Container, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Container>>;
    public createContainerInPending(container: Container, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Container>>;
    public createContainerInPending(container: Container, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (container === null || container === undefined) {
            throw new Error('Required parameter container was null or undefined when calling createContainerInPending.');
        }

        let headers = this.defaultHeaders;


        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // authentication (oAuth) required

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<Container>(`${this.configuration.basePath}/container_in_pending`,
            container,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Supprime un contenant
     * Suppression contenant
     * @param containerId Identifiant du contenant à supprimer
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public _delete(containerId: number, observe?: 'body', reportProgress?: boolean): Observable<ApiResponse>;
    public _delete(containerId: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<ApiResponse>>;
    public _delete(containerId: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<ApiResponse>>;
    public _delete(containerId: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
      return this.deleteContainer(containerId, observe, reportProgress);
    }

    /**
     * Supprime un contenant
     * Suppression contenant
     * @param containerId Identifiant du contenant à supprimer
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public deleteContainer(containerId: number, observe?: 'body', reportProgress?: boolean): Observable<ApiResponse>;
    public deleteContainer(containerId: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<ApiResponse>>;
    public deleteContainer(containerId: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<ApiResponse>>;
    public deleteContainer(containerId: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (containerId === null || containerId === undefined) {
            throw new Error('Required parameter containerId was null or undefined when calling deleteContainer.');
        }

        let headers = this.defaultHeaders;


        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // authentication (oAuth) required

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.delete<ApiResponse>(`${this.configuration.basePath}/container/${encodeURIComponent(String(containerId))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * export la liste des contenants
     * 
     * @param filters 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public exportCSV(filters: Filters, observe?: 'body', reportProgress?: boolean): Observable<ContainersExportResponse>;
    public exportCSV(filters: Filters, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<ContainersExportResponse>>;
    public exportCSV(filters: Filters, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<ContainersExportResponse>>;
    public exportCSV(filters: Filters, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (filters === null || filters === undefined) {
            throw new Error('Required parameter filters was null or undefined when calling exportCSV.');
        }

        let headers = this.defaultHeaders;


        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // authentication (oAuth) required

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<ContainersExportResponse>(`${this.configuration.basePath}/containers/export`,
            filters,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Retourne les relations d&#39;un contenant
     * 
     * @param containerId id du contenant à récupérer
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public findRelatedContainer(containerId: number, observe?: 'body', reportProgress?: boolean): Observable<RelatedContainer>;
    public findRelatedContainer(containerId: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<RelatedContainer>>;
    public findRelatedContainer(containerId: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<RelatedContainer>>;
    public findRelatedContainer(containerId: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (containerId === null || containerId === undefined) {
            throw new Error('Required parameter containerId was null or undefined when calling findRelatedContainer.');
        }

        let headers = this.defaultHeaders;


        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // authentication (oAuth) required

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<RelatedContainer>(`${this.configuration.basePath}/container-finding-related/${encodeURIComponent(String(containerId))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Retourne un contenant
     * 
     * @param containerId id du contenant à récupérer
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getById(containerId: number, observe?: 'body', reportProgress?: boolean): Observable<Container>;
    public getById(containerId: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Container>>;
    public getById(containerId: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Container>>;
    public getById(containerId: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
      return this.getContainerById(containerId, observe, reportProgress);
    }

    /**
     * Retourne un contenant
     * 
     * @param containerId id du contenant à récupérer
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getContainerById(containerId: number, observe?: 'body', reportProgress?: boolean): Observable<Container>;
    public getContainerById(containerId: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Container>>;
    public getContainerById(containerId: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Container>>;
    public getContainerById(containerId: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (containerId === null || containerId === undefined) {
            throw new Error('Required parameter containerId was null or undefined when calling getContainerById.');
        }

        let headers = this.defaultHeaders;


        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // authentication (oAuth) required

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<Container>(`${this.configuration.basePath}/container/${encodeURIComponent(String(containerId))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Retourne un contenant par rapport à son urai
     * 
     * @param urai Urai du contenant à récupérer
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getContainerByUrai(urai: string, observe?: 'body', reportProgress?: boolean): Observable<Container>;
    public getContainerByUrai(urai: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Container>>;
    public getContainerByUrai(urai: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Container>>;
    public getContainerByUrai(urai: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (urai === null || urai === undefined) {
            throw new Error('Required parameter urai was null or undefined when calling getContainerByUrai.');
        }

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (urai !== undefined && urai !== null) {
            queryParameters = queryParameters.set('urai', <any>urai);
        }

        let headers = this.defaultHeaders;


        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // authentication (oAuth) required

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<Container>(`${this.configuration.basePath}/container/getByUrai`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Retourne le nombre de contenant par rapport à certains filtres
     * 
     * @param dashboardFilters 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getNbContainers(dashboardFilters: DashboardFilters, observe?: 'body', reportProgress?: boolean): Observable<number>;
    public getNbContainers(dashboardFilters: DashboardFilters, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<number>>;
    public getNbContainers(dashboardFilters: DashboardFilters, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<number>>;
    public getNbContainers(dashboardFilters: DashboardFilters, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (dashboardFilters === null || dashboardFilters === undefined) {
            throw new Error('Required parameter dashboardFilters was null or undefined when calling getNbContainers.');
        }

        let headers = this.defaultHeaders;


        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // authentication (oAuth) required

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<number>(`${this.configuration.basePath}/containers/nb`,
            dashboardFilters,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Import d&#39;un fichier csv
     * 
     * @param file 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public importCSV(file: Blob, observe?: 'body', reportProgress?: boolean): Observable<ImportResponse>;
    public importCSV(file: Blob, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<ImportResponse>>;
    public importCSV(file: Blob, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<ImportResponse>>;
    public importCSV(file: Blob, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (file === null || file === undefined) {
            throw new Error('Required parameter file was null or undefined when calling importCSV.');
        }

        let headers = this.defaultHeaders;


        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // authentication (oAuth) required

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'multipart/form-data'
        ];

        const canConsumeForm = this.canConsumeForm(consumes);

        let formParams: { append(param: string, value: any): any; };
        let useForm = false;
        let convertFormParamsToString = false;
        // use FormData to transmit files using content-type "multipart/form-data"
        // see https://stackoverflow.com/questions/4007969/application-x-www-form-urlencoded-or-multipart-form-data
        useForm = canConsumeForm;
        if (useForm) {
            formParams = new FormData();
        } else {
            formParams = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        }

        if (file !== undefined) {
            formParams = formParams.append('file', <any>file) || formParams;
        }

        return this.httpClient.post<ImportResponse>(`${this.configuration.basePath}/containers/import`,
            convertFormParamsToString ? formParams.toString() : formParams,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Retourne une liste de contenants
     * 
     * @param filters 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public list(filters: Filters, observe?: 'body', reportProgress?: boolean): Observable<ListContainersResponse>;
    public list(filters: Filters, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<ListContainersResponse>>;
    public list(filters: Filters, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<ListContainersResponse>>;
    public list(filters: Filters, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
      return this.listContainers(filters, observe, reportProgress);
    }

    /**
     * Retourne une liste de contenants
     * 
     * @param filters 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public listContainers(filters: Filters, observe?: 'body', reportProgress?: boolean): Observable<ListContainersResponse>;
    public listContainers(filters: Filters, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<ListContainersResponse>>;
    public listContainers(filters: Filters, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<ListContainersResponse>>;
    public listContainers(filters: Filters, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (filters === null || filters === undefined) {
            throw new Error('Required parameter filters was null or undefined when calling listContainers.');
        }

        let headers = this.defaultHeaders;


        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // authentication (oAuth) required

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<ListContainersResponse>(`${this.configuration.basePath}/containers`,
            filters,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Template d&#39;un import d&#39;un fichier csv
     * 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public templateImportCSV(observe?: 'body', reportProgress?: boolean): Observable<ContainersExportResponse>;
    public templateImportCSV(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<ContainersExportResponse>>;
    public templateImportCSV(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<ContainersExportResponse>>;
    public templateImportCSV(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;


        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // authentication (oAuth) required

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<ContainersExportResponse>(`${this.configuration.basePath}/containers/template_import`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Met à jour un contenant
     * MaJ contenant
     * @param containerId Identifiant du contenant à mettre à jour
     * @param container 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public update(containerId: number, container: Container, observe?: 'body', reportProgress?: boolean): Observable<ApiResponse>;
    public update(containerId: number, container: Container, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<ApiResponse>>;
    public update(containerId: number, container: Container, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<ApiResponse>>;
    public update(containerId: number, container: Container, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
      return this.updateContainer(containerId, container, observe, reportProgress);
    }

    /**
     * Met à jour un contenant
     * MaJ contenant
     * @param containerId Identifiant du contenant à mettre à jour
     * @param container 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public updateContainer(containerId: number, container: Container, observe?: 'body', reportProgress?: boolean): Observable<ApiResponse>;
    public updateContainer(containerId: number, container: Container, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<ApiResponse>>;
    public updateContainer(containerId: number, container: Container, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<ApiResponse>>;
    public updateContainer(containerId: number, container: Container, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (containerId === null || containerId === undefined) {
            throw new Error('Required parameter containerId was null or undefined when calling updateContainer.');
        }
        if (container === null || container === undefined) {
            throw new Error('Required parameter container was null or undefined when calling updateContainer.');
        }

        let headers = this.defaultHeaders;


        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // authentication (oAuth) required

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.put<ApiResponse>(`${this.configuration.basePath}/container/${encodeURIComponent(String(containerId))}`,
            container,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

}
