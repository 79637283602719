/**
 * Sam Tools WS
 * Sam Tools Web Services.
 *
 * OpenAPI spec version: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
/* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent }                           from '@angular/common/http';
import { CustomHttpUrlEncodingCodec }                        from '../encoder';

import { Observable }                                        from 'rxjs';

import { ApiResponse } from '../model/apiResponse';
import { Filters } from '../model/filters';
import { ImportResponse } from '../model/importResponse';
import { ListProductsResponse } from '../model/listProductsResponse';
import { Product } from '../model/product';
import { ProductsExportResponse } from '../model/productsExportResponse';
import { RequestBodyListWithoutAffectedTrackedProduct } from '../model/requestBodyListWithoutAffectedTrackedProduct';

import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
import { Configuration }                                     from '../configuration';


@Injectable({
  providedIn: 'root'
})
export class ProductService {

    protected basePath = 'http://api.samtools.local';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {

        if (configuration) {
            this.configuration = configuration;
            this.configuration.basePath = configuration.basePath || basePath || this.basePath;

        } else {
            this.configuration.basePath = basePath || this.basePath;
        }
    }

    /**
     * @param consumes string[] mime-types
     * @return true: consumes contains 'multipart/form-data', false: otherwise
     */
    private canConsumeForm(consumes: string[]): boolean {
        const form = 'multipart/form-data';
        for (const consume of consumes) {
            if (form === consume) {
                return true;
            }
        }
        return false;
    }


    /**
     * Créer un article
     * 
     * @param product 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public create(product: Product, observe?: 'body', reportProgress?: boolean): Observable<Product>;
    public create(product: Product, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Product>>;
    public create(product: Product, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Product>>;
    public create(product: Product, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
      return this.createProduct(product, observe, reportProgress);
    }

    /**
     * Créer un article
     * 
     * @param product 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public createProduct(product: Product, observe?: 'body', reportProgress?: boolean): Observable<Product>;
    public createProduct(product: Product, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Product>>;
    public createProduct(product: Product, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Product>>;
    public createProduct(product: Product, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (product === null || product === undefined) {
            throw new Error('Required parameter product was null or undefined when calling createProduct.');
        }

        let headers = this.defaultHeaders;


        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // authentication (oAuth) required

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<Product>(`${this.configuration.basePath}/product`,
            product,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Supprime un article
     * Suppression d&#39;article
     * @param productId Identifiant de l&#39;article à supprimer
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public _delete(productId: number, observe?: 'body', reportProgress?: boolean): Observable<ApiResponse>;
    public _delete(productId: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<ApiResponse>>;
    public _delete(productId: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<ApiResponse>>;
    public _delete(productId: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
      return this.deleteProduct(productId, observe, reportProgress);
    }

    /**
     * Supprime un article
     * Suppression d&#39;article
     * @param productId Identifiant de l&#39;article à supprimer
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public deleteProduct(productId: number, observe?: 'body', reportProgress?: boolean): Observable<ApiResponse>;
    public deleteProduct(productId: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<ApiResponse>>;
    public deleteProduct(productId: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<ApiResponse>>;
    public deleteProduct(productId: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (productId === null || productId === undefined) {
            throw new Error('Required parameter productId was null or undefined when calling deleteProduct.');
        }

        let headers = this.defaultHeaders;


        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // authentication (oAuth) required

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.delete<ApiResponse>(`${this.configuration.basePath}/product/${encodeURIComponent(String(productId))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * export la liste des articles
     * 
     * @param filters 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public exportCSV(filters: Filters, observe?: 'body', reportProgress?: boolean): Observable<ProductsExportResponse>;
    public exportCSV(filters: Filters, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<ProductsExportResponse>>;
    public exportCSV(filters: Filters, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<ProductsExportResponse>>;
    public exportCSV(filters: Filters, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (filters === null || filters === undefined) {
            throw new Error('Required parameter filters was null or undefined when calling exportCSV.');
        }

        let headers = this.defaultHeaders;


        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // authentication (oAuth) required

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<ProductsExportResponse>(`${this.configuration.basePath}/products/export`,
            filters,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Retourne un article
     * 
     * @param productId id de l&#39;article à récupérer
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getById(productId: number, observe?: 'body', reportProgress?: boolean): Observable<Product>;
    public getById(productId: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Product>>;
    public getById(productId: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Product>>;
    public getById(productId: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
      return this.getProductById(productId, observe, reportProgress);
    }

    /**
     * Retourne un article
     * 
     * @param productId id de l&#39;article à récupérer
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getProductById(productId: number, observe?: 'body', reportProgress?: boolean): Observable<Product>;
    public getProductById(productId: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Product>>;
    public getProductById(productId: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Product>>;
    public getProductById(productId: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (productId === null || productId === undefined) {
            throw new Error('Required parameter productId was null or undefined when calling getProductById.');
        }

        let headers = this.defaultHeaders;


        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // authentication (oAuth) required

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<Product>(`${this.configuration.basePath}/product/${encodeURIComponent(String(productId))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Import d&#39;un fichier csv
     * 
     * @param file 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public importCSV(file: Blob, observe?: 'body', reportProgress?: boolean): Observable<ImportResponse>;
    public importCSV(file: Blob, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<ImportResponse>>;
    public importCSV(file: Blob, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<ImportResponse>>;
    public importCSV(file: Blob, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (file === null || file === undefined) {
            throw new Error('Required parameter file was null or undefined when calling importCSV.');
        }

        let headers = this.defaultHeaders;


        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // authentication (oAuth) required

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'multipart/form-data'
        ];

        const canConsumeForm = this.canConsumeForm(consumes);

        let formParams: { append(param: string, value: any): any; };
        let useForm = false;
        let convertFormParamsToString = false;
        // use FormData to transmit files using content-type "multipart/form-data"
        // see https://stackoverflow.com/questions/4007969/application-x-www-form-urlencoded-or-multipart-form-data
        useForm = canConsumeForm;
        if (useForm) {
            formParams = new FormData();
        } else {
            formParams = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        }

        if (file !== undefined) {
            formParams = formParams.append('file', <any>file) || formParams;
        }

        return this.httpClient.post<ImportResponse>(`${this.configuration.basePath}/products/import`,
            convertFormParamsToString ? formParams.toString() : formParams,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Retourne une liste d&#39;article
     * 
     * @param filters 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public list(filters: Filters, observe?: 'body', reportProgress?: boolean): Observable<ListProductsResponse>;
    public list(filters: Filters, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<ListProductsResponse>>;
    public list(filters: Filters, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<ListProductsResponse>>;
    public list(filters: Filters, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
      return this.listProducts(filters, observe, reportProgress);
    }

    /**
     * Retourne une liste d&#39;article
     * 
     * @param filters 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public listProducts(filters: Filters, observe?: 'body', reportProgress?: boolean): Observable<ListProductsResponse>;
    public listProducts(filters: Filters, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<ListProductsResponse>>;
    public listProducts(filters: Filters, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<ListProductsResponse>>;
    public listProducts(filters: Filters, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (filters === null || filters === undefined) {
            throw new Error('Required parameter filters was null or undefined when calling listProducts.');
        }

        let headers = this.defaultHeaders;


        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // authentication (oAuth) required

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<ListProductsResponse>(`${this.configuration.basePath}/products`,
            filters,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Retourne une liste d&#39;article suivi et non affecté
     * 
     * @param requestBodyListWithoutAffectedTrackedProduct 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public listWithoutAffectedTrackedProduct(requestBodyListWithoutAffectedTrackedProduct: RequestBodyListWithoutAffectedTrackedProduct, observe?: 'body', reportProgress?: boolean): Observable<ListProductsResponse>;
    public listWithoutAffectedTrackedProduct(requestBodyListWithoutAffectedTrackedProduct: RequestBodyListWithoutAffectedTrackedProduct, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<ListProductsResponse>>;
    public listWithoutAffectedTrackedProduct(requestBodyListWithoutAffectedTrackedProduct: RequestBodyListWithoutAffectedTrackedProduct, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<ListProductsResponse>>;
    public listWithoutAffectedTrackedProduct(requestBodyListWithoutAffectedTrackedProduct: RequestBodyListWithoutAffectedTrackedProduct, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (requestBodyListWithoutAffectedTrackedProduct === null || requestBodyListWithoutAffectedTrackedProduct === undefined) {
            throw new Error('Required parameter requestBodyListWithoutAffectedTrackedProduct was null or undefined when calling listWithoutAffectedTrackedProduct.');
        }

        let headers = this.defaultHeaders;


        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // authentication (oAuth) required

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<ListProductsResponse>(`${this.configuration.basePath}/products_tracked_not_allocate`,
            requestBodyListWithoutAffectedTrackedProduct,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Template d&#39;un import d&#39;un fichier csv
     * 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public templateImportCSV(observe?: 'body', reportProgress?: boolean): Observable<ProductsExportResponse>;
    public templateImportCSV(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<ProductsExportResponse>>;
    public templateImportCSV(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<ProductsExportResponse>>;
    public templateImportCSV(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;


        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // authentication (oAuth) required

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<ProductsExportResponse>(`${this.configuration.basePath}/products/template_import`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Met à jour un article
     * MaJ article
     * @param productId Identifiant de l&#39;article à mettre à jour
     * @param product 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public update(productId: number, product: Product, observe?: 'body', reportProgress?: boolean): Observable<ApiResponse>;
    public update(productId: number, product: Product, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<ApiResponse>>;
    public update(productId: number, product: Product, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<ApiResponse>>;
    public update(productId: number, product: Product, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
      return this.updateProduct(productId, product, observe, reportProgress);
    }

    /**
     * Met à jour un article
     * MaJ article
     * @param productId Identifiant de l&#39;article à mettre à jour
     * @param product 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public updateProduct(productId: number, product: Product, observe?: 'body', reportProgress?: boolean): Observable<ApiResponse>;
    public updateProduct(productId: number, product: Product, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<ApiResponse>>;
    public updateProduct(productId: number, product: Product, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<ApiResponse>>;
    public updateProduct(productId: number, product: Product, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (productId === null || productId === undefined) {
            throw new Error('Required parameter productId was null or undefined when calling updateProduct.');
        }
        if (product === null || product === undefined) {
            throw new Error('Required parameter product was null or undefined when calling updateProduct.');
        }

        let headers = this.defaultHeaders;


        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // authentication (oAuth) required

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.put<ApiResponse>(`${this.configuration.basePath}/product/${encodeURIComponent(String(productId))}`,
            product,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

}
