/**
 * Sam Tools WS
 * Sam Tools Web Services.
 *
 * OpenAPI spec version: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
/* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent }                           from '@angular/common/http';
import { CustomHttpUrlEncodingCodec }                        from '../encoder';

import { Observable }                                        from 'rxjs';

import { ApiResponse } from '../model/apiResponse';
import { Brand } from '../model/brand';
import { Filters } from '../model/filters';
import { ListBrandOption } from '../model/listBrandOption';
import { ListBrandsResponse } from '../model/listBrandsResponse';

import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
import { Configuration }                                     from '../configuration';


@Injectable({
  providedIn: 'root'
})
export class BrandService {

    protected basePath = 'http://api.samtools.local';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {

        if (configuration) {
            this.configuration = configuration;
            this.configuration.basePath = configuration.basePath || basePath || this.basePath;

        } else {
            this.configuration.basePath = basePath || this.basePath;
        }
    }

    /**
     * @param consumes string[] mime-types
     * @return true: consumes contains 'multipart/form-data', false: otherwise
     */
    private canConsumeForm(consumes: string[]): boolean {
        const form = 'multipart/form-data';
        for (const consume of consumes) {
            if (form === consume) {
                return true;
            }
        }
        return false;
    }


    /**
     * Créer une marque
     * 
     * @param brand 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public create(brand: Brand, observe?: 'body', reportProgress?: boolean): Observable<Brand>;
    public create(brand: Brand, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Brand>>;
    public create(brand: Brand, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Brand>>;
    public create(brand: Brand, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
      return this.createBrand(brand, observe, reportProgress);
    }

    /**
     * Créer une marque
     * 
     * @param brand 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public createBrand(brand: Brand, observe?: 'body', reportProgress?: boolean): Observable<Brand>;
    public createBrand(brand: Brand, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Brand>>;
    public createBrand(brand: Brand, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Brand>>;
    public createBrand(brand: Brand, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (brand === null || brand === undefined) {
            throw new Error('Required parameter brand was null or undefined when calling createBrand.');
        }

        let headers = this.defaultHeaders;


        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // authentication (oAuth) required

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<Brand>(`${this.configuration.basePath}/brand`,
            brand,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Supprime une marque
     * Suppression d&#39;une marque
     * @param brandId Identifiant de la marque à supprimer
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public _delete(brandId: number, observe?: 'body', reportProgress?: boolean): Observable<ApiResponse>;
    public _delete(brandId: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<ApiResponse>>;
    public _delete(brandId: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<ApiResponse>>;
    public _delete(brandId: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
      return this.deleteBrand(brandId, observe, reportProgress);
    }

    /**
     * Supprime une marque
     * Suppression d&#39;une marque
     * @param brandId Identifiant de la marque à supprimer
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public deleteBrand(brandId: number, observe?: 'body', reportProgress?: boolean): Observable<ApiResponse>;
    public deleteBrand(brandId: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<ApiResponse>>;
    public deleteBrand(brandId: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<ApiResponse>>;
    public deleteBrand(brandId: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (brandId === null || brandId === undefined) {
            throw new Error('Required parameter brandId was null or undefined when calling deleteBrand.');
        }

        let headers = this.defaultHeaders;


        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // authentication (oAuth) required

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.delete<ApiResponse>(`${this.configuration.basePath}/brand/${encodeURIComponent(String(brandId))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Retourne une marque
     * 
     * @param brandId id de la marque à récupérer
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getById(brandId: number, observe?: 'body', reportProgress?: boolean): Observable<Brand>;
    public getById(brandId: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Brand>>;
    public getById(brandId: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Brand>>;
    public getById(brandId: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
      return this.getBrandById(brandId, observe, reportProgress);
    }

    /**
     * Retourne une marque
     * 
     * @param brandId id de la marque à récupérer
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getBrandById(brandId: number, observe?: 'body', reportProgress?: boolean): Observable<Brand>;
    public getBrandById(brandId: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Brand>>;
    public getBrandById(brandId: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Brand>>;
    public getBrandById(brandId: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (brandId === null || brandId === undefined) {
            throw new Error('Required parameter brandId was null or undefined when calling getBrandById.');
        }

        let headers = this.defaultHeaders;


        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // authentication (oAuth) required

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<Brand>(`${this.configuration.basePath}/brand/${encodeURIComponent(String(brandId))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Retourne une liste de marque
     * 
     * @param filters 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public list(filters: Filters, observe?: 'body', reportProgress?: boolean): Observable<ListBrandsResponse>;
    public list(filters: Filters, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<ListBrandsResponse>>;
    public list(filters: Filters, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<ListBrandsResponse>>;
    public list(filters: Filters, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
      return this.listBrands(filters, observe, reportProgress);
    }

    /**
     * Retourne une liste de marque
     * 
     * @param filters 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public listBrands(filters: Filters, observe?: 'body', reportProgress?: boolean): Observable<ListBrandsResponse>;
    public listBrands(filters: Filters, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<ListBrandsResponse>>;
    public listBrands(filters: Filters, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<ListBrandsResponse>>;
    public listBrands(filters: Filters, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (filters === null || filters === undefined) {
            throw new Error('Required parameter filters was null or undefined when calling listBrands.');
        }

        let headers = this.defaultHeaders;


        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // authentication (oAuth) required

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<ListBrandsResponse>(`${this.configuration.basePath}/brands`,
            filters,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Retourne une liste d&#39;options contenant les marques
     * 
     * @param customerId Id du client
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public listOptions(customerId: number, observe?: 'body', reportProgress?: boolean): Observable<ListBrandOption>;
    public listOptions(customerId: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<ListBrandOption>>;
    public listOptions(customerId: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<ListBrandOption>>;
    public listOptions(customerId: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (customerId === null || customerId === undefined) {
            throw new Error('Required parameter customerId was null or undefined when calling listOptions.');
        }

        let headers = this.defaultHeaders;


        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<ListBrandOption>(`${this.configuration.basePath}/brand/options/${encodeURIComponent(String(customerId))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Met à jour une marque
     * MaJ marque
     * @param brandId Identifiant de la marque à mettre à jour
     * @param brand 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public update(brandId: number, brand: Brand, observe?: 'body', reportProgress?: boolean): Observable<ApiResponse>;
    public update(brandId: number, brand: Brand, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<ApiResponse>>;
    public update(brandId: number, brand: Brand, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<ApiResponse>>;
    public update(brandId: number, brand: Brand, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
      return this.updateBrand(brandId, brand, observe, reportProgress);
    }

    /**
     * Met à jour une marque
     * MaJ marque
     * @param brandId Identifiant de la marque à mettre à jour
     * @param brand 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public updateBrand(brandId: number, brand: Brand, observe?: 'body', reportProgress?: boolean): Observable<ApiResponse>;
    public updateBrand(brandId: number, brand: Brand, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<ApiResponse>>;
    public updateBrand(brandId: number, brand: Brand, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<ApiResponse>>;
    public updateBrand(brandId: number, brand: Brand, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (brandId === null || brandId === undefined) {
            throw new Error('Required parameter brandId was null or undefined when calling updateBrand.');
        }
        if (brand === null || brand === undefined) {
            throw new Error('Required parameter brand was null or undefined when calling updateBrand.');
        }

        let headers = this.defaultHeaders;


        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // authentication (oAuth) required

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.put<ApiResponse>(`${this.configuration.basePath}/brand/${encodeURIComponent(String(brandId))}`,
            brand,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

}
