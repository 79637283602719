/**
 * Sam Tools WS
 * Sam Tools Web Services.
 *
 * OpenAPI spec version: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
/* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent }                           from '@angular/common/http';
import { CustomHttpUrlEncodingCodec }                        from '../encoder';

import { Observable }                                        from 'rxjs';

import { ActivateOrDeactivateCustomer } from '../model/activateOrDeactivateCustomer';
import { ActivateOrDeactivateUpdateManagerAuto } from '../model/activateOrDeactivateUpdateManagerAuto';
import { ApiResponse } from '../model/apiResponse';
import { Customer } from '../model/customer';
import { CustomerSiteAreaService } from '../model/customerSiteAreaService';
import { CustomersExportResponse } from '../model/customersExportResponse';
import { Filters } from '../model/filters';
import { ListCustomersResponse } from '../model/listCustomersResponse';

import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
import { Configuration }                                     from '../configuration';


@Injectable({
  providedIn: 'root'
})
export class CustomerService {

    protected basePath = 'http://api.samtools.local';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {

        if (configuration) {
            this.configuration = configuration;
            this.configuration.basePath = configuration.basePath || basePath || this.basePath;

        } else {
            this.configuration.basePath = basePath || this.basePath;
        }
    }

    /**
     * @param consumes string[] mime-types
     * @return true: consumes contains 'multipart/form-data', false: otherwise
     */
    private canConsumeForm(consumes: string[]): boolean {
        const form = 'multipart/form-data';
        for (const consume of consumes) {
            if (form === consume) {
                return true;
            }
        }
        return false;
    }


    /**
     * Activer/désactiver un client
     * 
     * @param activateOrDeactivateCustomer 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public activateOrDeactivateCustomer(activateOrDeactivateCustomer: ActivateOrDeactivateCustomer, observe?: 'body', reportProgress?: boolean): Observable<ApiResponse>;
    public activateOrDeactivateCustomer(activateOrDeactivateCustomer: ActivateOrDeactivateCustomer, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<ApiResponse>>;
    public activateOrDeactivateCustomer(activateOrDeactivateCustomer: ActivateOrDeactivateCustomer, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<ApiResponse>>;
    public activateOrDeactivateCustomer(activateOrDeactivateCustomer: ActivateOrDeactivateCustomer, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (activateOrDeactivateCustomer === null || activateOrDeactivateCustomer === undefined) {
            throw new Error('Required parameter activateOrDeactivateCustomer was null or undefined when calling activateOrDeactivateCustomer.');
        }

        let headers = this.defaultHeaders;


        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<ApiResponse>(`${this.configuration.basePath}/customer/change-activate`,
            activateOrDeactivateCustomer,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Activer/désactiver les mises à jour automatique du Sam tool manager
     * 
     * @param activateOrDeactivateUpdateManagerAuto 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public activateOrDeactivateUpdateManagerAuto(activateOrDeactivateUpdateManagerAuto: ActivateOrDeactivateUpdateManagerAuto, observe?: 'body', reportProgress?: boolean): Observable<ApiResponse>;
    public activateOrDeactivateUpdateManagerAuto(activateOrDeactivateUpdateManagerAuto: ActivateOrDeactivateUpdateManagerAuto, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<ApiResponse>>;
    public activateOrDeactivateUpdateManagerAuto(activateOrDeactivateUpdateManagerAuto: ActivateOrDeactivateUpdateManagerAuto, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<ApiResponse>>;
    public activateOrDeactivateUpdateManagerAuto(activateOrDeactivateUpdateManagerAuto: ActivateOrDeactivateUpdateManagerAuto, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (activateOrDeactivateUpdateManagerAuto === null || activateOrDeactivateUpdateManagerAuto === undefined) {
            throw new Error('Required parameter activateOrDeactivateUpdateManagerAuto was null or undefined when calling activateOrDeactivateUpdateManagerAuto.');
        }

        let headers = this.defaultHeaders;


        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<ApiResponse>(`${this.configuration.basePath}/customer/change-update-manager-auto`,
            activateOrDeactivateUpdateManagerAuto,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Créer un client
     * 
     * @param customer 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public create(customer: Customer, observe?: 'body', reportProgress?: boolean): Observable<Customer>;
    public create(customer: Customer, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Customer>>;
    public create(customer: Customer, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Customer>>;
    public create(customer: Customer, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
      return this.createCustomer(customer, observe, reportProgress);
    }

    /**
     * Créer un client
     * 
     * @param customer 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public createCustomer(customer: Customer, observe?: 'body', reportProgress?: boolean): Observable<Customer>;
    public createCustomer(customer: Customer, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Customer>>;
    public createCustomer(customer: Customer, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Customer>>;
    public createCustomer(customer: Customer, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (customer === null || customer === undefined) {
            throw new Error('Required parameter customer was null or undefined when calling createCustomer.');
        }

        let headers = this.defaultHeaders;


        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<Customer>(`${this.configuration.basePath}/customer`,
            customer,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Créer/Ajoute des sites/secteurs/services à un client
     * 
     * @param customerId Identifiant du client à mettre à jour
     * @param customerSiteAreaService 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public createCustomerSiteAreaService(customerId: number, customerSiteAreaService: Array<CustomerSiteAreaService>, observe?: 'body', reportProgress?: boolean): Observable<ApiResponse>;
    public createCustomerSiteAreaService(customerId: number, customerSiteAreaService: Array<CustomerSiteAreaService>, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<ApiResponse>>;
    public createCustomerSiteAreaService(customerId: number, customerSiteAreaService: Array<CustomerSiteAreaService>, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<ApiResponse>>;
    public createCustomerSiteAreaService(customerId: number, customerSiteAreaService: Array<CustomerSiteAreaService>, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (customerId === null || customerId === undefined) {
            throw new Error('Required parameter customerId was null or undefined when calling createCustomerSiteAreaService.');
        }
        if (customerSiteAreaService === null || customerSiteAreaService === undefined) {
            throw new Error('Required parameter customerSiteAreaService was null or undefined when calling createCustomerSiteAreaService.');
        }

        let headers = this.defaultHeaders;


        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<ApiResponse>(`${this.configuration.basePath}/customer/site_area_service/${encodeURIComponent(String(customerId))}`,
            customerSiteAreaService,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Supprime un client
     * Suppression d&#39;un client
     * @param customerId Identifiant du client à supprimer
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public _delete(customerId: number, observe?: 'body', reportProgress?: boolean): Observable<ApiResponse>;
    public _delete(customerId: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<ApiResponse>>;
    public _delete(customerId: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<ApiResponse>>;
    public _delete(customerId: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
      return this.deleteCustomer(customerId, observe, reportProgress);
    }

    /**
     * Supprime un client
     * Suppression d&#39;un client
     * @param customerId Identifiant du client à supprimer
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public deleteCustomer(customerId: number, observe?: 'body', reportProgress?: boolean): Observable<ApiResponse>;
    public deleteCustomer(customerId: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<ApiResponse>>;
    public deleteCustomer(customerId: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<ApiResponse>>;
    public deleteCustomer(customerId: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (customerId === null || customerId === undefined) {
            throw new Error('Required parameter customerId was null or undefined when calling deleteCustomer.');
        }

        let headers = this.defaultHeaders;


        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.delete<ApiResponse>(`${this.configuration.basePath}/customer/${encodeURIComponent(String(customerId))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * export la liste des clients
     * 
     * @param filters 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public exportCSV(filters: Filters, observe?: 'body', reportProgress?: boolean): Observable<CustomersExportResponse>;
    public exportCSV(filters: Filters, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<CustomersExportResponse>>;
    public exportCSV(filters: Filters, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<CustomersExportResponse>>;
    public exportCSV(filters: Filters, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (filters === null || filters === undefined) {
            throw new Error('Required parameter filters was null or undefined when calling exportCSV.');
        }

        let headers = this.defaultHeaders;


        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // authentication (oAuth) required

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<CustomersExportResponse>(`${this.configuration.basePath}/customers/export`,
            filters,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Retourne un client selon le contenant
     * 
     * @param containerId id du contenant
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getCustomerByContainerId(containerId: number, observe?: 'body', reportProgress?: boolean): Observable<Customer>;
    public getCustomerByContainerId(containerId: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Customer>>;
    public getCustomerByContainerId(containerId: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Customer>>;
    public getCustomerByContainerId(containerId: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (containerId === null || containerId === undefined) {
            throw new Error('Required parameter containerId was null or undefined when calling getCustomerByContainerId.');
        }

        let headers = this.defaultHeaders;


        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<Customer>(`${this.configuration.basePath}/customer/containerId/${encodeURIComponent(String(containerId))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Retourne un client
     * 
     * @param customerId id du client à récupérer
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getById(customerId: number, observe?: 'body', reportProgress?: boolean): Observable<Customer>;
    public getById(customerId: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Customer>>;
    public getById(customerId: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Customer>>;
    public getById(customerId: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
      return this.getCustomerById(customerId, observe, reportProgress);
    }

    /**
     * Retourne un client
     * 
     * @param customerId id du client à récupérer
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getCustomerById(customerId: number, observe?: 'body', reportProgress?: boolean): Observable<Customer>;
    public getCustomerById(customerId: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Customer>>;
    public getCustomerById(customerId: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Customer>>;
    public getCustomerById(customerId: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (customerId === null || customerId === undefined) {
            throw new Error('Required parameter customerId was null or undefined when calling getCustomerById.');
        }

        let headers = this.defaultHeaders;


        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<Customer>(`${this.configuration.basePath}/customer/${encodeURIComponent(String(customerId))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Retourne les enfants d&#39;un client
     * 
     * @param customerId id du client pour lequel on cherche les enfants
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getCustomerChildrenById(customerId: number, observe?: 'body', reportProgress?: boolean): Observable<Array<Customer>>;
    public getCustomerChildrenById(customerId: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<Customer>>>;
    public getCustomerChildrenById(customerId: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<Customer>>>;
    public getCustomerChildrenById(customerId: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (customerId === null || customerId === undefined) {
            throw new Error('Required parameter customerId was null or undefined when calling getCustomerChildrenById.');
        }

        let headers = this.defaultHeaders;


        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<Array<Customer>>(`${this.configuration.basePath}/customer-child/${encodeURIComponent(String(customerId))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Retourne une liste de client
     * 
     * @param filters 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public list(filters: Filters, observe?: 'body', reportProgress?: boolean): Observable<ListCustomersResponse>;
    public list(filters: Filters, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<ListCustomersResponse>>;
    public list(filters: Filters, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<ListCustomersResponse>>;
    public list(filters: Filters, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
      return this.listCustomers(filters, observe, reportProgress);
    }

    /**
     * Retourne une liste de client
     * 
     * @param filters 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public listCustomers(filters: Filters, observe?: 'body', reportProgress?: boolean): Observable<ListCustomersResponse>;
    public listCustomers(filters: Filters, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<ListCustomersResponse>>;
    public listCustomers(filters: Filters, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<ListCustomersResponse>>;
    public listCustomers(filters: Filters, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (filters === null || filters === undefined) {
            throw new Error('Required parameter filters was null or undefined when calling listCustomers.');
        }

        let headers = this.defaultHeaders;


        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<ListCustomersResponse>(`${this.configuration.basePath}/customers`,
            filters,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Met à jour un client
     * MaJ client
     * @param customerId Identifiant du client à mettre à jour
     * @param customer 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public update(customerId: number, customer: Customer, observe?: 'body', reportProgress?: boolean): Observable<ApiResponse>;
    public update(customerId: number, customer: Customer, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<ApiResponse>>;
    public update(customerId: number, customer: Customer, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<ApiResponse>>;
    public update(customerId: number, customer: Customer, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
      return this.updateCustomer(customerId, customer, observe, reportProgress);
    }

    /**
     * Met à jour un client
     * MaJ client
     * @param customerId Identifiant du client à mettre à jour
     * @param customer 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public updateCustomer(customerId: number, customer: Customer, observe?: 'body', reportProgress?: boolean): Observable<ApiResponse>;
    public updateCustomer(customerId: number, customer: Customer, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<ApiResponse>>;
    public updateCustomer(customerId: number, customer: Customer, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<ApiResponse>>;
    public updateCustomer(customerId: number, customer: Customer, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (customerId === null || customerId === undefined) {
            throw new Error('Required parameter customerId was null or undefined when calling updateCustomer.');
        }
        if (customer === null || customer === undefined) {
            throw new Error('Required parameter customer was null or undefined when calling updateCustomer.');
        }

        let headers = this.defaultHeaders;


        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.put<ApiResponse>(`${this.configuration.basePath}/customer/${encodeURIComponent(String(customerId))}`,
            customer,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

}
