import {
  WindowManagerConfig,
  UserServiceProvider,
  ErrorMessageServiceProvider,
  MenuServiceProvider,
  ShortcutServiceProvider,
  KitUIConfig,
  StompService,
} from '@adista/window-kit-ui';
import { ApiLockService } from '@adista/common';
import { TopbarComponent } from '@components/topbar/topbar.component';

export function samToolsWMConfig(
  userService: UserServiceProvider,
  errorMessageService: ErrorMessageServiceProvider,
  stompService: StompService,
  lockService: ApiLockService,
): WindowManagerConfig {
  return {
    services: {
      userService,
      errorMessageService,
    },
    entities: {
      lock: {
        lockService,
        listen: true,
      },
      messaging: {
        stompService,
        baseDestination: '/topic/SamTools',
        listen: true,
      },
    },
    menuItems: {
      disabledDeleteMenuDuringEdition: false,
      items: {
        showPrintIconAndMenu: true,
        showConfiguratorMenu: true,
        showDeleteMenu: true,
        showDuplicateMenu: true,
      },
    },
    windowLayout: {
      mode: 'single',
    },
  };
}

export function samToolsUIConfig(menuService: MenuServiceProvider, shortcutService: ShortcutServiceProvider): KitUIConfig {
  return {
    multipleDesktops: false,
    controlClickOpen: false,

    menu: {
      visible: true,
      menuService,
    },

    sideNav: {
      visible: false,
      hasDisconnect: true,
      shortcutService,
    },

    topBar: {
      visible: true,
      hasDisconnect: true,
      barTemplate: TopbarComponent,
    },
  };
}
