/**
 * Sam Tools WS
 * Sam Tools Web Services.
 *
 * OpenAPI spec version: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
/* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent }                           from '@angular/common/http';
import { CustomHttpUrlEncodingCodec }                        from '../encoder';

import { Observable }                                        from 'rxjs';

import { AlertModel } from '../model/alertModel';
import { ApiResponse } from '../model/apiResponse';
import { Filters } from '../model/filters';
import { ListAlertModelsResponse } from '../model/listAlertModelsResponse';

import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
import { Configuration }                                     from '../configuration';


@Injectable({
  providedIn: 'root'
})
export class AlertModelService {

    protected basePath = 'http://api.samtools.local';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {

        if (configuration) {
            this.configuration = configuration;
            this.configuration.basePath = configuration.basePath || basePath || this.basePath;

        } else {
            this.configuration.basePath = basePath || this.basePath;
        }
    }

    /**
     * @param consumes string[] mime-types
     * @return true: consumes contains 'multipart/form-data', false: otherwise
     */
    private canConsumeForm(consumes: string[]): boolean {
        const form = 'multipart/form-data';
        for (const consume of consumes) {
            if (form === consume) {
                return true;
            }
        }
        return false;
    }


    /**
     * Créer un modèle d&#39;alerte
     * 
     * @param alertModel 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public create(alertModel: AlertModel, observe?: 'body', reportProgress?: boolean): Observable<AlertModel>;
    public create(alertModel: AlertModel, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<AlertModel>>;
    public create(alertModel: AlertModel, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<AlertModel>>;
    public create(alertModel: AlertModel, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
      return this.createAlertModel(alertModel, observe, reportProgress);
    }

    /**
     * Créer un modèle d&#39;alerte
     * 
     * @param alertModel 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public createAlertModel(alertModel: AlertModel, observe?: 'body', reportProgress?: boolean): Observable<AlertModel>;
    public createAlertModel(alertModel: AlertModel, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<AlertModel>>;
    public createAlertModel(alertModel: AlertModel, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<AlertModel>>;
    public createAlertModel(alertModel: AlertModel, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (alertModel === null || alertModel === undefined) {
            throw new Error('Required parameter alertModel was null or undefined when calling createAlertModel.');
        }

        let headers = this.defaultHeaders;


        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // authentication (oAuth) required

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<AlertModel>(`${this.configuration.basePath}/alertModel`,
            alertModel,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Supprime un modèle d&#39;alerte
     * Suppression d&#39;un modèle d&#39;alerte
     * @param alertModelId Identifiant du modèle d&#39;alerte à supprimer
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public _delete(alertModelId: number, observe?: 'body', reportProgress?: boolean): Observable<ApiResponse>;
    public _delete(alertModelId: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<ApiResponse>>;
    public _delete(alertModelId: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<ApiResponse>>;
    public _delete(alertModelId: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
      return this.deleteAlertModel(alertModelId, observe, reportProgress);
    }

    /**
     * Supprime un modèle d&#39;alerte
     * Suppression d&#39;un modèle d&#39;alerte
     * @param alertModelId Identifiant du modèle d&#39;alerte à supprimer
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public deleteAlertModel(alertModelId: number, observe?: 'body', reportProgress?: boolean): Observable<ApiResponse>;
    public deleteAlertModel(alertModelId: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<ApiResponse>>;
    public deleteAlertModel(alertModelId: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<ApiResponse>>;
    public deleteAlertModel(alertModelId: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (alertModelId === null || alertModelId === undefined) {
            throw new Error('Required parameter alertModelId was null or undefined when calling deleteAlertModel.');
        }

        let headers = this.defaultHeaders;


        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // authentication (oAuth) required

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.delete<ApiResponse>(`${this.configuration.basePath}/alertModel/${encodeURIComponent(String(alertModelId))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Retourne un modèle d&#39;alerte
     * 
     * @param alertModelId id du modèle d&#39;alerte à récupérer
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getById(alertModelId: number, observe?: 'body', reportProgress?: boolean): Observable<AlertModel>;
    public getById(alertModelId: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<AlertModel>>;
    public getById(alertModelId: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<AlertModel>>;
    public getById(alertModelId: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
      return this.getAlertModelById(alertModelId, observe, reportProgress);
    }

    /**
     * Retourne un modèle d&#39;alerte
     * 
     * @param alertModelId id du modèle d&#39;alerte à récupérer
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getAlertModelById(alertModelId: number, observe?: 'body', reportProgress?: boolean): Observable<AlertModel>;
    public getAlertModelById(alertModelId: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<AlertModel>>;
    public getAlertModelById(alertModelId: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<AlertModel>>;
    public getAlertModelById(alertModelId: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (alertModelId === null || alertModelId === undefined) {
            throw new Error('Required parameter alertModelId was null or undefined when calling getAlertModelById.');
        }

        let headers = this.defaultHeaders;


        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // authentication (oAuth) required

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<AlertModel>(`${this.configuration.basePath}/alertModel/${encodeURIComponent(String(alertModelId))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Retourne une liste de modèle d&#39;alerte
     * 
     * @param filters 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public list(filters: Filters, observe?: 'body', reportProgress?: boolean): Observable<ListAlertModelsResponse>;
    public list(filters: Filters, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<ListAlertModelsResponse>>;
    public list(filters: Filters, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<ListAlertModelsResponse>>;
    public list(filters: Filters, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
      return this.listAlertModels(filters, observe, reportProgress);
    }

    /**
     * Retourne une liste de modèle d&#39;alerte
     * 
     * @param filters 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public listAlertModels(filters: Filters, observe?: 'body', reportProgress?: boolean): Observable<ListAlertModelsResponse>;
    public listAlertModels(filters: Filters, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<ListAlertModelsResponse>>;
    public listAlertModels(filters: Filters, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<ListAlertModelsResponse>>;
    public listAlertModels(filters: Filters, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (filters === null || filters === undefined) {
            throw new Error('Required parameter filters was null or undefined when calling listAlertModels.');
        }

        let headers = this.defaultHeaders;


        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // authentication (oAuth) required

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<ListAlertModelsResponse>(`${this.configuration.basePath}/alertModels`,
            filters,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Met à jour un modèle d&#39;alerte
     * MaJ modèle d&#39;alerte
     * @param alertModelId Identifiant du modèle d&#39;alerte à mettre à jour
     * @param alertModel 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public update(alertModelId: number, alertModel: AlertModel, observe?: 'body', reportProgress?: boolean): Observable<ApiResponse>;
    public update(alertModelId: number, alertModel: AlertModel, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<ApiResponse>>;
    public update(alertModelId: number, alertModel: AlertModel, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<ApiResponse>>;
    public update(alertModelId: number, alertModel: AlertModel, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
      return this.updateAlertModel(alertModelId, alertModel, observe, reportProgress);
    }

    /**
     * Met à jour un modèle d&#39;alerte
     * MaJ modèle d&#39;alerte
     * @param alertModelId Identifiant du modèle d&#39;alerte à mettre à jour
     * @param alertModel 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public updateAlertModel(alertModelId: number, alertModel: AlertModel, observe?: 'body', reportProgress?: boolean): Observable<ApiResponse>;
    public updateAlertModel(alertModelId: number, alertModel: AlertModel, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<ApiResponse>>;
    public updateAlertModel(alertModelId: number, alertModel: AlertModel, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<ApiResponse>>;
    public updateAlertModel(alertModelId: number, alertModel: AlertModel, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (alertModelId === null || alertModelId === undefined) {
            throw new Error('Required parameter alertModelId was null or undefined when calling updateAlertModel.');
        }
        if (alertModel === null || alertModel === undefined) {
            throw new Error('Required parameter alertModel was null or undefined when calling updateAlertModel.');
        }

        let headers = this.defaultHeaders;


        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // authentication (oAuth) required

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.put<ApiResponse>(`${this.configuration.basePath}/alertModel/${encodeURIComponent(String(alertModelId))}`,
            alertModel,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

}
