/**
 * Sam Tools WS
 * Sam Tools Web Services.
 *
 * OpenAPI spec version: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
/* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent }                           from '@angular/common/http';
import { CustomHttpUrlEncodingCodec }                        from '../encoder';

import { Observable }                                        from 'rxjs';

import { ApiResponse } from '../model/apiResponse';
import { Filters } from '../model/filters';
import { ListProductCategoriesOption } from '../model/listProductCategoriesOption';
import { ListProductCategoriesResponse } from '../model/listProductCategoriesResponse';
import { ProductCategory } from '../model/productCategory';
import { ProductCategorySearchOption } from '../model/productCategorySearchOption';

import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
import { Configuration }                                     from '../configuration';


@Injectable({
  providedIn: 'root'
})
export class ProductCategoriesService {

    protected basePath = 'http://api.samtools.local';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {

        if (configuration) {
            this.configuration = configuration;
            this.configuration.basePath = configuration.basePath || basePath || this.basePath;

        } else {
            this.configuration.basePath = basePath || this.basePath;
        }
    }

    /**
     * @param consumes string[] mime-types
     * @return true: consumes contains 'multipart/form-data', false: otherwise
     */
    private canConsumeForm(consumes: string[]): boolean {
        const form = 'multipart/form-data';
        for (const consume of consumes) {
            if (form === consume) {
                return true;
            }
        }
        return false;
    }


    /**
     * Créer une catégorie d&#39;article
     * 
     * @param productCategory 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public create(productCategory: ProductCategory, observe?: 'body', reportProgress?: boolean): Observable<ProductCategory>;
    public create(productCategory: ProductCategory, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<ProductCategory>>;
    public create(productCategory: ProductCategory, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<ProductCategory>>;
    public create(productCategory: ProductCategory, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
      return this.createProductCategory(productCategory, observe, reportProgress);
    }

    /**
     * Créer une catégorie d&#39;article
     * 
     * @param productCategory 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public createProductCategory(productCategory: ProductCategory, observe?: 'body', reportProgress?: boolean): Observable<ProductCategory>;
    public createProductCategory(productCategory: ProductCategory, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<ProductCategory>>;
    public createProductCategory(productCategory: ProductCategory, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<ProductCategory>>;
    public createProductCategory(productCategory: ProductCategory, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (productCategory === null || productCategory === undefined) {
            throw new Error('Required parameter productCategory was null or undefined when calling createProductCategory.');
        }

        let headers = this.defaultHeaders;


        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // authentication (oAuth) required

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<ProductCategory>(`${this.configuration.basePath}/productCategory`,
            productCategory,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Supprime une catégorie d&#39;article
     * Suppression d&#39;une catégorie d&#39;article
     * @param productCategoryId Identifiant de la catégorie d&#39;article à supprimer
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public _delete(productCategoryId: number, observe?: 'body', reportProgress?: boolean): Observable<ApiResponse>;
    public _delete(productCategoryId: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<ApiResponse>>;
    public _delete(productCategoryId: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<ApiResponse>>;
    public _delete(productCategoryId: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
      return this.deleteProductCategory(productCategoryId, observe, reportProgress);
    }

    /**
     * Supprime une catégorie d&#39;article
     * Suppression d&#39;une catégorie d&#39;article
     * @param productCategoryId Identifiant de la catégorie d&#39;article à supprimer
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public deleteProductCategory(productCategoryId: number, observe?: 'body', reportProgress?: boolean): Observable<ApiResponse>;
    public deleteProductCategory(productCategoryId: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<ApiResponse>>;
    public deleteProductCategory(productCategoryId: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<ApiResponse>>;
    public deleteProductCategory(productCategoryId: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (productCategoryId === null || productCategoryId === undefined) {
            throw new Error('Required parameter productCategoryId was null or undefined when calling deleteProductCategory.');
        }

        let headers = this.defaultHeaders;


        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // authentication (oAuth) required

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.delete<ApiResponse>(`${this.configuration.basePath}/productCategory/${encodeURIComponent(String(productCategoryId))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Retourne une catégorie d&#39;article
     * 
     * @param productCategoryId id de la catégorie d&#39;article à récupérer
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getById(productCategoryId: number, observe?: 'body', reportProgress?: boolean): Observable<ProductCategory>;
    public getById(productCategoryId: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<ProductCategory>>;
    public getById(productCategoryId: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<ProductCategory>>;
    public getById(productCategoryId: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
      return this.getProductCategoryById(productCategoryId, observe, reportProgress);
    }

    /**
     * Retourne une catégorie d&#39;article
     * 
     * @param productCategoryId id de la catégorie d&#39;article à récupérer
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getProductCategoryById(productCategoryId: number, observe?: 'body', reportProgress?: boolean): Observable<ProductCategory>;
    public getProductCategoryById(productCategoryId: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<ProductCategory>>;
    public getProductCategoryById(productCategoryId: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<ProductCategory>>;
    public getProductCategoryById(productCategoryId: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (productCategoryId === null || productCategoryId === undefined) {
            throw new Error('Required parameter productCategoryId was null or undefined when calling getProductCategoryById.');
        }

        let headers = this.defaultHeaders;


        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // authentication (oAuth) required

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<ProductCategory>(`${this.configuration.basePath}/productCategory/${encodeURIComponent(String(productCategoryId))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Retourne une liste d&#39;options contenant les catégorie d&#39;articles
     * 
     * @param productCategorySearchOption 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public listOptions(productCategorySearchOption: ProductCategorySearchOption, observe?: 'body', reportProgress?: boolean): Observable<ListProductCategoriesOption>;
    public listOptions(productCategorySearchOption: ProductCategorySearchOption, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<ListProductCategoriesOption>>;
    public listOptions(productCategorySearchOption: ProductCategorySearchOption, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<ListProductCategoriesOption>>;
    public listOptions(productCategorySearchOption: ProductCategorySearchOption, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (productCategorySearchOption === null || productCategorySearchOption === undefined) {
            throw new Error('Required parameter productCategorySearchOption was null or undefined when calling listOptions.');
        }

        let headers = this.defaultHeaders;


        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<ListProductCategoriesOption>(`${this.configuration.basePath}/productCategories/options`,
            productCategorySearchOption,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Retourne une liste de catégorie d&#39;article
     * 
     * @param filters 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public list(filters: Filters, observe?: 'body', reportProgress?: boolean): Observable<ListProductCategoriesResponse>;
    public list(filters: Filters, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<ListProductCategoriesResponse>>;
    public list(filters: Filters, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<ListProductCategoriesResponse>>;
    public list(filters: Filters, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
      return this.listProductCategories(filters, observe, reportProgress);
    }

    /**
     * Retourne une liste de catégorie d&#39;article
     * 
     * @param filters 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public listProductCategories(filters: Filters, observe?: 'body', reportProgress?: boolean): Observable<ListProductCategoriesResponse>;
    public listProductCategories(filters: Filters, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<ListProductCategoriesResponse>>;
    public listProductCategories(filters: Filters, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<ListProductCategoriesResponse>>;
    public listProductCategories(filters: Filters, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (filters === null || filters === undefined) {
            throw new Error('Required parameter filters was null or undefined when calling listProductCategories.');
        }

        let headers = this.defaultHeaders;


        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // authentication (oAuth) required

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<ListProductCategoriesResponse>(`${this.configuration.basePath}/productCategories`,
            filters,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Met à jour une catégorie d&#39;article
     * MaJ catégorie d&#39;article
     * @param productCategoryId Identifiant de la catégorie d&#39;article à mettre à jour
     * @param productCategory 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public update(productCategoryId: number, productCategory: ProductCategory, observe?: 'body', reportProgress?: boolean): Observable<ApiResponse>;
    public update(productCategoryId: number, productCategory: ProductCategory, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<ApiResponse>>;
    public update(productCategoryId: number, productCategory: ProductCategory, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<ApiResponse>>;
    public update(productCategoryId: number, productCategory: ProductCategory, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
      return this.updateProductCategory(productCategoryId, productCategory, observe, reportProgress);
    }

    /**
     * Met à jour une catégorie d&#39;article
     * MaJ catégorie d&#39;article
     * @param productCategoryId Identifiant de la catégorie d&#39;article à mettre à jour
     * @param productCategory 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public updateProductCategory(productCategoryId: number, productCategory: ProductCategory, observe?: 'body', reportProgress?: boolean): Observable<ApiResponse>;
    public updateProductCategory(productCategoryId: number, productCategory: ProductCategory, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<ApiResponse>>;
    public updateProductCategory(productCategoryId: number, productCategory: ProductCategory, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<ApiResponse>>;
    public updateProductCategory(productCategoryId: number, productCategory: ProductCategory, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (productCategoryId === null || productCategoryId === undefined) {
            throw new Error('Required parameter productCategoryId was null or undefined when calling updateProductCategory.');
        }
        if (productCategory === null || productCategory === undefined) {
            throw new Error('Required parameter productCategory was null or undefined when calling updateProductCategory.');
        }

        let headers = this.defaultHeaders;


        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // authentication (oAuth) required

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.put<ApiResponse>(`${this.configuration.basePath}/productCategory/${encodeURIComponent(String(productCategoryId))}`,
            productCategory,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

}
