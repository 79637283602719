/**
 * Sam Tools WS
 * Sam Tools Web Services.
 *
 * OpenAPI spec version: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
/* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent }                           from '@angular/common/http';
import { CustomHttpUrlEncodingCodec }                        from '../encoder';

import { Observable }                                        from 'rxjs';

import { ApiResponse } from '../model/apiResponse';
import { Distributor } from '../model/distributor';
import { Filters } from '../model/filters';
import { ListDistributorOption } from '../model/listDistributorOption';
import { ListDistributorsResponse } from '../model/listDistributorsResponse';

import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
import { Configuration }                                     from '../configuration';


@Injectable({
  providedIn: 'root'
})
export class DistributorService {

    protected basePath = 'http://api.samtools.local';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {

        if (configuration) {
            this.configuration = configuration;
            this.configuration.basePath = configuration.basePath || basePath || this.basePath;

        } else {
            this.configuration.basePath = basePath || this.basePath;
        }
    }

    /**
     * @param consumes string[] mime-types
     * @return true: consumes contains 'multipart/form-data', false: otherwise
     */
    private canConsumeForm(consumes: string[]): boolean {
        const form = 'multipart/form-data';
        for (const consume of consumes) {
            if (form === consume) {
                return true;
            }
        }
        return false;
    }


    /**
     * Créer un distributeur
     * 
     * @param distributor 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public create(distributor: Distributor, observe?: 'body', reportProgress?: boolean): Observable<Distributor>;
    public create(distributor: Distributor, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Distributor>>;
    public create(distributor: Distributor, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Distributor>>;
    public create(distributor: Distributor, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
      return this.createDistributor(distributor, observe, reportProgress);
    }

    /**
     * Créer un distributeur
     * 
     * @param distributor 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public createDistributor(distributor: Distributor, observe?: 'body', reportProgress?: boolean): Observable<Distributor>;
    public createDistributor(distributor: Distributor, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Distributor>>;
    public createDistributor(distributor: Distributor, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Distributor>>;
    public createDistributor(distributor: Distributor, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (distributor === null || distributor === undefined) {
            throw new Error('Required parameter distributor was null or undefined when calling createDistributor.');
        }

        let headers = this.defaultHeaders;


        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // authentication (oAuth) required

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<Distributor>(`${this.configuration.basePath}/distributor`,
            distributor,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Supprime un distributeur
     * Suppression d&#39;un distributeur
     * @param distributorId Identifiant du distributeur à supprimer
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public _delete(distributorId: number, observe?: 'body', reportProgress?: boolean): Observable<ApiResponse>;
    public _delete(distributorId: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<ApiResponse>>;
    public _delete(distributorId: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<ApiResponse>>;
    public _delete(distributorId: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
      return this.deleteDistributor(distributorId, observe, reportProgress);
    }

    /**
     * Supprime un distributeur
     * Suppression d&#39;un distributeur
     * @param distributorId Identifiant du distributeur à supprimer
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public deleteDistributor(distributorId: number, observe?: 'body', reportProgress?: boolean): Observable<ApiResponse>;
    public deleteDistributor(distributorId: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<ApiResponse>>;
    public deleteDistributor(distributorId: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<ApiResponse>>;
    public deleteDistributor(distributorId: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (distributorId === null || distributorId === undefined) {
            throw new Error('Required parameter distributorId was null or undefined when calling deleteDistributor.');
        }

        let headers = this.defaultHeaders;


        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // authentication (oAuth) required

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.delete<ApiResponse>(`${this.configuration.basePath}/distributor/${encodeURIComponent(String(distributorId))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Retourne un distributeur
     * 
     * @param distributorId id du distributeur à récupérer
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getById(distributorId: number, observe?: 'body', reportProgress?: boolean): Observable<Distributor>;
    public getById(distributorId: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Distributor>>;
    public getById(distributorId: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Distributor>>;
    public getById(distributorId: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
      return this.getDistributorById(distributorId, observe, reportProgress);
    }

    /**
     * Retourne un distributeur
     * 
     * @param distributorId id du distributeur à récupérer
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getDistributorById(distributorId: number, observe?: 'body', reportProgress?: boolean): Observable<Distributor>;
    public getDistributorById(distributorId: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Distributor>>;
    public getDistributorById(distributorId: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Distributor>>;
    public getDistributorById(distributorId: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (distributorId === null || distributorId === undefined) {
            throw new Error('Required parameter distributorId was null or undefined when calling getDistributorById.');
        }

        let headers = this.defaultHeaders;


        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // authentication (oAuth) required

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<Distributor>(`${this.configuration.basePath}/distributor/${encodeURIComponent(String(distributorId))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Retourne une liste de distributeur
     * 
     * @param filters 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public list(filters: Filters, observe?: 'body', reportProgress?: boolean): Observable<ListDistributorsResponse>;
    public list(filters: Filters, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<ListDistributorsResponse>>;
    public list(filters: Filters, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<ListDistributorsResponse>>;
    public list(filters: Filters, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
      return this.listDistributors(filters, observe, reportProgress);
    }

    /**
     * Retourne une liste de distributeur
     * 
     * @param filters 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public listDistributors(filters: Filters, observe?: 'body', reportProgress?: boolean): Observable<ListDistributorsResponse>;
    public listDistributors(filters: Filters, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<ListDistributorsResponse>>;
    public listDistributors(filters: Filters, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<ListDistributorsResponse>>;
    public listDistributors(filters: Filters, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (filters === null || filters === undefined) {
            throw new Error('Required parameter filters was null or undefined when calling listDistributors.');
        }

        let headers = this.defaultHeaders;


        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // authentication (oAuth) required

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<ListDistributorsResponse>(`${this.configuration.basePath}/distributors`,
            filters,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Retourne une liste d&#39;options contenant les distributeurs
     * 
     * @param customerId Id du client
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public listOptions(customerId: number, observe?: 'body', reportProgress?: boolean): Observable<ListDistributorOption>;
    public listOptions(customerId: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<ListDistributorOption>>;
    public listOptions(customerId: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<ListDistributorOption>>;
    public listOptions(customerId: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (customerId === null || customerId === undefined) {
            throw new Error('Required parameter customerId was null or undefined when calling listOptions.');
        }

        let headers = this.defaultHeaders;


        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<ListDistributorOption>(`${this.configuration.basePath}/distributor/options/${encodeURIComponent(String(customerId))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Met à jour un distributeur
     * MaJ distributeur
     * @param distributorId Identifiant du distributeur à mettre à jour
     * @param distributor 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public update(distributorId: number, distributor: Distributor, observe?: 'body', reportProgress?: boolean): Observable<ApiResponse>;
    public update(distributorId: number, distributor: Distributor, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<ApiResponse>>;
    public update(distributorId: number, distributor: Distributor, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<ApiResponse>>;
    public update(distributorId: number, distributor: Distributor, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
      return this.updateDistributor(distributorId, distributor, observe, reportProgress);
    }

    /**
     * Met à jour un distributeur
     * MaJ distributeur
     * @param distributorId Identifiant du distributeur à mettre à jour
     * @param distributor 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public updateDistributor(distributorId: number, distributor: Distributor, observe?: 'body', reportProgress?: boolean): Observable<ApiResponse>;
    public updateDistributor(distributorId: number, distributor: Distributor, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<ApiResponse>>;
    public updateDistributor(distributorId: number, distributor: Distributor, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<ApiResponse>>;
    public updateDistributor(distributorId: number, distributor: Distributor, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (distributorId === null || distributorId === undefined) {
            throw new Error('Required parameter distributorId was null or undefined when calling updateDistributor.');
        }
        if (distributor === null || distributor === undefined) {
            throw new Error('Required parameter distributor was null or undefined when calling updateDistributor.');
        }

        let headers = this.defaultHeaders;


        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // authentication (oAuth) required

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.put<ApiResponse>(`${this.configuration.basePath}/distributor/${encodeURIComponent(String(distributorId))}`,
            distributor,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

}
