import { Injectable } from '@angular/core';
import { ValidationErrors } from '@angular/forms';
import { TranslateKitService } from '@shared/services/translate-kit.service';

@Injectable({
  providedIn: 'root',
})
export class ErrorMessageService {
  private readonly keyErrors = ['required', 'max', 'min', 'pattern', 'email', 'minLength', 'maxLength'];
  constructor(private readonly translateKitService: TranslateKitService) {}

  public getMessage(errors: ValidationErrors): string {
    if (errors === null || Object.keys(errors)[0] === null) {
      return '';
    }
    const keyError = Object.keys(errors)[0];
    if (this.keyErrors.includes(keyError)) {
      return this.translateKitService.translateInstant('errorsForm.' + keyError);
    } else {
      return '';
    }
  }
}
