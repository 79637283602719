/**
 * Sam Tools WS
 * Sam Tools Web Services.
 *
 * OpenAPI spec version: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
/* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent }                           from '@angular/common/http';
import { CustomHttpUrlEncodingCodec }                        from '../encoder';

import { Observable }                                        from 'rxjs';

import { ApiResponse } from '../model/apiResponse';

import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
import { Configuration }                                     from '../configuration';


@Injectable({
  providedIn: 'root'
})
export class FileService {

    protected basePath = 'http://api.samtools.local';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {

        if (configuration) {
            this.configuration = configuration;
            this.configuration.basePath = configuration.basePath || basePath || this.basePath;

        } else {
            this.configuration.basePath = basePath || this.basePath;
        }
    }

    /**
     * @param consumes string[] mime-types
     * @return true: consumes contains 'multipart/form-data', false: otherwise
     */
    private canConsumeForm(consumes: string[]): boolean {
        const form = 'multipart/form-data';
        for (const consume of consumes) {
            if (form === consume) {
                return true;
            }
        }
        return false;
    }


    /**
     * Supprime un fichier
     * Suppression d&#39;un fichier
     * @param fileId Identifiant du fichier à supprimer
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public _delete(fileId: number, observe?: 'body', reportProgress?: boolean): Observable<ApiResponse>;
    public _delete(fileId: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<ApiResponse>>;
    public _delete(fileId: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<ApiResponse>>;
    public _delete(fileId: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (fileId === null || fileId === undefined) {
            throw new Error('Required parameter fileId was null or undefined when calling _delete.');
        }

        let headers = this.defaultHeaders;


        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // authentication (oAuth) required

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.delete<ApiResponse>(`${this.configuration.basePath}/file/${encodeURIComponent(String(fileId))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Créer un fichier
     * Permet de créer un fichier
     * @param file 
     * @param lastModifiedDate 
     * @param parentEntityType 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public create(file?: Blob, lastModifiedDate?: string, parentEntityType?: string, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public create(file?: Blob, lastModifiedDate?: string, parentEntityType?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public create(file?: Blob, lastModifiedDate?: string, parentEntityType?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public create(file?: Blob, lastModifiedDate?: string, parentEntityType?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;


        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // authentication (oAuth) required

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'multipart/form-data'
        ];

        const canConsumeForm = this.canConsumeForm(consumes);

        let formParams: { append(param: string, value: any): any; };
        let useForm = false;
        let convertFormParamsToString = false;
        // use FormData to transmit files using content-type "multipart/form-data"
        // see https://stackoverflow.com/questions/4007969/application-x-www-form-urlencoded-or-multipart-form-data
        useForm = canConsumeForm;
        if (useForm) {
            formParams = new FormData();
        } else {
            formParams = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        }

        if (file !== undefined) {
            formParams = formParams.append('file', <any>file) || formParams;
        }
        if (lastModifiedDate !== undefined) {
            formParams = formParams.append('lastModifiedDate', <any>lastModifiedDate) || formParams;
        }
        if (parentEntityType !== undefined) {
            formParams = formParams.append('parentEntityType', <any>parentEntityType) || formParams;
        }

        return this.httpClient.post<any>(`${this.configuration.basePath}/file`,
            convertFormParamsToString ? formParams.toString() : formParams,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Trouve un fichier par son identifiant
     * Avec l&#39;identifiant (id) retourne un un fichier
     * @param fileId Identifiant (id) du fichier à retourner
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getById(fileId: number, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public getById(fileId: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public getById(fileId: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public getById(fileId: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (fileId === null || fileId === undefined) {
            throw new Error('Required parameter fileId was null or undefined when calling getById.');
        }

        let headers = this.defaultHeaders;


        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // authentication (oAuth) required

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<any>(`${this.configuration.basePath}/file/${encodeURIComponent(String(fileId))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Trouve un fichier par son Id
     * Retourne un fichier déjà présent sur le serveur
     * @param fileId Identifiant du fichier à retourner
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getLink(fileId: number, observe?: 'body', reportProgress?: boolean): Observable<string>;
    public getLink(fileId: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<string>>;
    public getLink(fileId: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<string>>;
    public getLink(fileId: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (fileId === null || fileId === undefined) {
            throw new Error('Required parameter fileId was null or undefined when calling getLink.');
        }

        let headers = this.defaultHeaders;


        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // authentication (oAuth) required

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<string>(`${this.configuration.basePath}/file/getLink/${encodeURIComponent(String(fileId))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

}
